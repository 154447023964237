.swiper {
  &.swiper-container {
    overflow: visible;
  }

  &.s-wrapper .swiper-wrapper .swiper-slide {
    overflow: initial !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: unset;
    bottom: 0;
  }
}

.custom-swiper-button-wrapper {
  display: none;

  .custom-swiper-button-prev,
  .custom-swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: $secondary;
    color: $white;
    cursor: pointer;
    transition: background 0.2s ease;
    outline: none;

    &.swiper-button-disabled {
      background: $gray-500;
      cursor: initial;
    }
  }

  .custom-swiper-button-prev {
    margin-right: 10px;
  }

  &:has(:is(.custom-swiper-button-prev, .custom-swiper-button-next):not(.swiper-button-disabled)) {
    display: flex;
  }
}
