@import '../variables';

.card {
  max-width: 400px;
  color: white;
  border: none;
}

.card.product-card:not(.has-card-extension) {

  &:focus-within {
    //@include card-focus-within;
  }
}

.card.product-card:not(.has-card-extension) {

  @supports selector(:has(*)) {

    &:has(.card-focus-effect-trigger:focus){
      @include card-focus-effect;
    }
  }
  @supports not selector(:has(*)) {

    &.card-has-focus {
      @include card-focus-effect;
    }
  }
}

.card.is-blank {
  max-width: none;
  color: $black;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  display: block;
}

.card.tiny {
  width: 209px;
  display: inline-block;

  .card-inner {
    min-height: 150px;
    padding: 30px 30px 20px 20px;

    h3 {
      font-size: 20px;
      text-align: left;
    }
  }
}

.card.small,
.card.is-small {
  max-width: 314px;
}

.card.large,
.card.is-large {
  max-width: 700px;
}

.card.has-shadow {
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
}

.card > .button.small {
  width: 60px;
  left: calc(70% - 30px);
  position: relative;
  top: -30px;
}

.border .button {
  height: 80px;
  border: 10px solid #e6e8e6;
}

.card-inner {
  color: white;
  width: 100%;
  min-height: 125px;
  text-align: center;
  position: relative;
  padding: 60px 50px 20px 50px;
  border-radius: 5px;
  background-image: -webkit-radial-gradient(
    70% 100%,
    circle closest-corner,
    transparent 0,
    transparent 35px,
    $blue 36px
  );
  background-image: -ms-radial-gradient(
    70% 100%,
    circle closest-corner,
    transparent 0,
    transparent 35px,
    $blue 36px
  );
}

.card-inner.featured {
  background: url($we-assets-images-path + '/background-card-tiny-featured.svg')
    left top no-repeat;
  background-size: 209px, 150px, auto;
}

.card-inner > h3 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 10px;
}

.card-inner > p {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  text-align: center;
  color: #07112a;
}

div.button.small.orange > i {
  padding-left: 3px;
}

.teaser {
  margin: 0 auto;
  padding: 30px 30px 30px 30px;
  max-width: 906px !important;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);

  h2 {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #000460;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 5px;
  }
}

// tooltip-card

.tooltip-card {
  background: $blue;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  color: white;
  padding: 25px;
  font-size: 14px;
  margin: 0;
  border-radius: 5px;
  max-width: 100%;

  h3 {
    font-size: inherit;
    color: white;
  }

  p {
    display: inline;
  }

  &.single {
    &:after {
      left: 42px !important;
    }
  }

  &.has-body {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .tooltip-body {
      background: white;
      margin: 25px -25px -25px -25px;
      padding: 25px;

      p {
        color: $blue;
      }
    }

    &:after {
      display: none;
    }
  }

  div.row.link {
    margin-top: 0;
    margin-right: -25px;
    margin-left: -25px;
    padding: 8px 15px 15px;

    button,
    a.btn-link {
      pointer-events: painted;
      position: relative;
      float: right;
    }

    button {
      top: 6px;
    }

    a.btn-link {
      top: 8px;
      padding-top: 4px;
    }
  }

  div.row.separator {
    border-top: 1px solid #d8d8d8;
    margin-top: 20px;
    margin-right: -25px;
    margin-left: -25px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;

    p {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.74;
      color: #ffffff;
      top: 8px;
      position: relative;
    }

    button,
    a.btn-link {
      pointer-events: painted;
      position: relative;
      float: right;
    }

    button {
      top: 12px;
    }

    a.btn-link {
      top: 18px;
      padding-top: 4px;
    }
  }
}

.tooltip-card.disabled {
  pointer-events: none;
  background: #b3b3b3;

  &:after {
    border-top: solid 7px #b3b3b3;
  }

  &:hover {
    cursor: not-allowed;
  }

  .btn.btn-dropdown {
    box-shadow: none;

    &:hover {
      background: transparent;
      color: white;
    }
  }
}

.tooltip-card.white {
  pointer-events: none;
  background: $white;
  color: $gray-600;

  h3 {
    color: $blue;
    margin-bottom: 15px;
  }

  &:after {
    border-top: solid 7px $white;
  }

  &:hover {
    cursor: not-allowed;
  }

  .btn.btn-dropdown {
    box-shadow: none;

    &:hover {
      background: transparent;
      color: white;
    }
  }
}

.tooltip-icon-box {
  max-width: 50px;
}

.tooltip-card--small {
  min-height: 56px !important;
  font-weight: 600;
}

.tooltip-card:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -7px;
  width: 0;
  height: 0;
  border-top: solid 7px $blue;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

.toolip-left-icon,
.toolip-left-icon-hidden-sm {
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 100em;
  color: $blue;

  > i {
    font-size: 10.8px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .toolip-left-icon-hidden-sm {
    display: none;
  }
}

.toolip-right-icon {
  margin: 0;
  cursor: pointer;
}

.tooltip-info-icon {
  width: 30px;
  height: 30px;
  top: -13px;
  right: 13px;
}

// product card

.product-card {
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  height: 161px;
  max-width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  /* Center vertically */
  align-items: center;

  /*Center horizontaly */
  justify-content: center;

  /*Center horizontaly ie */
  -ms-flex-pack: center;

  .icon-column {
    min-width: 124px;
  }
}

.product-card.disabled {
  pointer-events: none;

  p,
  li,
  h4:not(.green),
  h4:not(.red) {
    color: $blue;
  }

  button {
    background-color: #b3b3b3;

    &:hover {
      color: white;
    }
  }
}

.product-card.large {
  min-height: 222px;
}

.product-card-inner {
  text-align: center;
}

.product-card-inner h3,
.product-card.extended h3 {
  padding-top: 10px;
  padding-bottom: 0;
  margin: 0;
  font-weight: 600;
  font-size: 18px;

  .product-icon-box & {
    min-width: 75px;
  }

  &.branch {
    font-size: 14px;
  }
}

.product-icon-box {
  .product-card-inner:not(:last-child) {
    margin-bottom: 35px;
  }
}

.product-card-inner img,
.product-card.extended img {
  width: 75px;
  height: 67px;
}

.product-card-inner .alert {
  .row {
    margin-right: -15px !important;
    margin-left: -15px !important;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.product-card-subtitle {
  min-height: 30px;
  margin-top: -10px;
}

.product-card-teaser {
  border-radius: 0 0 5px 5px;
  min-height: 50px;
  position: relative;
  top: 15px;
  background-color: $gray-500;
}

.product-card-teaser-text {
  margin-top: 15px;
  font-size: 14px !important;
  color: $gray-100 !important;
}

p.product-subtitle {
  font-size: 13px !important;
  color: $gray-500 !important;
}

.product-card.extended {
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    85% 100%,
    circle closest-corner,
    rgba(0, 0, 0, 0.11) 0,
    rgba(0, 0, 0, 0.08) 32px,
    #fff 33px
  );
  background-image: -ms-radial-gradient(
    85% 100%,
    circle closest-corner,
    rgba(0, 0, 0, 0.11) 0,
    rgba(0, 0, 0, 0.08) 32px,
    #fff 33px
  );
  padding-top: 24px;
  padding-bottom: 15px;
  margin-bottom: 30px;
  height: auto;

  .btn.btn-bubble {
    position: absolute;
    left: 85%;
    top: 100%;
    margin-top: -1.75rem;
    margin-left: -1.75rem;

    i {
      padding: 0 0 0 3px;
      font-size: 1.3em !important;
    }

    &:focus-visible {
      &::before {
        border: 0;
      }
    }
  }
}

.product-card.extended.no-button {
  background: white none;

  &.has-dropdown {
    margin-bottom: 0;
  }
}

.product-card.has-card-extension {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
}

.product-card-dropdown {
  z-index: 100;
  background: $white;
  position: relative;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 0 15px 0;
  margin-bottom: 35px;

  img {
    height: 31px;
    width: 31px;
    float: right;
    border-radius: 10px;
  }

  .switch {
    float: right;
    margin-top: 2px;
  }

  hr {
    border-top: 1px solid rgb(216, 216, 216);
  }

  div.row {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.info-expander {
    h6 {
      font-size: 18px;
    }

    p {
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
    }
  }
}

.product-card-info {
  i {
    font-size: 12px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: $blue;
  }
}

.product-card-footer {
  border-top: 1px solid #dedfe3;
  margin-bottom: -6px;
  margin-top: 12px;
  position: relative;
  width: 100%;
  padding: 10px 24px 0 24px;
  display: flex;
}

.product-card-footer-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}

.product-card-footer-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

// My products overview card

.product-card.overview {
  height: auto;
  padding: 24px;
  text-align: left;
  align-items: flex-start;

  p {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #07112a;
    margin-bottom: 35px;
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    color: #000460;
    margin-bottom: 13px;
  }
}

.product-card.extended h4.product-name {
  font-size: 20px;
}

.product-card.extended p {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #07112a;
}

.product-card.extended ul {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #07112a;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

// Product cards with pricing tag
.product-card.extended.pricing {
  h4 {
    font-size: 32px;
    font-weight: 400;
    color: $blue;
  }

  h4.red {
    color: #db0133;
  }

  h4.green {
    color: #258875;
  }
}

// Product cards with a table
.product-card.table {
  display: block;
  height: auto;

  .row {
    margin: auto;
  }

  .image-box {
    padding: 50px 0 25px 0;

    &:before {
      content: '';
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 0;
      height: 1px;
      border-bottom: 1px solid #d8d8d8;
    }

    img {
      width: 75px;
      height: 67px;
    }

    h3 {
      font-size: 22px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  .pricing-row-box {
    padding: 20px 30px 20px 30px;
  }

  .pricing-box {
    h3 {
      color: $gray-600;
      font-size: 22px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    h4 {
      color: $blue;
      font-size: 40px;
      font-weight: 300;
      margin-bottom: 10px;

      &.is-green {
        color: #258875;
      }
    }

    p {
      color: $gray-600;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .table-row-box {
    padding: 0 30px 30px 30px;
  }

  table {
    margin-bottom: 0;

    td {
      padding: 10px 15px 0 15px;

      h5 {
        color: $gray-600;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }

      p {
        color: $gray-600;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
      }

      h5.is-green {
        color: #258875;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 5px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 5px;
    }
  }
}

// sliding product card
.product-card-sliding {
  width: 314px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  //max-width: 311px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 1rem;
  color: $gray-600;
}

.product-card-sliding.center {
  margin: auto;
}

.product-card-sliding.has-card-extension {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
}

.product-card-sliding-dropdown {
  background: $white;
  position: relative;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 0 15px 0;
  margin-bottom: 35px;

  img {
    height: 31px;
    width: 31px;
    float: right;
    border-radius: 10px;
  }

  .switch {
    float: right;
    margin-top: 2px;
  }

  hr {
    border-top: 1px solid rgb(216, 216, 216);
  }

  div.row {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.info-expander {
    h6 {
      font-size: 18px;
    }

    p {
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
    }
  }
}

// payment card

.payment-card {
  margin: 0;
  border-radius: 5px;
  border: 1px solid $gray-400;
  background-color: #ffffff;
  height: 161px;
  min-height: 161px;
  max-width: 325px;
}

@media (max-width: 575.98px) {
  .payment-card {
    width: 100%;
    max-width: 100%;
  }
}

// bottom-info-card

.bottom-info-card {
  background-color: transparent;
  clear: both;

  > .alert-info {
    padding: 25px !important;
  }
}

// Teaser card

.teaser h2 {
  text-transform: none !important;
}

.teaser-card {
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  display: inline-block;
}

.teaser-card.blue {
  background-image: linear-gradient(141deg, #3ea9ca, #06649b 74%, #0f255b);
}

.teaser-card.orange {
  background-image: linear-gradient(
    142deg,
    #fccf8a,
    #f7af40 27%,
    $orange 63%,
    #e13b31
  );
}

.teaser-card.green {
  background-image: linear-gradient(
    129deg,
    #73deca,
    #28a48c 35%,
    $green 70%,
    #0f6e5c
  );
}

.teaser-card.small {
  min-width: 410px;
  min-height: 337px;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  margin: 0 0 70px 0;

  > .inner {
    width: 410px;
    height: 337px;
    border-radius: 5px;
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    position: relative;
    top: 60px;
    left: 20px;
    padding: 35px;

    > p {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      color: #07112a;
      padding-top: 20px;
      min-height: 70px;
    }

    > button {
      min-width: 210px;
      max-width: 210px;
      position: absolute;
      bottom: 40px;
    }
  }
}

.teaser-card.large {
  max-width: 413px;
  padding: 60px 40px 40px 40px;
  margin: 0;
  display: inline-block;

  > img {
    position: relative;
    top: -60px;
    left: -60px;
    width: 412px;
    height: 253px;
    border-radius: 5px;
  }

  > h3 {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    color: white;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top: -30px;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #ffffff;
    min-height: 48px;
  }

  > button {
    margin-top: 20px;
    min-width: 210px;
    max-width: 210px;
  }
}

// Bubbles

.teaser .bubble {
  height: 70px;
  width: 70px;
  background: #000460;
  color: white;
  border-radius: 90px;
  text-align: center;

  & > i {
    position: relative;
    top: calc(50% - 13px); /* 50% - 3/4 of icon height */
    left: 1px;
    font-size: 27px;
  }

  &.large {
    height: 84px;
    width: 84px;
    border-radius: 90px;
  }
}

// Skeleton animations

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 500px;
  }
}

// Skeleton Styles

.skeleton-circle {
  width: 80px;
  height: 80px;
  border-radius: 41px;
  background-color: #dedfe3;
}

.skeleton-line {
  min-width: 54px;
  height: 16px;
  border-radius: 13px;
  margin-bottom: 11px;

  animation: shine-lines 1s infinite linear;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background: #eeeff1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    #eeeff1 40%,
    rgba(210, 210, 210, 0.8) 50%,
    #fff 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 600px;

  &.small {
    max-width: 54px;
  }

  &.thin {
    height: 12px;
  }

  &.fat {
    height: 26px;
    margin-bottom: 17px;
  }
}

// Tooltip skeleton

.tooltip-card.skeleton {
  background: $gray-500;

  &::after {
    border-top: solid 7px $gray-500;
  }

  .tooltip-header:empty,
  .tooltip-subheader:empty {
    max-width: 500px;
    animation: shine-lines 1s infinite linear;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(210, 210, 210, 0.8) 50%,
      rgba(255, 255, 255, 1) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 600px;

    position: relative;
    top: 12px;
    display: inline-block;
    height: 11px;
    width: 75%;
    border-radius: 5.5px;
    background-color: #ffffff;
  }

  .tooltip-subheader:empty {
    width: 55%;
  }

  .toolip-right-icon {
    display: none;
  }

  .toolip-left-icon {
    height: 30px;
    width: 30px;

    i {
      display: none;
    }
  }
}

// Product card skeleton

.product-card.skeleton {
  cursor: default;

  & > div:first-child {
    position: relative;
    padding-left: 15px;
    padding-right: 20px;
  }

  //button {
  //  background: #b3b3b3;
  //  color: white;
  //}
}

@keyframes shine {
  to {
    background-position: 0 0, 100% 0, /* move highlight to right */ 120px 0,
      120px 40px, 120px 80px, 120px 120px;
  }
}

// Small devices
@media (max-width: 375px) {
  p.product-subtitle {
    font-size: 12px !important;
  }
}

// Small devices
@media (max-width: 355px) {
  p.product-subtitle {
    font-size: 11px !important;
  }
}

@media (max-width: 330px) {
  p.product-subtitle {
    font-size: 10px !important;
  }
}

// Very small devices
@media (max-width: 320px) {
  .product-card.overview {
    padding: 5px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .teaser {
    min-height: 190px;

    h2 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  .product-card {
    .icon-column {
      min-width: 140px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .product-card.extended h3.branch {
    font-size: 16px;
  }

  .product-card.extended h4.product-name {
    font-size: 24px;
  }

  .product-card.extended {
    padding-top: 32px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

/* Safari 10.1+ */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .product-card.extended {
      background-image: -webkit-radial-gradient(
        85% 100%,
        circle closest-corner,
        transparent 0,
        transparent 32px,
        #fff 32px
      ) !important;
    }

    .card-inner {
      background-image: -webkit-radial-gradient(
        70% 100%,
        circle closest-corner,
        transparent 0,
        transparent 35px,
        $blue 36px
      );
    }
  }
}

.product-detail-card {
  padding: 32px !important;
  h5 {
    font-size: 32px !important;
  }
  .card-section {
    margin-top: 32px;
  }
  hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .payment-badge-new {
    padding: 16px;
    height: 48px;
    background: $gray-300;
    border-radius: 5px;
    position: relative;
    width: fit-content;
    min-width: 92px;

    img {
      height: 19px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 60px;
      height: 16px;
    }
  }
}

@media (max-width: 767px) {
  .product-detail-card {
    padding: 24px !important;
    .card-sub-heading {
      font-size: 18px !important;
    }
    .card-section {
      margin-top: 24px;
    }
    hr {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .payment-badge-new {
      padding: 12px;
      height: 37px;
      min-width: 72px;
      img {
        width: 48px;
        height: 13px;
      }
    }
  }
}
