$utilities: (
  "padding-end": (
    responsive: true,
    property: padding-right,
    class: pr,
    values: $spacers
  ),
  "padding-start": (
    responsive: true,
    property: padding-left,
    class: pl,
    values: $spacers
  ),
  "margin-end": (
    responsive: true,
    property: margin-right,
    class: mr,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-start": (
    responsive: true,
    property: margin-left,
    class: ml,
    values: map-merge($spacers, (auto: auto))
  ),
  "negative-margin-end": (
    responsive: true,
    property: margin-right,
    class: mr,
    values: $negative-spacers
  ),
  "negative-margin-start": (
    responsive: true,
    property: margin-left,
    class: ml,
    values: $negative-spacers
  ),
  "abstract-spacing": (
    responsive: true,
    property: --#{$prefix}abstract-spacing,
    class: spacing,
    values: $spacers
  ),
  "css-columns": (
    responsive: true,
    property: column-count,
    class: columns,
    values: (0, 1, 2, 3)
  ),
);

.mw-product-icon {
  max-width: 4.5rem;
}

.link-active-opacity:active {
  --#{$prefix}text-opacity: .8;
}

.shadow-none {
  &::after {
    box-shadow: none !important;
  }
}

.rounded-13 {
  border-radius: 0.8125rem !important;
}

.rounded-top-13 {
  border-top-left-radius: 0.8125rem !important;
  border-top-right-radius: 0.8125rem !important;
}

.rounded-bottom-13 {
  border-bottom-left-radius: 0.8125rem !important;
  border-bottom-right-radius: 0.8125rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.z-0 {
  z-index: 0 !important;
}

.transition-all {
  transition: $transition-base;
}

.bg-gray--300 {
  background-color: $gray-300 !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-neutral-600 {
  color: $neutral-600 !important;
}

.field-sizing-content {
  @supports (field-sizing: content) {
    field-sizing: content !important;
    width: auto !important;
  }
}

.force-focus {
  &:focus-visible {
    outline: 0 none;
  }
}

.grid-congruent {
  grid-template-columns: 1fr;

  > * {
    grid-row: 1;
    grid-column: 1;
  }
}

.inline-notification-box-shadow-danger {
  box-shadow: 4px 0 0 0 $danger inset;
}

.inline-notification-box-shadow-success {
  box-shadow: 4px 0 0 0 $success inset;
}

.inline-notification-box-shadow-secondary-light {
  box-shadow: 4px 0 0 0 $blue-light inset;
}

.inline-notification-box-shadow-warning {
  box-shadow: 4px 0 0 0 $warning inset;
}

.border-neutral-400 {
  border-color: $neutral-400 !important;
}