
.scrollbar-thin {
  &::-webkit-scrollbar {
    width:  .5rem;
    height: .5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 0.3125rem;
    border: 1px solid #DEDFE3;
  }
  &::-webkit-scrollbar-track {
    background: #DEDFE3;
  }

  // Standard version (Firefox only for now)
  scrollbar-width: thin;
  scrollbar-color: #949494 #DEDFE3;
}