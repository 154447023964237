@import '../variables';

.h-auto {
  height: auto;
}

.dark {
  color: $gray-600 !important;
}

.red-text-color {
  color: $red !important;
}

.green {
  color: $green !important;
}
.img-size {
  width: 18px !important;
  height: 21px !important;
}
.yellow {
  color: $yellow !important;
}

.bg-blue {
  background-color: $indigo !important;
}

.tooltip-card-padding {
  padding: 18.5px 24px !important;
  cursor: pointer;
}
.p-4px {
  padding: 4px;
}
.border-radius-5 {
  border-radius: 5px !important;
}

.address-menu > .list-group-item {
  padding: 8px;
  color: $blue;
  font-size: 12px;
}
.address-menu > .list-group-item:hover {
  background: $list-hover;
  text-decoration: none;
}
.icon-small {
  width: 24px !important;
  height: 24px !important;
}

.img-icon {
  .icon-mini {
    width: 24px;
  }

  h5 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.orange {
  color: $orange;
}

.icon-text {
  .icon-small {
    width: 25px;
  }
  h6 {
    font-size: 12px;
    color: $indigo;
    font-weight: 700;
  }
}

.is-filter {
  background: $white;
  color: $white;
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 8px;
  border: none;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 22px 35px 0 rgba(0, 0, 0, 0.11);
  .filter-icon-2 {
    position: absolute;
    width: 12px;
    right: 7px;
    top: 7px;
  }
}
.card-btn {
  width: 62px;
  height: 36px;
  border: none;
  border-radius: 5px;
  color: $white;
  font-size: 16px;
  font-weight: 400;
  background-color: $blue-light;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: revert;
  margin-left: auto;
  margin-right: 25px;
}
.big-card {
  border-radius: 6px;
  // box-shadow: 0 22px 45px 0 rgb(0 0 0 / 11%);
  background-color: $white;
  padding: 12px 24px 12px 24px;
  max-width: 100%;
  .text-green {
    color: $green;
  }

  .card-h {
    margin-bottom: 2px;
    font-size: 20px;
    font-weight: 600;
    color: $gray-600;
  }

  .card-detail {
    font-size: 14px;
    font-weight: 400;
    color: $gray-600;
  }

  .big-card-footer {
    h4 {
      font-size: 16px;
      font-weight: 600;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.top-border-0 {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.bottom-border-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-menu {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: $white;
  display: none;
}

.menu-full-width {
  position: absolute;
  margin-top: 0 !important;
  width: 100%;
  z-index: 1;
}

.filter-full-width {
  background-color: $white;
  position: absolute;
  min-width: 345px;
  top: 50px;
  width: 100%;
  z-index: 2;
  left: -280;
}

.address-menu {
  padding-left: 6px;
  padding-right: 6px;

  .list-group-item {
    position: relative;
    font-weight: 400;
  }
}

.cross-icon {
  display: none;
}

.fa-angle-down {
  font-size: 30px;
  font-weight: 300;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.6s;
}

.selected {
  font-weight: 700 !important;
}

.selected:before {
  content: '\f00C';
  font-family: 'Font Awesome 6 Pro', monospace;
  right: 15px;
  position: absolute;
  font-size: 18px;
  top: 4px;
  display: none;
}

.info_text {
  margin: 3em 0.5em;
  text-align: center;
  font-style: italic;
}

.font-size-22 {
  font-size: 22px;
}
.w-314 {
  width: 314px;
}

.heading-margin {
  margin-top: 1rem;
}

.small-icon {
  font-size: 1.25em !important;
  margin-left: 0.05em;
}

@media (min-width: 991px) {
  .icon-small {
    width: 32px !important;
    height: 32px !important;
  }
  .heading-margin {
    margin-top: 2.5rem;
  }
  .tooltip-card-padding {
    padding: 24px 32px !important;
  }
}
