/* ==========================================================================
General
========================================================================== */
.hidden {
  //unused?
  display: none;
  visibility: hidden;
}

input::-ms-clear {
  display: none;
}

.no-after {
  &:after {
    display: none;
  }
}

@media (min-width: 992px) {
  .no-after-lg {
    &:after {
      display: none;
    }
  }
}

.pointer-events-auto {
  pointer-events: auto;
}

.white {
  color: #ffffff;
}

.gray {
  color: #b3b3b3;
}

.green {
  color: #258875;
}

.font-size-11 {
  font-size: 11px !important;

  p {
    font-size: 11px !important;
  }
}

.font-size-14 {
  font-size: 14px !important;

  p {
    font-size: 14px !important;
  }
}

.font-size-16 {
  font-size: 16px !important;

  p {
    font-size: 16px !important;
  }
}

.font-size-0_8rem {
  font-size: 0.8rem;
}

.fw-500 {
  font-weight: 500 !important;
}

.font-size-18 {
  font-size: 18px !important;

  p {
    font-size: 18px !important;
  }
}

.font-size-20 {
  font-size: 20px;

  p {
    font-size: 20px;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.no-hyphenate {
  hyphens: none !important;
}

.no-box-shadow,
.no-box-shadow:before,
.no-box-shadow:after {
  box-shadow: none !important;
}

// todo fix ".expander-item .btn" rules
.expander-item {
  .btn.expander-item-btn-fix {
    letter-spacing: 0.88px !important;

    &:not(:hover) {
      text-decoration: none !important;
    }
  }

  .product-card.extended button.expander-item-btn-fix {
    top: 0px !important;
  }
}

.table-bs5-fix > :not(caption) > * > * {
  box-shadow: none !important;
  border-bottom-width: 0px !important;
}

.table-bs5-padding-fix > :not(caption) > * > * {
  box-shadow: none !important;
  padding: 0px !important;
  border-bottom-width: 0px !important;
}

/* ==========================================================================
temp solution until .expander-item - refactoring is done
========================================================================== */

.clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
_modal overrides
========================================================================== */
.modal-content {
  background-color: inherit;
  border: none;
}

.modal-backdrop {
  opacity: 0;

  &.fade {
    transition: none;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal.xlarge {
  > div {
    max-width: 900px;
  }
}

/* ==========================================================================
_forms overrides
========================================================================== */
.hint-error {
  color: #db0033;
  font-size: 11px;
  font-weight: 300;
}

/* ==========================================================================
_cover.scss overrides
========================================================================== */
.page-content {
  min-height: 100vh;
}

.content-box {
  .form-buttons mwe-button:not(:last-child) button {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .content-box {
    .form-buttons mwe-button:not(:last-child) button {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}

/* ==========================================================================
_tabs overrides
========================================================================== */
.tabs {
  background-color: white;
}

/* ==========================================================================
_list_groups overrides
========================================================================== */
.list-group-item > button:focus {
  outline: none !important;
}

.expander-item {
  .collapse-button i:before {
    content: '\f106';
  }

  .collapse-button.collapsed i:before {
    content: '\f107';
  }

  .alert-info p {
    color: #fff;
  }

  .summary-list li {
    padding: 5px 0;

    p {
      overflow: inherit;

      &.iphone-hack {
        display: inline;
      }
    }
  }

  .product-card-summary li {
    padding: 0;
  }

  .collapse-button {
    outline: none;
  }
}

.summary-card {
  color: black;
  height: 100%;
  min-height: 0;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h6 {
    align-self: flex-start;
    padding-left: 1rem;
  }
}

/* ==========================================================================
_cards overrides
========================================================================== */
@media (max-width: 768px) {
  .tooltip-card {
    &.single {
      &:after {
        left: 50% !important;
      }
    }
  }
}

.product-card.extended button:not(.btn-clean) {
  top: 12px;
}

.tooltip-card {
  &.no-after {
    &:after {
      display: none;
    }
  }

  div.row.separator.white {
    background-color: white;
    color: #000460;
    border-top: 1px solid white;
    margin-bottom: -25px;
    padding-bottom: 25px;

    p {
      color: #000460;
    }
  }
}

/* --- Banner Popup --- */
mwe-banner-popup .popup-inner p {
  text-align: start;
}

/* ==========================================================================
_popups overrides
========================================================================== */
@media (min-width: 992px) {
  .popup-content {
    padding: 25px;
  }
}

.campaign-popup-button > button {
  min-width: 250px;
}

.campain-text {
  font-size: 16px;
  font-weight: 500;
  color: #000460;
}

.campaign-close {
  position: relative;
  float: right;
  margin-top: 10px;
  margin-right: 15px;
  z-index: 1;
  font-size: 20px;

  i {
    color: #000460;
    cursor: pointer;
  }
}

/* ==========================================================================
_fullscreen_process overrides
========================================================================== */
.fullscreen-process {
  .process-content.wizard-additional-footer {
    margin-bottom: 0px;
  }
}

.fullscreen-process {
  //müll
  .process-content {
    mwe-process-summary,
    > mwe-relocation-new-address,
    > mwe-status-summary {
      > h3 {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.08;
        margin-bottom: 30px;
      }
    }
  }
}

/* ==========================================================================
relocation
========================================================================== */
.pointer {
  cursor: pointer;
}

.tooltip-card.disabled .dropdown-tooltip {
  pointer-events: visible;
}

mwe-relocation-summary,
mwe-move-out-summary,
mwe-relocation-status {
  mwe-wizard-product-card-list mwe-wizard-product-card-container .product-card {
    margin-bottom: 35px !important;
  }
}

/* ==========================================================================
datepicker
========================================================================== */
.datepicker {
  margin: auto;
}

/* ==========================================================================
cookie consent
========================================================================== */
.cookie-consent-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.52;
}

.cookie-consent-clamp {
  -webkit-line-clamp: 3;
  max-height: 70px;
}

.product-card {
  &.h-auto {
    .col-2 > h3,
    .col-2 > .fas,
    .col-3 > h3 {
      display: none;
    }
  }

  &.tarif-selection {
    align-items: normal;
    min-height: 230px;
    height: 230px;
    display: block;

    &.no-button {
      padding: 0 25px;
    }

    .product-card-inner {
      text-align: left;
      padding-top: 40px;
    }

    .product-category {
      text-align: center;
      margin-bottom: 25px;
    }

    .form-control {
      font-weight: bold;
      text-transform: uppercase;
      color: #000460;
    }

    .tarif-link {
      margin-top: -25px;
    }
  }
}

.product-card.extended {
  &.product-card-fernwaerme {
    height: 165px;
    min-height: 165px;
  }
}

.product-card-dropdown {
  &.price-indication {
    color: #000;
    font-size: 0.8rem;
    margin: 20px 0 0;
    padding: 15px 35px 0;

    .text-uppercase {
      color: #000460;
    }

    .row {
      padding: 0;
    }
  }

  &.tarif-selection {
    padding: 4px 25px;
    border-top: none;
    margin-top: 0;
    font-size: 0.8rem;

    .row {
      padding-left: 0;
      padding-right: 0;
    }

    img {
      float: left;
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }

    .is-link {
      font-size: 0.8rem;
    }
  }

  &.tarif-selection-fernwaerme {
    padding: 15px;
    border-top: none;

    .product-card-fernwaerme {
      border: 1px solid #d8d8d8;
      border-width: 1px 0;
      padding: 20px 0;
      margin-top: 0;

      ul {
        margin: 0;
        padding: 0 30px;

        li {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }

      .product-card-info {
        margin-top: 10px;
      }
    }
  }
}

.contract-details {
  h6 {
    font-size: 14px;
  }

  .custom-control-label,
  .supply-information {
    font-size: 14px;
    color: #000460;
  }

  .custom-control-label {
    font-size: 14px !important;
  }

  .product-card-info p {
    font-size: 14px;
    font-weight: normal;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* ==========================================================================
input type="number" default overrides
========================================================================== */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ==========================================================================
SwipeComponents
========================================================================== */
mwe-horizontal-swiper {
  display: block;
  position: relative;

  .swiper.swiper-container {
    overflow: hidden;
  }
}

/* ==========================================================================
bill-info
========================================================================== */

.image-box-not-available {
  padding: 50px 0 25px 0;

  img {
    width: 75px;
    height: 67px;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

/* ==========================================================================
meter-reading
========================================================================== */

.p-meter-reading {
  max-width: 100%;
  color: #000;
  padding: 0 40px;
}

@media (min-width: 768px) {
  .img-meter-reading,
  .p-meter-reading {
    max-width: 60%;
  }
}

.card-product-category-icon {
  width: 75px;
}

/* ==========================================================================
maintenance
========================================================================== */
@media (min-width: 766px) {
  .maintenance-mode {
    .maintenance-hero {
      height: 100vh;
    }
  }
}

/* ==========================================================================
 interactive invoices
========================================================================== */
// product details
.interactive-invoice-product-details-info-card {
  min-height: 260px;

  @media (max-width: 990px) {
    min-height: 240px;
  }

  @media (max-width: 767px) {
    min-height: 0px;
  }
}

/* ==========================================================================
consumption
========================================================================== */
.consumption-page {
  .address-menu .list-group-item {
    padding-right: 45px;
  }
}

.btn-group.consumption-switch {
  //obsolete?
  margin-left: -10px;

  label.btn {
    padding: 10px 0;
  }

  .btn {
    height: 40px;

    &.active {
      color: #fff;
      background: #3177aa;
      border-color: #3177aa;
    }
  }
}

@media screen and (max-width: 576px) {
  .btn-group.consumption-switch {
    .btn {
      min-width: 132px;
    }
  }
}

/* ==========================================================================
Chatbot
========================================================================== */

.chatbot {
  background-color: #000460;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 1031;
  height: 700px;
  width: 500px;
}

@media (max-width: 576px) {
  .chatbot {
    top: 0px;
    height: 94%;
    width: 100%;
    right: 0px;
  }
}

@media (min-width: 577px) {
  @media (min-height: 600px) and (max-height: 775px) {
    .chatbot {
      height: 523px;
    }
  }

  @media (min-height: 525px) and (max-height: 599px) {
    .chatbot {
      height: 450px;
    }
  }

  @media (max-height: 524px) {
    .chatbot {
      top: 0px;
      height: 90%;
      width: 100%;
      right: 0px;
    }
  }
}

.chatbot-header {
  margin-top: 10px;
  margin-right: 15px;
  z-index: 1;
  font-size: 20px;

  label,
  i {
    color: white;
  }

  i {
    cursor: pointer;
  }
}

.chatbot-hide-btn {
  position: fixed;
  bottom: 75px;
  right: 40px;
  z-index: 100;
}

@media (min-width: 768px) {
  .chatbot-hide-btn {
    bottom: 50px;
  }
}

/* ==========================================================================
bootstrap tooltip styling
========================================================================== */
.mwe-tooltip .tooltip-inner {
  background-color: $blue;
}

.mwe-tooltip .arrow::before {
  border-top-color: darkgreen;
}

/* ==========================================================================
Disabling phone-number detection for iOS Safari
========================================================================== */

.disabled-safari-phone-detection {
  pointer-events: none;
  cursor: default !important;
  text-decoration: none !important;

  span {
    font-weight: 400;
    color: #07112a !important;
  }

  a[href^='tel:'] {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
    pointer-events: none;
  }
}

/* ==========================================================================
Style Fixes
========================================================================== */
.green-check {
  min-width: 1.4rem;
}

.expander-item {
  .alert-warning {
    .alert-inner p {
      color: #fff;
    }
  }
}

/* ==========================================================================
Temporary fixes that will be migrated to the theme
========================================================================== */

.minw-0 {
  min-width: 0rem;
}

.modal-dialog-scrollable :is(mwe-association-suggestions, mwe-custom-popup) {
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

a.edit-link-with-icon {
  &::after {
    content: '\f044';
    font-family: 'Font Awesome 6 Pro';
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
}

.backend-process-step-icon {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.steps.is-vertical.steps-line {
  mwe-timeline-item {
    &:not(:last-child) .item {
      .image {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          top: 10px;
          width: 2px;
          background: #005797;
          left: 23px;
        }
      }
    }
  }
}

/* ==========================================================================
Temporary Friendly Captcha
========================================================================== */

.frc-captcha {
  background: transparent !important;
  border: none !important;
  max-width: 100% !important;

  &.text-danger {
    .frc-text {
      @extend .text-danger;
    }

    .frc-icon {
      fill: $red !important;
      stroke: $red !important;
    }
  }

  .frc-container {
    align-items: start;
  }

  .frc-banner {
    a {
      color: #3861f6 !important;
      opacity: 1 !important;
    }
  }

  .frc-text {
    @extend .h5;
  }

  .frc-progress {
    margin: 0 0 0.25rem 0 !important;
    height: 0.5rem !important;
    background: #d9d9d9 !important;
    -webkit-appearance: none;
    appearance: none;

    &[value]::-webkit-progress-value {
      background: $blue !important;
    }
  }

  .frc-button {
    max-width: 312px;
    color: $blue;
    text-align: left;
  }

  .frc-banner {
    a {
      color: inherit !important;
    }
  }

  .frc-icon {
    margin-left: 0 !important;
    margin-right: 1rem !important;
    stroke: $blue !important;
    fill: $blue !important;
  }
}

//
@include media-breakpoint-down(md) {
  :is(.offcanvas, #id) {
    inset: 0;
    width: 100%;
  }
}

.transition-transform {
  transition: transform 0.2s ease-in-out;
}

.rotate-x-180 {
  transform: rotateX(180deg);
}
