@import '../variables';

.maintenance-hero {
  color: white;
  background-image: url($we-assets-images-path + '/maintenance_background.svg');
  background-size: cover;
  background-color: $orange;
  background-repeat: no-repeat;
  background-position: calc(50%) calc(-140px);

  .menu {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.22;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.menu-icon {
  content: url($we-assets-icons-path + '/we-stencil.svg');
}

.maintenance-footer {
  background-color: #dedfe3;

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: center;
    color: $indigo;
  }
}

.maintenance-img {
  margin-bottom: 20px;
  object-fit: cover;
}

.maintenance-bg {
  background-image: none;
}

.maintenance-guy-bg {
  background-image: none;
}

.maintenance-text {
  margin-top: 20px;
  background-color: #f7f8f8;
}

.maintenance {
  h1 {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    color: $indigo;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    color: $indigo;
  }

  p {
    margin-top: 21px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $gray-600;
  }

  .btn {
    width: 20%;
  }
}

@media (min-width: 766px) {
  .maintenance-text {
    margin-top: 220px;
    background-color: transparent;
  }

  .maintenance {
    h1 {
      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      text-transform: none;
      color: $indigo;
    }

    h2 {
      height: 32px;
      font-size: 24px;
      font-weight: 600;
      text-transform: none;
      font-stretch: normal;
      font-style: normal;
      color: $indigo;
    }

    p {
      margin-top: 35px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: $gray-600;
    }

    .btn {
      width: 20%;
    }
  }

  .maintenance-hero {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    color: white;
    background: #eeeff1;
    background-size: cover;

    .menu {
      color: $indigo;
    }

    .menu-icon {
      content: url($we-assets-icons-path + '/we-stencil-blue.svg');
    }
  }

  .maintenance-footer {
    padding-left: 12.5%;

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      line-height: 1.29;
      color: #07112a;
    }
  }
}

@media (min-height: 800px) {
  .maintenance-footer {
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 766px) {
  .maintenance-guy-bg {
    background-size: 320px;
    background-image: url($we-assets-images-path + '/maintenance_illustration.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 10px) calc(300px);
  }

  .maintenance-bg {
    height: 100%;
    background-size: 1200px;
    background-image: url($we-assets-images-path + '/maintenance_background.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 800px) calc(-100px);
  }
}

@media (min-width: 900px) {
  .maintenance-guy-bg {
    height: 100%;
    background-size: 420px;
    background-position: calc(100%) calc(260px);
  }

  .maintenance-bg {
    height: 100%;
    background-position: calc(100% + 640px) calc(-100px);
  }
}
@media (min-width: 1320px) {
  .maintenance-guy-bg {
    height: 100%;
    background-size: 720px;
    background-position: calc(100%) calc(100px);
  }

  .maintenance-bg {
    height: 100%;
    background-size: 1800px;
    background-position: calc(100% + 920px) calc(-380px);
  }
}

@media (min-width: 2300px) {
  .maintenance-guy-bg {
    height: 100%;
    background-size: 1100px;
    background-position: calc(90% + 180px) calc(220px);
  }

  .maintenance-bg {
    height: 100%;
    background-size: 2600px;
    background-position: calc(100% + 1140px) calc(-400px);
  }
}

/* navigation styles */
.hero-row-height {
  min-height: 0;
}

.blue-nav {
  color: white !important;
}

@media (min-width: 766px) {
  .blue-nav {
    color: $indigo !important;
  }
}

.nav-menu {
  background: $white;
}

.nav-item-vert {
  &:not(:first-child) {
    margin: 0 0 0 20px;
  }
  .nav-link {
    padding: 0;
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    &:hover {
      color: white;

      &::after {
        display: block !important;
        text-decoration: none;
        content: '';
        width: 30px !important;
        position: absolute;
        bottom: -5px;
        border-width: 0 0 2px;
        border-style: solid;
        left: 30px !important;
        right: 0;
        margin: auto;
      }
    }

    & > i {
      font-size: 21px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: -20px;
      text-align: center;
    }

    &.active {
      &::after {
        display: block !important;
        text-decoration: none;
        content: '';
        width: 30px !important;
        position: absolute;
        bottom: -5px;
        border-width: 0 0 2px;
        border-style: solid;
        left: 30px !important;
        right: 0;
        margin: auto;
      }
    }

    &.logout-link {
      i {
        padding: 8px;
        border: 2px solid;
        font-size: 16px;
        opacity: 0.8;
        transition: opacity 0.3s, visibility 0.3s;
      }
      &:hover {
        i {
          opacity: 1;
          transition: opacity 0.3s, visibility 0.3s;
        }
        &::after {
          display: none !important;
        }
      }
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &:not(:first-child) {
      margin: 0 0 0 30px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &:not(:first-child) {
      margin: 0 0 0 40px;
    }
  }
}

.maintenance-nav-bg {
  background-image: none;
}

.maintenance-nav-guy-bg {
  background-image: none;
}

.tab-bg {
  background-color: white;
}

.footer-padding-bottom {
  padding-bottom: 60px;
}

.maintenance-padding-bottom {
  min-height: 560px;
}

@media (min-width: 766px) {
  .maintenance-nav-guy-bg {
    background-size: 320px;
    background-image: url($we-assets-images-path + '/maintenance_illustration.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 10px) calc(300px);
  }

  .maintenance-nav-bg {
    height: 100%;
    background-size: 1200px;
    background-image: url($we-assets-images-path + '/maintenance_background.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 800px) calc(20px);
  }

  .hero-row-height {
    min-height: calc(95vh);
  }

  .footer-padding-bottom {
    padding-bottom: 0;
  }

  .maintenance-padding-bottom {
    padding-bottom: 0;
  }
}

@media (min-width: 900px) {
  .maintenance-nav-guy-bg {
    height: 100%;
    background-size: 420px;
    background-position: calc(100%) calc(260px);
  }

  .maintenance-nav-bg {
    height: 100%;
    background-position: calc(100% + 640px) calc(100px);
  }
}
@media (min-width: 1320px) {
  .maintenance-nav-guy-bg {
    height: 100%;
    background-size: 720px;
    background-position: calc(100%) calc(100px);
  }

  .maintenance-nav-bg {
    height: 100%;
    background-size: 1800px;
    background-position: calc(100% + 920px) calc(-120px);
  }
}

@media (min-width: 2300px) {
  .maintenance-nav-guy-bg {
    height: 100%;
    background-size: 1100px;
    background-position: calc(90% + 180px) calc(220px);
  }

  .maintenance-nav-bg {
    height: 100%;
    background-size: 2600px;
    background-position: calc(100% + 1140px) calc(-40px);
  }
}
