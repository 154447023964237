@import '../variables';

.progress {
  background-color: transparent;
  border-radius: 0;
}

.progress.thin {
  height: 5px;
  min-height: 5px;
}

.progress-bar {
  background: $blue;
}

ul.step-progress {
  margin: 0;
  padding: 12px 0 0 0;
  list-style: none;
  display: flex;
  align-items: center;

  li {
    position: relative;
    color: white;
    text-align: center;
    flex: 1;
    min-width: 0;

    .step-label {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 11px;
      font-weight: 400;
      color: $gray;
      line-height: 12px;
      text-transform: none;
      padding-bottom: 10px;

      height: 24px;
      z-index: 1;
      border-bottom: 2px solid $gray;
    }

    &:nth-child(1) {
      .step-label {
        border-bottom: none;
      }
      .step-label:before {
        z-index: -1;
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 24px;
        width: 50%;
        border-bottom: 2px solid $gray;
      }
    }

    &:last-child {
      .step-label {
        border-bottom: none;
      }
      .step-label:before {
        z-index: 1;
        content: '';
        position: absolute;
        bottom: 0;
        height: 24px;
        left: 0;
        width: 50%;
        border-bottom: 2px solid $gray;
      }
    }

    .step-inner {
      position: relative;
      z-index: 2;
      width:  1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: -9px;
      margin-left: auto;
      margin-right: auto;

      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 1;
      text-align: center;

      color: $gray;
      i {
        display: none;
        font-size: 8px;
      }
      background: white;

      border: 1px solid $gray;
      border-radius: 50%;
    }

    &.active {
      .step-inner {
        color: $white;
      }
      .step-title {
        color: $blue;
      }
    }
  }

  li.active {
    .step-inner {
      background: $blue;
      color: $white;
      border: 1px solid $blue;
    }
    .step-inner:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: -33px;
      left: -1px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/icons/chevron-down.svg') no-repeat center;
    }

    .step-label {
      border-bottom: none;
      color: $blue;
    }

    &:not(:first-child) {
      .step-label:before {
        z-index: 1;
        content: '';
        position: absolute;
        bottom: 0;
        height: 24px;
        left: 0;
        width: 50%;
        border-bottom: 2px solid $green;
      }
    }
    .step-label:after {
      z-index: 1;
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 24px;
      width: 50%;
      border-bottom: 2px solid $gray;
    }

    &:last-child {
      .step-label {
        border-bottom: none;
      }
      .step-label:before,
      .step-label:after {
        z-index: -1;
        content: '';
        position: absolute;
        bottom: 0;
        height: 24px;
        left: 0;
        width: 50%;
        border-bottom: 2px solid $green;
      }
    }
  }

  li.visited {
    &:hover {
      cursor: pointer;

      .step-inner {
        color: $white;
        background: $blue;
        border: 1px solid $blue;
      }
    }

    .step-inner {
      background: $green;
      border: 1px solid $green;

      i {
        display: block;
        font-size: 8px;
      }
      span {
        display: none;
      }
    }
    .step-label {
      border-bottom: 2px solid $green;
      color: $blue;
    }
    &:nth-child(1) {
      .step-label {
        border-bottom: none;
      }
      .step-label:before {
        z-index: -1;
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 24px;
        width: 50%;
        border-bottom: 2px solid $green;
      }
    }

    &:last-child {
      .step-label {
        border-bottom: none;
      }
      .step-label:before {
        z-index: -1;
        content: '';
        position: absolute;
        bottom: 0;
        height: 24px;
        left: 0;
        width: 50%;
        border-bottom: 2px solid $green;
      }
    }
  }

  li:focus-visible {
    outline: none;
  }

  li:focus-visible:before {
    content: '';
    border: 1px dashed $blue;
    border-radius: 5px;

    position: absolute;
    top: -50%;
    left: 25%;
    width: 50%;
    height: 200%;
  }
}

@media screen and (max-width: 991px) {
  ul.step-progress {
    li:focus-visible:before {
      content: '';
      border: 1px dashed $blue;
      border-radius: 5px;

      position: absolute;
      top: -50%;
      left: 12%;
      width: 76%;
      height: 200%;
    }
  }
}

@media screen and (max-width: 575px) {
  ul.step-progress {
    li:focus-visible:before {
      content: '';
      border: 1px dashed $blue;
      border-radius: 5px;

      position: absolute;
      top: -50%;
      left: 0;
      width: 100%;
      height: 200%;
    }
  }
}
