@import '../variables';

.expander-element {
  padding: 25px;
}

.expander-component:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d8d8d8;
}

.expander-item {
  .box {
    &:hover {
      cursor: pointer;
    }
  }

  label,
  h6 {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: #10265c;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    color: #07112a;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    &.clamp {
      -webkit-line-clamp: 2;
      max-height: 45px;
      overflow: hidden;
    }
  }

  //.btn {
  //  margin: 0 0 5px 0;
  //  padding: 0;
  //  font-size: 14px;
  //  font-weight: 500;
  //  font-stretch: normal;
  //  font-style: normal;
  //  line-height: 1.57;
  //  letter-spacing: normal;
  //  text-decoration: underline !important;
  //  text-decoration-thickness: 2px !important;
  //  text-underline-offset: 1px !important;
  //  text-decoration-style: solid !important;
  //}
}

.clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
