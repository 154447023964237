@import '../variables';

.bg-gradient--orange,
.has-gradient-orange {
  background: $gradient-orange;
}

.bg-gradient--blue,
.has-gradient-blue {
  background: $gradient-blue;
}

.bg-gradient--blue-light,
.has-gradient-blue-light {
  background: $gradient-blue-light;
}

.bg-gradient--green,
.has-gradient-green {
  background: $gradient-green;
}

.bg-gradient--red,
.has-gradient-red {
  background: $gradient-red;
}

.bg-gradient--yellow,
.has-gradient-yellow {
  background: $gradient-yellow;
}
