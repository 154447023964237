@import '../variables';

.modal-mobile .modal-dialog {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.modal-dialog.large {
  max-width: 671px;
}

.modal-dialog.xlarge {
  max-width: 900px;
}

.modal-mobile .modal-dialog .popup-inner {
  border-radius: 5px 5px 0 0;
}

.modal-content {
  border: none;
}

@media (max-width: 575.98px) {
  .modal-dialog--full {
    margin: 0;
    align-items: flex-start;
    height: 100%;
    background-color: $gray-200 !important;
  }

  .modal-content--full {
    height: 100% !important;
    background-color: $gray-200 !important;
  }

  .popup-content {
    background: $gray-200 !important;
    .form-control {
      background: transparent;
    }

    form {
      background: white;
      padding: 29px 20px 29px 20px;
      border-radius: 5px;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11);

      .popup-submit-btn {
        position: relative;
        top: 70px;
        right: -20px;
      }
    }
  }
}

.popup {
  margin: 0 auto;
  max-width: 400px;

  div.button.small {
    box-shadow: none;
    &:hover {
      cursor: default !important;
    }
  }
}

.popup.small {
  max-width: 400px;
}

.popup.large {
  max-width: 700px;
}

.popup.xlarge {
  max-width: 900px;
}

.popup-close {
  color: white;
  width: 60px;
  height: 100%;

  i {
    float: right;
    padding-top: 23px;
  }

  &:hover {
    cursor: pointer;
  }
}

.button {
  border-radius: 50px;
  background: #06d6a0;
  z-index: 1;
  position: relative;
  height: 60px;
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
}

.button.small {
  width: 60px;
  left: calc(50% - 30px);
  position: relative;
  top: 30px;
}

.button.small > i {
  font-size: 22.4px;
  padding-top: 20px;
}

.button.small.blue_no_gradiant {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11);
  background: $blue;
  color: $white;
}

.button.small.blue {
  background-image: linear-gradient(215deg, #3ea9ca, #06649b 52%, #0f255b);
}

.button.small.green {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11);
  background-image: linear-gradient(
    135deg,
    #73deca,
    #28a48c 35%,
    #258875 70%,
    #0f6e5c
  );
  color: $white !important;
}

.button.small.orange {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11);
  background: #eb6626;
  color: $white;
}

.button.small.yellow {
  background-image: linear-gradient(134.66deg, #FFD88C 3.37%, #D9A300 46.27%, #CB8603 89.57%);
  color: $white !important;
}

.button.small.red {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11);
  background-image: linear-gradient(
    135deg,
    #f03e7a,
    #e7295b 35%,
    #d02534 70%,
    #bb2037
  );
  color: $white;
}

.border .button {
  height: 80px;
  border: 10px solid #e6e8e6;
}

.button:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.popup-inner {
  width: 100%;
  min-height: 125px;
  text-align: center;
  position: relative;
  padding: 60px 20px 20px 20px;
  border-radius: $border-radius-lg;
  background-image: -webkit-radial-gradient(
    top center,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    white 35px
  );
  background-image: radial-gradient(
    top center,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    white 35px
  );
  background-image: -o-radial-gradient(
    top center,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    white 35px
  );
  background-image: -ms-radial-gradient(
    top center,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    white 35px
  );
}

.popup-inner.no-icon {
  background: white !important;
}

.popup-inner h3 {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 10px;
}

.popup-inner p {
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  text-align: center;
  color: #07112a;
}

.popup-inner p > a {
  font-weight: 500;
  &:after {
    display: none !important;
    text-decoration: underline;
  }
}

.popup-inner .btn-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
}

.border .popup-inner {
  background: #21252b;
}

.svg .popup-inner {
  background: #21252b;
  clip-path: url(#svgPath);
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.boxShadow .popup-inner {
  background: #21252b;
  height: 75px;
  position: relative;
  border-radius: 0;
}

.boxShadow .popup-inner:after {
  content: '';
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 0;
  height: 15px;
  background: #21252b;
}

figure {
  position: relative;
  width: 180px;
  height: 55px;
  margin: 0;
  overflow: hidden;
}
figure:before,
figure:after {
  content: '';
  position: absolute;
}

figure.left:before {
  right: -50%;
  top: 15px;
  background: transparent;
  width: 180px;
  height: 200px;
  border-radius: 18%;
  box-shadow: 0 0 0 100em #21252b;
}

figure.right:before {
  left: -50%;
  top: 15px;
  background: transparent;
  width: 180px;
  height: 200px;
  border-radius: 18%;
  box-shadow: 0 0 0 100em #21252b;
}

.icon {
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.popup-header {
  height: 60px;
  min-height: 60px;
  background: $orange;
  width: 100%;

  h6 {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #ffffff;
    margin-bottom: 0;
  }
}

.popup-content {
  background: $gray-200;
  padding: 25px;

  h3 {
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.08;
    color: #000460;
    margin-bottom: 30px;
  }
}

.popup-content-img {
  width: 100%;
  max-width: 300px;
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .wizardModal {
    background: #f5f6f6;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .popup-inner {
    padding: 60px 40px 20px 40px;
  }

  .popup-inner p {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    color: #07112a;
  }

  .popup-inner p.smaller {
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    color: #07112a;
  }

  .popup-inner h3 {
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.08;
    text-align: center;
    color: #000460;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .popup-content {
    padding: 50px 75px 50px 75px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

/* Safari 10.1+ */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .popup-inner {
      background-image: -webkit-radial-gradient(
        top center,
        circle closest-corner,
        transparent 0,
        transparent 32px,
        white 35px
      ) !important;
    }
  }
}

// Modals xxl and xxxl

@include media-breakpoint-up(sm) {
  .modal-xxl,
  .modal-xxxl {
    position: relative;
    width: auto;
    margin: var(--#{$prefix}modal-margin);
    --#{$prefix}modal-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .modal-xxl,
  .modal-xxxl {
    margin-right: auto;
    margin-left: auto;
    --#{$prefix}modal-width: #{$modal-lg};
  }
}

@include media-breakpoint-up(xl) {
  .modal-xxl {
    --#{$prefix}modal-width: #{$modal-xxl};
  }
  .modal-xxxl {
    --#{$prefix}modal-width: #{$modal-xxxl};
  }
}

.btn-modal-header {
  width: 3.875rem;
  height: 3.875rem;
  border: 0 none;

  :is(i, svg) {
    font-size: 1.5rem;
  }
}

.modal-header-notch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-mask-image: -webkit-radial-gradient(50% 50%, circle closest-corner, transparent 0, transparent 34px, white 35px);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}