
.new-frontpage {
    position: relative;
    background: #f7f8f8;
}

.new-frontpage-header {
    #desktop_view {
        display: none;
    }
    #mobile_view {
        display: block;
        background-size: cover;
        background-image: url(../../../assets/img/header-background-mobile-new.png);
        background-repeat: no-repeat;
        background-position: center;
        //position: absolute;
        //z-index: 10;
        //left: 0;
        //right: 0;
        padding: 16px 23px;
        height: 64px;
        margin-right: -1rem;
        margin-left: -1rem;
    }

    @include media-breakpoint-up(md) {

        #desktop_view {
            display: block;
            position: absolute;
            z-index: 10;
            top: 30px;
            left: 35px;
        }
        #mobile_view {
            display: none;
        }
    }
}

.new-frontpage-max-width-sm {
    max-width: 27rem;
    margin-right: auto;
    margin-left: auto;
}

.new-frontpage-max-width-md {
    max-width: 38.5rem;
    margin-right: auto;
    margin-left: auto;
}

.new-frontpage-padding-md {
    padding-top:   4rem;
    padding-right: 1.5rem;
    padding-left:  1.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
        padding-top:    7.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding-right:  4.5rem;
        padding-left:   4.5rem;
        padding-bottom: 4.5rem;

    }
}

.new-frontpage-padding-sm {
    padding-top: 2rem;
    padding-right: 1.5rem;
    padding-left:  1.5rem;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
        padding-top:    7.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 4.5rem;
    }
}

.new-frontpage-has-background {
    position: relative;
    z-index: 0;

    &:not(.new-frontpage-has-campaign) {

        @include media-breakpoint-up(md) {
            min-height: 100vh;
        }
    }
}

.new-frontpage-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: url($we-assets-images-path + '/new-frontpage-bg-desktop.svg') no-repeat;
    background-size: cover;

    .theme-override-b2b & {
        background: url($we-assets-images-path + '/new-frontpage-bg-desktop-b2b.svg') no-repeat;
        background-size: cover;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.new-frontpage-intro-img {
    display: inline-block;
    width: 214px;
    height: 163px;
    margin-right: auto;
    margin-left: auto;
    background: url($we-assets-images-path + '/illustration-login-mails-2.svg') no-repeat;

    .theme-override-b2b & {
        background: url($we-assets-images-path + '/new-frontpage-intro-b2b.svg') no-repeat;
    }
}

.new-frontpage-logo {
    display: inline-block;
    width: 188px;
    height: 33px;
    margin-right: auto;
    margin-left: auto;
    background: url($we-assets-images-path + '/logo.svg') no-repeat;

    .theme-override-b2b & {
        background: url($we-assets-images-path + '/logo-b2b.svg') no-repeat;
        width: 274px;
    }
}

.smartphone-display {
    background-image: url('data:image/svg+xml,<svg width="236" height="480" viewBox="0 0 236 480" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="236" height="480" rx="30" fill="%23EEEFF1"/><rect x="13" y="72" width="210" height="336"/><circle cx="118" cy="443" r="20" fill="%23DEDFE3"/><circle cx="118" cy="28" r="8" fill="%23DEDFE3"/></svg>');
    background-size: 100%;
    padding: 31.9% 6%;
}
