@import '../variables';

.fixed-table-container {
  /* 
    * Attach fixed-th-table to this container,
    * in order to layout fixed-th-table
    * in the same way as scolled-td-table" 
    */
  position: relative;

  // Truncate fixed-th-table
  overflow: hidden;

  .fixed-th-table-wrapper td,
  .fixed-th-table-wrapper th,
  .scrolled-td-table-wrapper td,
  .scrolled-td-table-wrapper th {
    /* 
        * Set background to non-transparent color
        * because two tables are one above another.
        */
    background: white;
  }

  .fixed-th-table-wrapper th,
  .scrolled-td-table-wrapper th {
    max-width: 13em;
  }

  .fixed-th-table-wrapper td,
  .scrolled-td-table-wrapper td {
    min-width: 8em;
  }

  .fixed-th-table-wrapper {
    // Make table out of flow
    position: absolute;
  }

  .fixed-th-table-wrapper th {
    /*
        * Place fixed-th-table th-cells above 
        * scrolled-td-table td-cells.
        */
    position: relative;
    z-index: 1;
  }

  .scrolled-td-table-wrapper td {
    /* 
        * Place scrolled-td-table td-cells
        * above fixed-th-table.
        */
    position: relative;
  }

  .scrolled-td-table-wrapper {
    // Make horizonal scrollbar if needed
    overflow-x: auto;
  }

  /* 
    * Simulating border-collapse: collapse,
    * because fixed-th-table borders
    * are below ".scrolling-td-wrapper table" borders
    */
  table {
    border-spacing: 0;
    border-collapse: separate;
  }

  th:first-child {
    border-left-width: 0;
  }

  tr:last-child td,
  tr:last-child th {
    border-bottom-width: 1px;
  }

  // Unimportant styles
  .container {
    width: 250px;
  }

  tr {
    border-top: 1px solid lightgray;
  }

  td,
  th {
    padding: 1em;
  }

  @media (min-width: 768px) {
    td,
    th {
      padding: 2em;
    }
  }

  th {
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
  }

  td {
    text-align: right;
  }

  .has-border-bottom {
    border-bottom: 1px solid $blue;
  }

  .has-no-border-right {
    border-right: none;
  }

  .has-no-border-top {
    border-top: none;
  }
}
