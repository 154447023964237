.box {
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
}

.box.select {
  padding: 24px 12px 24px 12px;
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .custom-radio {
    padding-right: 10px;
  }

  h6,
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    color: #000460;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  h6 {
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }
}
