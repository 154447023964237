
.hover-effect {
    --#{$prefix}btn-border-width: 0;
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-active-border-color: transparent;
    --#{$prefix}btn-focus-border-color: transparent;

    --#{$prefix}btn-focus-ring-offset: -3px;

    background-color: transparent;
    z-index: 0;

    &:disabled {
        div[class^='wave-'] {
            display: none;
        }
    }

    .wave-1 {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        overflow: hidden;
        border-radius: inherit;
        background-color: var(--#{$prefix}btn-bg);
        z-index: -1;
        pointer-events: none;
        @include transition(background-color 0.15s ease-in-out);

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 0;
            height: 200px;
            width: 200px;
            opacity: 0;
            transition: none;
            border-radius: 60% 90% 90% 60%;
            background-blend-mode: multiply;
        }

        &:before {
            background-image: var(--#{$prefix}btn-effect-before,linear-gradient(
                            290deg,
                            #fccf8a,
                            #f7af40 27%,
                            #eb6626 63%,
                            #e13b31
            ));
            left: 85px;
            bottom: -10px;
            transform: rotate(20deg);
        }

        &:after {
            background-image: var(--#{$prefix}btn-effect-after,linear-gradient(
                            232deg,
                            #ff5500,
                            #f7af40 27%,
                            #ff5500 63%,
                            #e13b31
            ));
            top: -10px;
            transform: rotate(180deg);
            right: 100px;
        }

        @supports (container-type: inline-size) {
            container-type: inline-size;

            &:before {
                left: 50%;
                margin-left: -20px;
            }

            &:after {
                right: 50%;
                margin-right: -5px;
            }

            @container (min-width:290px) {

                &:before {
                    right: -36px;
                    left: auto;
                    margin-left: 0;
                }

                &:after {
                    left: -50px;
                    right: auto;
                    margin-right: 0;
                }
            }

        }
    }

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show {
        .wave-1 {
            background-color: var(--#{$prefix}btn-active-bg);
        }
    }

    .wave-2 {
        display: none;
    }
}

.wave--blue {
    &:before, &:after {
        background-image: linear-gradient(
                        254deg,
                        #3ea9ca,
                        #06649b 52%,
                        #0f255b
        ) !important;
    }
}

.wave--white {
    &:before, &:after {
        background-image: linear-gradient(
                        254deg,
                        white,
                        #f7f7f7 52%,
                        #c5c5c59c
        ) !important;
    }
}


.hover-effect:hover .wave-1 {
    &:before, &:after {
        opacity: 0.9;
        animation: wave 6s linear infinite;
        transition: 2s;
    }
}



@keyframes wave {
    0% {
        -ms-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
