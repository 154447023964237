@import '../variables';

//
// Base styles
//

.btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  @include rfs($btn-font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  // scss-docs-end btn-css-vars

  --#{$prefix}btn-height: #{$input-btn-height};
  --#{$prefix}btn-icon-size: 1.5rem;

  --#{$prefix}btn-hover-box-shadow: none;

  --#{$prefix}btn-disabled-color: rgba(0,0,0,.4);
  --#{$prefix}btn-disabled-bg: #{$neutral-100};
  --#{$prefix}btn-disabled-border-color: rgba(0,0,0,.4);

  --#{$prefix}btn-focus-ring-border-width: 2px;
  --#{$prefix}btn-focus-ring-border-color: #{$blue-light};
  --#{$prefix}btn-focus-ring-offset: 3px;
  --#{$prefix}loading-spinner-color: var(--#{$prefix}btn-color);

  display: inline-flex;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  @include font-size(var(--#{$prefix}btn-font-size));
  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);
  align-items: center;
  justify-content: center;
  min-width: 210px;
  height: var(--#{$prefix}btn-height);
  position: relative;

  mask-image: none;
  -webkit-mask-image: none;

  &:hover {
    --#{$prefix}loading-spinner-color: var(--#{$prefix}btn-hover-color);

    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);

    &::after {
      display: none;
    }
  }

  .btn-check + &:hover {
    // override for the checkbox/radio buttons
    color: var(--#{$prefix}btn-color);
    background-color: var(--#{$prefix}btn-bg);
    border-color: var(--#{$prefix}btn-border-color);
  }

  &:focus-visible,
  &:focus,
  &.focus {
    outline: var(--#{$prefix}btn-focus-ring-border-width) dashed var(--#{$prefix}btn-focus-ring-border-color);
    outline-offset: var(--#{$prefix}btn-focus-ring-offset);
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:focus-visible + & {
    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);
    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus-visible {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }

  .btn-check:checked:focus-visible + & {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    --#{$prefix}loading-spinner-color: #{$blue-500};
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
              $hover-border: shade-color($value, $btn-hover-border-shade-amount),
              $active-background: shade-color($value, $btn-active-bg-shade-amount),
              $active-border: shade-color($value, $btn-active-border-shade-amount)
      );
    } @else if $color == "dark" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
              $hover-border: tint-color($value, $btn-hover-border-tint-amount),
              $active-background: tint-color($value, $btn-active-bg-tint-amount),
              $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    } @else {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  --#{$prefix}btn-padding-x: 0;
  --#{$prefix}btn-padding-y: 0;
  --#{$prefix}btn-font-weight: #{$font-weight-bold};
  --#{$prefix}btn-font-size: 1rem;
  --#{$prefix}btn-color: #{$btn-link-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: 0;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: var(--#{$prefix}btn-color);
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-hover-bg: transparent;
  --#{$prefix}btn-active-color: #{$btn-link-hover-color};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;
  --#{$prefix}btn-disabled-color: #{rgba($black, .4)};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-box-shadow: 0 0 0 #000; // Can't use `none` as keyword negates all values when used with multiple shadows
  --#{$prefix}btn-focus-shadow-rgb: #{$btn-link-focus-shadow-rgb};

  --#{$prefix}btn-focus-ring-offset: 6px;

  min-width: 0;
  height: auto;

  @if $enable-gradients {
    background-image: none;
  }

  &:focus-visible {
    color: var(--#{$prefix}btn-color);
  }

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}


// General

.btn-md {
  --#{$prefix}btn-padding-x: #{.75rem};
  --#{$prefix}btn-height: #{$input-btn-height-md};
  --#{$prefix}btn-icon-size: 1rem;
  min-width: 0;
}

.btn-sm {
  @include rfs(11px, --#{$prefix}btn-font-size);
  --#{$prefix}btn-box-shadow: none;
  --#{$prefix}btn-height: #{$input-btn-height-sm};
  --#{$prefix}btn-icon-size: 1rem;
  min-width: 0;
}

.btn-xs {
  --#{$prefix}btn-box-shadow: none;
  --#{$prefix}btn-height: #{$input-btn-height-xs};
  --#{$prefix}btn-icon-size: .75rem;
  min-width: 0;
}

.btn-focus-as-background {
  --#{$prefix}btn-focus-ring-border-color: var(--#{$prefix}btn-bg);
}

.btn-focus-white {
  --#{$prefix}btn-focus-ring-border-color: #{$white};
}

.tab-btns {
  border-radius: 20px;
  overflow: hidden;
  .nav-item {
    width: 50%;
    height: 32px;
    margin: 0 !important;

    a {
      background-color: transparent;
      color: $blue-light !important;
      padding: 0 10px !important;
      font-weight: 600 !important;
      font-size: 12px !important;
      text-align: center;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: $blue-light !important;
        color: $white !important;
        border-radius: 0 !important;
      }
      &::after {
        display: none !important;
      }
      i {
        top: 0 !important;
        padding-top: 0 !important;
        font-size: 12px !important;
      }
    }
    &:first-child {
      a {
        border: 1px solid $blue-light;
        border-right: 0 none;
        border-radius: 50px 0 0 50px;
      }
    }
    &:last-child {
      a {
        border: 1px solid $blue-light;
        border-left: 0 none;
        border-radius: 0 50px 50px 0;
      }
    }
  }
  &.tertiary {
    li {
      a {
        color: $blue !important;
        &.active {
          background-color: $blue !important;
          color: $white !important;
        }
      }

      &:first-child {
        a {
          border: 1px solid $blue;
          border-right: 0 none;
        }
      }
      &:last-child {
        a {
          border: 1px solid $blue;
          border-left: 0 none;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .tab-btns {
    width: inherit;
    li {
      min-width: 50%;
    }
  }
}

.btn-nopadding {
  padding-top: 4px !important;
}
.secondary_button_icon {
  .patternlib-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btn-spinner {
  height: 30px;
  width: 30px;
  // negative width
  position: relative;
  right: 10px;

  &.only-spinner {
    margin-left: 0;
    right: 0;
    font-size: 0;
  }

  &.darker {
    filter: contrast(0.5);
  }
}

.loading-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 3px solid var(--#{$prefix}loading-spinner-track-color, rgba($black, 0.08));
    border-top-color: var(--#{$prefix}loading-spinner-color, #{$blue-500});
    border-radius: $border-radius-pill;
    transform-origin: center;

    transition: border-color 0.2s;
    animation: spin 1.5s infinite linear;
  }
}

.loading-spinner-white {
  --#{$prefix}loading-spinner-track-color: #{rgba($white, 0.08)};
  --#{$prefix}loading-spinner-color: #{$white};
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

//a.btn {
//  padding-top: 18px;
//}

.card > .button.white {
  background: $white;
  color: $blue;
}

a.has-btn {
  &:hover {
    text-decoration: none;

    &::after {
      display: none;
    }
  }
}

// Primary
.btn-primary,
.btn-primary--orange {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: #{$orange};

  --#{$prefix}btn-hover-bg: #{$orange-600};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$orange-600};

  --#{$prefix}btn-active-bg: #{$orange-700};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-border-color: #{$orange-700};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}loading-spinner-track-color: #{rgba($white,.08)};
}

.btn-tertiary,
.btn-primary-tertiary--blue {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: #{$blue};
  --#{$prefix}btn-border-color: transparent;

  --#{$prefix}btn-hover-bg: #{$blue-light};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;
}

.btn-secondary,
.btn-primary--blue {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: #{$blue-light};
  --#{$prefix}btn-border-color: transparent;

  --#{$prefix}btn-hover-bg: #{$blue-700};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue-800};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}loading-spinner-track-color: #{rgba($white,.08)};

  --#{$prefix}btn-effect-before: linear-gradient(
                  254deg,
                  #3ea9ca,
                  #06649b 52%,
                  #0f255b
  );

  --#{$prefix}btn-effect-after: linear-gradient(
                  254deg,
                  #3ea9ca,
                  #06649b 52%,
                  #0f255b
  );
}

.btn-secondary.facebook,
.btn-primary--blue.facebook {
  --#{$prefix}btn-bg: #4267b2;
  --#{$prefix}btn-hover-bg: #{$blue};
  --#{$prefix}btn-padding-x: 1.25rem;
  display: inline-flex;
  align-items: center;

  > i {
    padding-right: 12px;
    font-size: 20px;
    //top: 2px;
    position: relative;
  }
}

.btn-secondary.google,
.btn-primary--blue.google {
  --#{$prefix}btn-bg: #2b83fc;
  --#{$prefix}btn-hover-bg: #{$blue};
  --#{$prefix}btn-padding-x: 1.25rem;
  display: inline-flex;
  align-items: center;

  > i {
    padding-right: 12px;
    font-size: 20px;
    //top: 2px;
    position: relative;
  }
}

.btn-ghost,
.btn-primary--white {
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-hover-bg: #{rgba($white,.9)};
  --#{$prefix}btn-hover-color: #{$blue-light};
  --#{$prefix}btn-hover-border-color: #{rgba($white,.9)};

  --#{$prefix}btn-active-color: #{$blue-light};
  --#{$prefix}btn-active-bg: #{rgba($white,.7)};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}loading-spinner-track-color: #{rgba($black,.08)};
  --#{$prefix}loading-spinner-color: #{$gray};

  --#{$prefix}btn-disabled-color: #{rgba($white,.6)};
  --#{$prefix}btn-disabled-bg: #{rgba($white,.12)};
  --#{$prefix}btn-disabled-border-color: #{rgba($white,.6)};

  &:hover {
    --#{$prefix}loading-spinner-color: #{$white};
  }

  --#{$prefix}btn-effect-before: linear-gradient(
                  254deg,
                  white,
                  #f7f7f7 52%,
                  #c5c5c59c
  );

  --#{$prefix}btn-effect-after: linear-gradient(
                  254deg,
                  white,
                  #f7f7f7 52%,
                  #c5c5c59c
  );

  &.hover-effect {
    --#{$prefix}btn-hover-color: #{$blue};
  }
}

.btn-gray {
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-bg: #{$gray-300};
  --#{$prefix}btn-border-color: transparent;

  --#{$prefix}btn-hover-bg: #{$blue-light};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue-light};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-color: #{$gray};
  --#{$prefix}btn-disabled-bg: #{$gray-300};
  --#{$prefix}btn-disabled-border-color: transparent;
}

// Secondary
.btn-primary-outline,
.btn-secondary--orange {
  --#{$prefix}btn-color: #{$orange};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: #{$orange};
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$orange-600};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$orange-600};

  --#{$prefix}btn-active-bg: #{$orange-700};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-border-color: #{$orange-700};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-bg: transparent;
}

.btn-primary-transparent,
.btn-tertiary--orange {
  --#{$prefix}btn-color: #{$orange};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$orange-600};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-bg: #{$orange-700};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-bg: transparent;
}

.btn-secondary-outline,
.btn-secondary--blue {
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: #{$blue-light};
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$blue-700};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$blue-700};

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue-800};
  --#{$prefix}btn-active-border-color: #{$blue-800};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-bg: transparent;
}

.btn-secondary-transparent,
.btn-tertiary--blue {
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$blue-700};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue-800};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-bg: transparent;
}

.btn-ghost-outline,
.btn-secondary--white {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: #{$white};
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{rgba($white,.9)};
  --#{$prefix}btn-hover-color: #{$blue-light};
  --#{$prefix}btn-hover-border-color: #{rgba($white,.9)};

  --#{$prefix}btn-active-color: #{$blue};
  --#{$prefix}btn-active-bg: #{rgba($white,.7)};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-color: #{rgba($white,.6)};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{rgba($white,.6)};

  --#{$prefix}btn-focus-ring-border-color: #{$white};

  --#{$prefix}loading-spinner-track-color: #{rgba($white,.08)};
}

.btn-ghost-transparent,
.btn-tertiary--white {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{rgba($white,.9)};
  --#{$prefix}btn-hover-color: #{$blue-light};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$blue};
  --#{$prefix}btn-active-bg: #{rgba($white,.7)};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-disabled-color: #{rgba($white,.6)};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;

  --#{$prefix}btn-focus-ring-border-color: #{$white};

  --#{$prefix}loading-spinner-track-color: #{rgba($white,.08)};
}

.btn-secondary-tertiary--blue {
  --#{$prefix}btn-color: #{$blue};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: #{$blue};
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$blue-light};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$blue};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: none;
}

// Bubble Buttons
.btn-bubble {
  height: 3.5rem;
  width:  3.5rem;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  transition: all 0.3s ease 0s;
  -webkit-mask-image: unset;
  mask-image: unset;
  position: relative;

  i, svg {
    font-size: $font-size-base;
  }
}

.btn-bubble-m {
  height: 3rem;
  width:  3rem;
}

.btn-bubble-s {
  height: 2rem;
  width:  2rem;

  i, svg {
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(md) {
    height: 2.5rem;
    width:  2.5rem;

    i, svg {
      font-size: $font-size-base;
    }
  }
}

.btn-bubble-blue-small {
  height: 39px;
  width: 39px;
  min-width: 0 !important;
  background: $blue;
  color: $white;
  box-shadow: 0 0 0 0;

  &:hover {
    color: $white;
  }

  & > i {
    font-size: 13.6px;
    padding-top: 0.15rem;
    padding-left: 0.15rem;
  }

  &.icon-left > i {
    padding-left: 0;
    padding-right: 0.15rem;
  }

  &:disabled {
    opacity: 1;
    background: $gray;
  }

  &.is-gray {
    background: $gray-400;
  }
}

// Text Buttons

.btn-link-primary,
.btn-link--orange {
  --#{$prefix}btn-color: #{$orange};
  --#{$prefix}btn-hover-color: #{$orange-600};
  --#{$prefix}btn-active-color: #{$orange-700};
}

.btn-link-secondary,
.btn-link--blue {
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-hover-color: #{$blue-700};
  --#{$prefix}btn-active-color: #{$blue};
}

.btn-link-ghost,
.btn-link--white {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-hover-color: #{rgba($white,.9)};
  --#{$prefix}btn-active-color: #{rgba($white,.7)};
  --#{$prefix}btn-focus-ring-border-color: #{$white};
}

// Links

.is-link {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  color: $orange;
  text-transform: uppercase;

  &.has-icon {
    i {
      font-size: 20px;
      color: $orange;
    }
  }
}

.is-link.has-text-blue-light {
  color: $blue-light;

  &.has-icon {
    i {
      font-size: 20px;
      color: $blue-light;
    }
  }
}

.pill-btn {
  .tooltip-card {
    border-radius: 50px !important;
    cursor: pointer;
    padding: 5px 16px;
    font-size: 11px;
    background-color: transparent;
    border: 2px solid #fff;
    font-weight: 600;
    &:disabled,
    &.disabled {
      background-color: $gray;
      border-color: $gray;
      text-decoration: none !important;
      cursor: pointer;
      color: $white !important;
    }
    &:hover {
      text-decoration: underline;
    }
    &:active,
    &.active {
      background-color: #fff;
      color: $blue;
      text-decoration: none;
    }
    &.btn-primary-tertiary--blue {
      background-color: $blue;
      border-color: $blue;
      &:active,
      &.active {
        background-color: $blue-light;
        color: $white;
        border-color: $blue-light;
      }

      &:disabled,
      &.disabled {
        background-color: $gray;
        border-color: $gray;
        cursor: pointer;
      }
    }
    &::after {
      display: none;
    }
    .circle {
      background-color: #258875;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      margin: 0 4px 0 0;
    }

    &:disabled,
    &.disabled {
      background-color: $gray;
      border-color: $gray;
      cursor: pointer;
    }

    .toolip-right-icon {
      height: 10px;
      overflow: hidden;
    }
  }
  .dropdown-menu {
    border-radius: 5px;
    .address-menu {
      border-radius: 5px;
      .list-group-item {
        padding: 8px;
        color: $blue;
        text-decoration: none;
      }
    }
  }
  .menu-full-width {
    width: fit-content;
    font-size: 12px;
    top: 110%;
    .selected {
      &::before {
        display: none;
      }
    }
    &.end {
      left: 100%;
      transform: translateX(-100%);
    }
    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.chevron-nav {
  h2 {
    color: $blue;
    font-size: 20px;
    font-weight: bold;
  }
  .is-bubble {
    width: 40px;
    height: 40px;
  }
}

.btn-blank {
  --#{$prefix}btn-bg: unset;
  --#{$prefix}btn-border-color: unset;
  --#{$prefix}btn-hover-border-color: unset;
  --#{$prefix}btn-color: unset;
  --#{$prefix}btn-hover-bg: unset;
  --#{$prefix}btn-hover-color: unset;

  --#{$prefix}btn-active-color: unset;
  --#{$prefix}btn-active-bg: unset;
  --#{$prefix}btn-active-border-color: unset;

  --#{$prefix}btn-disabled-color: unset;
  --#{$prefix}btn-disabled-bg: unset;
  --#{$prefix}btn-disabled-border-color: unset;

  --#{$prefix}btn-focus-ring-border-color: unset;
  --#{$prefix}btn-focus-ring-offset: unset;
  cursor: auto;
}

.btn-clean {
  padding: 0;
  min-width: 1em;
  height: auto;
  border-radius: $border-radius;
  --#{$prefix}btn-bg: unset;
  --#{$prefix}btn-border-color: unset;
  --#{$prefix}btn-hover-border-color: unset;
  --#{$prefix}btn-color: unset;
  --#{$prefix}btn-hover-bg: unset;
  --#{$prefix}btn-hover-color: unset;

  --#{$prefix}btn-active-color: unset;
  --#{$prefix}btn-active-bg: unset;
  --#{$prefix}btn-active-border-color: unset;

  --#{$prefix}btn-disabled-color: unset;
  --#{$prefix}btn-disabled-bg: unset;
  --#{$prefix}btn-disabled-border-color: unset;

  --#{$prefix}btn-focus-ring-border-color: currentColor;
  --#{$prefix}btn-focus-ring-offset: 5px;
}

.btn-icon {
  --#{$prefix}btn-focus-ring-border-width: 2px;

  padding: 0;
  width: var(--#{$prefix}btn-height);
  min-width: 0;
  font-size: var(--#{$prefix}btn-icon-size);

  > svg {
    width: var(--#{$prefix}btn-icon-size);
  }
}

.btn-neutral {
  --#{$prefix}btn-color: #{$neutral-600};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;

  --#{$prefix}btn-hover-bg: #{rgba($black, 0.08)};

  --#{$prefix}btn-active-bg: #{rgba($black, 0.16)};
  --#{$prefix}btn-active-border-color: transparent;

  --#{$prefix}btn-disabled-bg: transparent;
}