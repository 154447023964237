@import '../variables';

.footer {
  width: 100%;
  background: $gray-400;
}

.footer-inner {
  padding: 25px 0 20px 0;
  text-align: center;
}

.footer-inner li {
  border: none;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
}

.footer-inner li a {
  color: $blue;
}

.footer-links {
  margin-top: 6px;
  text-align: center;
}

.footer-links > .list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.footer-inner .list-group-header {
  color: $blue;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
}

.footer > .footer-legal {
  border-top: 1px solid white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.footer > .footer-legal > p {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: center;
  color: #07112a;
  padding: 0 20px 0 20px;
  margin-bottom: 0;
}

.footer-icon {
  font-size: 30px;
  margin-right: 10px;
  color: $blue;
}

.footer-social-box {
  margin-top: 14px;
}

.footer-social-box li a:hover {
  &:after {
    display: none;
  }
}

.mac-footer-height {
  height: 5rem;
}

// Responsive breakpoints / Media queries

@media (min-width: 360px) {
  .footer-links > .list-inline-item:not(:last-child) {
    margin-right: 10px;
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .footer-links > .list-inline-item:not(:last-child) {
    margin-right: 30px;
  }

  .footer-social-box {
    padding-top: 5px;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  .footer-icon {
    margin-right: 5px;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .footer-links {
    text-align: left;
  }

  .footer-inner {
    height: 134px !important;
    text-align: right;
    padding-top: 50px;
  }

  .footer-social-box {
    margin-top: 0;
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
