// All styles are prefixed with patternlib-*
.patternlib {
  &-h1 {
    font-size: 28px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
  }

  &-h2 {
    font-size: 22px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
  }

  &-col {
    padding: 60px 0 60px 0;
    text-align: center;
  }
}

.patternlib-col {
  border-radius: 5px;
}
