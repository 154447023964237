$step-navigation-step-width: 1rem;

.step-navigation-wrapper {
  padding-top: 2rem;

  &.step-navigation-zigzag {
    padding-bottom: 2rem;
  }
}

.step-navigation {
  --step-navigation-steps-count: 4;
  --step-navigation-steps-complete: 1;

  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  counter-reset: step-count;

  &:before {
    content: '';
    height: 2px;
    left: $step-navigation-step-width*.5;
    right: $step-navigation-step-width*.5;
    position: absolute;
    background: $gray;
    bottom: $step-navigation-step-width*.5;
    margin-bottom: -1px;
    z-index: -1;
  }

  &:after {
    content: '';
    height: 2px;
    left: $step-navigation-step-width*.5;
    width: calc( ((100% - $step-navigation-step-width)/(var(--step-navigation-steps-count) - 1) ) * var(--step-navigation-steps-complete));
    position: absolute;
    background: $green;
    bottom: $step-navigation-step-width*.5;
    margin-bottom: -1px;
    z-index: -1;

    transition: width .4s ease-out;
  }
}
.step-navigation-step {
  --step-navigation-step-color: #{$gray};
  --step-navigation-step-bg-color: #{$white};
  --step-navigation-step-border-color: #{$gray};
  --step-navigation-step-label-color: #{$gray};

  color: var(--step-navigation-step-color);
  counter-increment: step-count;
  position: relative;
}
.step-navigation-step-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $step-navigation-step-width;
  height: $step-navigation-step-width;
  line-height: 1;
  font-size: .6875rem;
  border-radius: 50%;
  background: var(--step-navigation-step-bg-color);
  border: 1px solid var(--step-navigation-step-border-color);

  &:before {
    content: counter(step-count);
  }

  .step-navigation-step-complete & {
    &:before {
      content: "\f00c";
      font-family: "Font Awesome 6 Pro";
      font-size: .75em;
    }
  }
}
.step-navigation-step-label {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translate3d(-50%,0,0);
  line-height: 1rem;
  font-size: .6875rem;
  color: var(--step-navigation-step-label-color);
}

.step-navigation-step-complete {
  --step-navigation-step-color: #{$white};
  --step-navigation-step-bg-color: #{$green};
  --step-navigation-step-border-color: #{$green};
  --step-navigation-step-label-color: #{$blue};
}
.step-navigation-step-active {
  --step-navigation-step-color: #{$white};
  --step-navigation-step-bg-color: #{$blue};
  --step-navigation-step-border-color: #{$blue};
  --step-navigation-step-label-color: #{$blue};

  .step-navigation-step-label {
    &:before {
      content: '\f107';
      font-family: "Font Awesome 6 Pro";
      position: absolute;
      top: -0.75rem;
      left: 50%;
      transform: translate3d(-50%,0,0);
      line-height: 1;
      font-size: .8rem;
      color: var(--step-navigation-step-label-color);
    }
  }
}

.step-navigation-zigzag {

  .step-navigation-step:nth-child(2n) {
    .step-navigation-step-label {
      top: 1.25rem;
      bottom: auto;
    }
    &.step-navigation-step-active {
      .step-navigation-step-label {

        &:before {
          content: '\f106';
          top: auto;
          bottom: -.75rem;
        }
      }
    }
  }
}

.step-navigation-flush-labels {

  .step-navigation-step {
    &:first-child {
      .step-navigation-step-label {
        left: 0;
        transform: translate3d(0,0,0);
      }
    }
    &:last-child {
      .step-navigation-step-label {
        right: 0;
        left: auto;
        transform: translate3d(0,0,0);
      }
    }
  }
  .step-navigation-step-active {

    &:first-child {
      .step-navigation-step-label:before {
        left: $step-navigation-step-width * .5;
      }
    }
    &:last-child {
      .step-navigation-step-label:before {
        right: $step-navigation-step-width * .5;
        left: auto;
        transform: translate3d(50%, 0, 0);
      }
    }
  }
}

.step-navigation-no-labels {
  padding-top: 1rem;
  .step-navigation-step-label {
    height: 0;
    color: transparent;
    pointer-events: none;
  }
}

.step-navigation-one-label {
  .step-navigation-step:not(.step-navigation-step-active) {
    .step-navigation-step-label {
      height: 0;
      color: transparent;
      pointer-events: none;
    }
  }
}