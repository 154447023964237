
.card-sliding {
  width: 210px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.875rem;

  .card-sliding-inner {
    min-height: 150px;
    padding: 30px 30px 20px 20px;

  }

  .btn {
    top: 100%;
    left: 70%;
    position: absolute;
    z-index: 1;
    margin-top: -1.75rem;
    margin-left: -1.75rem;

    &:focus-visible,
    &.focus {
      &:before {
        content: none;
      }
    }
  }

  @supports selector(:has(*)) {

    &:has(.card-focus-effect-trigger:focus){
      @include card-focus-effect;
    }
  }
  @supports not selector(:has(*)) {

    &.card-has-focus {
      @include card-focus-effect;
    }
  }
}

.card-sliding-inner {
  flex-grow: 1;
  color: white;
  width: 100%;
  min-height: 125px;
  padding: 60px 50px 20px 50px;
  border-radius: $border-radius;
  background: $blue;
  mask-image: radial-gradient(
    70% 100%,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    black 33px
  );
  -webkit-mask-image: -webkit-radial-gradient(
    70% 100%,
    circle closest-corner,
    transparent 0,
    transparent 32px,
    black 33px
  );
}

.card-sliding-inner.featured {
  background-image:
          url($we-assets-images-path + '/card-featured-sprite.svg'),
          linear-gradient(133.03deg, #F7AF40 9.52%, #EB6626 44.79%, #E13B31 94.15%);
  background-size: 99px 90px, auto;
  background-position: right -5% bottom -22.65%, left top;
  background-repeat: no-repeat, no-repeat;
}