@import '../variables';

.fullscreen-process .fullscreen-process-header {
  height: 60px;
  min-height: 60px;
  background: $orange;
  width: 100%;

  h6 {
    padding-top: 20px;
    padding-left: 1.5rem;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
  }

  i {
    position: relative;
    float: right;
    margin-top: -23px;
    margin-right: 1.5rem;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

.fullscreen-process {
  width: 100%;

  .process-content {
    padding-top: 40px;
    padding-bottom: 24px;

    &.container.is-medium {
      max-width: 720px;
    }

    & > h3 {
      font-size: 26px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.08;
      color: #000460;
      margin-bottom: 30px;
    }

    .form-footer {
      padding-bottom: 15px;
    }
  }

  .swiper-button-disabled {
    background-color: grey;
  }

  .we-slide {
    width: 314px;
    margin-bottom: 55px;
  }

  .card-btn-area {
    height: 80px;
  }

  .single-sliding-card-container {
    padding-bottom: 55px;
  }

  .process-content-top {
    margin-top: 3rem;

    &.container.is-medium {
      max-width: 720px;
    }
  }

  .process-content-bottom {
    margin-bottom: 4rem;

    &.container.is-medium {
      max-width: 720px;
    }

    .process-footer {
      padding-bottom: 15px;
    }
  }

  .process-content-middle {
    &.container.is-medium {
      max-width: 720px;
    }
  }
}

.process-footer {
  .buttons {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 8px;
    padding: 16px 0 16px 0;

    .primary {
      order: 1;
    }

    .back {
      order: 2;
    }

    .break {
      flex-basis: 100%;
      height: 0;
      order: 3;
    }

    .tertiary {
      order: 4;
    }
  }

  .buttons-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px 0 16px 0;
  }
}

.process-form-card {
  padding: 32px 250px 16px 32px;
  margin-top: 40px;

  &.bg-small {
    background-image: url('../../../assets/img/formular-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }

  &.bg-large {
    background-image: url('../../../assets/img/formular-bg-large.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }

  h1 {
    font-size: 20px;
  }
  .form-content {
    margin-top: 16px;
    select {
      color: $gray-light;
      -webkit-appearance: none;
      background: url(../../../assets/icons/select-down-arrow.svg) no-repeat
        center right !important;
    }
  }

  input {
    padding-left: 0;
  }
}

.form-chip {
  label {
    margin-bottom: 6px !important;
  }
  p {
    font-size: 14px;
    color: $gray-light;
    font-weight: 400;
  }
  .chip-text {
    margin-top: -5px;
  }
  .chip {
    padding: 5px 12px;
    background-color: $gray-300;
    border-radius: 200px;
    margin-left: 2px;
    margin-bottom: 21px;
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

@media (max-width: 991px) {
  .process-form-card {
    padding: 24px 24px 8px 24px;
    margin-top: 24px;

    &.bg-small {
      background-image: none;
    }

    &.bg-large {
      background-image: none;
    }

    h1 {
      font-size: 18px;
    }
  }
}

@media (max-width: 575px) {
  .process-footer {
    .buttons {
      .btn,
      .btn-container {
        display: block;
        width: 100%;
      }

      .primary {
        order: 1;
      }

      .back {
        order: 3;
      }

      .break {
        flex-basis: 0;
        height: 0;
        order: 4;
      }

      .tertiary {
        order: 2;
      }
    }

    .buttons-row {
      .btn,
      .btn-container {
        display: block;
        width: 100%;
      }
    }
  }
}
