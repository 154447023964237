
.mwe-mm-animation {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;

  > * {
    grid-row: 1;
    grid-column: 1;
    will-change: transform;
    transition:
            transform 0.4s ease-in-out,
            opacity 0.4s ease-in-out;
  }
}

.mwe-mm-animation-front {
}

.mwe-mm-animation-back {
  pointer-events: none;
  transform: scale(0.95);
  opacity: 0;
}

.mwe-mm-animation-active {
  .mwe-mm-animation-front {
    pointer-events: none;
    transform: scale(1.05);
    opacity: 0;
  }

  .mwe-mm-animation-back {
    pointer-events: all;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

$pulse-animation-duration: 2s;

.pulse {
  animation: pulse $pulse-animation-duration infinite ease-in-out;
}

.heartbeat {
  position: relative;
  animation: pulse $pulse-animation-duration infinite ease-in-out $pulse-animation-duration * 0.33;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
    animation: pulse $pulse-animation-duration infinite ease-in-out;
    margin: -2px;
  }
}