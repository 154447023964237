@import '../variables';

.f-14 {
  font-size: 14px;
}

.underline {
  text-decoration: underline !important;
}
.pl-12 {
  padding-left: 12px;
}
.pl-30 {
  padding-left: 12px;
}
.position_absolute {
  position: absolute;
  margin-top: 4px;
}
.table-striped tbody tr:nth-of-type(even) {
  background: $white !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background: $gray-300 !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.custom_row {
  background: $gray-300 !important;
}
.invoice-part-table {
  width: 100%;
  thead {
    tr {
      th {
        font-weight: 600;
        color: $blue;
        padding: 12px 12px;
        font-size: 14px;
      }
    }
  }
  tbody {
    tr {
      background-color: $white;
      &:nth-child(odd) {
        background-color: $gray-300;
      }
      td {
        color: #505558 !important;
        border-top: none !important;
        padding: 6px 12px;
        font-size: 14px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        margin-left: 2px;
      }
      .btn-link {
        font-size: 14px;
      }
    }
  }
}

/* Custom table */

.mini-icon {
  width: 16px !important;
  height: 16px !important;
}
.href {
  margin-bottom: 0;
  padding: 0 30px;
  color: $orange !important;
  font-weight: 600;
}
.custom_height {
  height: 60px;
}

@media (max-width: 333px) {
  .invoice-part-table {
    thead {
      tr {
        th {
          padding: 12px 4px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 6px 4px;
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .w-25 {
    width: 25% !important;
  }
  .custom_height {
    height: auto;
  }
  .position_absolute {
    position: relative;
    margin-top: 3px;
  }

  .pl-30 {
    padding-left: 30px;
  }

  .f-16 {
    font-size: 18px !important;
  }

  .icon-25 {
    img {
      width: 32px !important;
      height: 32px !important;
    }
  }
  .invoice-part-table {
    width: 100%;
    thead {
      tr {
        th {
          font-weight: 600;
          color: $blue;
          padding: 6px 30px;
          font-size: 18px;
        }
      }
    }
    tbody {
      tr {
        background-color: $white;
        &:nth-child(odd) {
          background-color: $gray-300;
        }
        td {
          color: #505558 !important;
          padding: 10px 30px !important;
          font-size: 18px !important;

          img {
            width: 20px;
            height: 20px;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 18px;
        }
        .btn-link {
          font-size: 18px;
        }
      }
    }
  }

  .mini-icon {
    width: 20px !important;
    height: 20px !important;
  }
}
