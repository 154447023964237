@import '../variables';

.mobile_address > .tooltip-card {
  padding: 12px 5px 12px 30px;
  border-radius: 0;
}

.text-green {
  color: $green !important;
  font-weight: 600 !important;
}

.text-blue {
  color: $blue !important;
  font-weight: 600 !important;
}
.f-600 {
  font-weight: 600 !important;
}
.f-400 {
  font-weight: 400 !important;
}
.text-orange {
  color: $orange;
}

.text-pink {
  color: $red;
}

.modal-title {
  margin-bottom: 0;
  font-size: 14px;
  color: $white;
}

.close {
  color: $white;
  opacity: 1;
  font-size: 30px;
  font-weight: 400;
}

.product-card .success-card {
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    85% 100%,
    circle closest-corner,
    #00000021 0,
    #00000014 31px !important,
    #fff 32px
  );
  background-image: -ms-radial-gradient(
    85% 100%,
    circle closest-corner,
    transparent 0,
    #00000014 31px !important,
    #fff 32px
  );
}

.card-padding {
  padding: 12px 24px 12px 24px !important;
}

.font-24 {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.font-28 {
  font-size: 28px !important;
}

.font-20 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: $gray-600;
}
.font-18 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.font-16 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.font-14 {
  font-size: 14px !important;
  color: $gray-600 !important;
  font-weight: normal !important;
}

.height-36 {
  height: 36px;
}
.pt-20 {
  padding-top: 20px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mt_custom {
  margin-top: 0.8rem;
}
.download-btn {
  display: block;
  width: max-content;
  margin-left: auto;
}

.circle-blue-light {
  background-color: $orange;
  padding: 3px;
  width: 1em !important;
  border-radius: 50%;
  margin-left: 1px;
}

#expand-btn:hover {
  cursor: pointer;
}

.big-card-padding {
  padding: 28px 24px 28px 24px !important;
}
