@import '../variables';

.offer {
  padding-bottom: 34px;
  &.form-header {
    padding: 15px 23px 18px 23px;
  }
  .offer-card {
    padding: 24px 24px 24px 22px;

    .card-title {
      color: $gray-light;
      opacity: 0.5;
    }
    .card-middle {
      background-image: $gradient-green;
      width: 32px;
      height: 32px;
      padding: 4px 0 0 10px;
      border-radius: 50%;
      i {
        font-size: 14px;
      }
    }
    .card-detail {
      .gray-light {
        color: $gray-light;
        font-weight: normal;
      }
      .img {
        img {
          width: 62px;
          height: 60px;
        }
      }
    }
    .text--small {
      font-style: italic;
    }
  }
  .gray-light {
    color: $gray-light !important;
  }
  .offer-header {
    padding: 0 16px 26px 16px;
  }
  .table {
    &.offer-card {
      padding: 12px 15px 15px 14px;
    }
  }
  .table {
    thead {
      tr {
        height: 40px;
      }
    }
    tr {
      display: flex;
      justify-content: space-between;
      height: 27px;
      align-items: center;
      &.border-top {
        border-top: 1px solid black !important;
        padding-top: 30px;
        padding-bottom: 15px;
        margin-top: 5px;
      }
      th {
        border: none;
      }

      .amount {
        font-weight: 600;
      }
    }
  }
}

.product-card {
  .action-button {
    display: block;
    width: 100%;
  }
  .open-sum-text {
    font-size: 14px;
    font-weight: 600;
  }
  .open-sum {
    font-size: 24px;
    font-weight: 700;
  }
  .open-item-text {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width: 991px) {
  .offer {
    .table {
      tr {
        height: 31px;
      }
    }
  }
}
@media (min-width: 768px) {
  .product-card {
    .open-sum-text {
      font-size: 16px;
    }
    .open-sum {
      font-size: 32px;
    }
    .open-item-text {
      font-size: 16px;
    }
  }

  .one-column {
    .border-top-md {
      padding-top: 0.5rem;
      border-top: $border-width solid $border-color;
    }
    .border-bottom-md {
      padding-bottom: 0.5rem;
      border-bottom: $border-width solid $border-color;
    }
    .margin-bottom-4-md {
      margin-bottom: 0;
    }
    .pb-4-md {
      padding-bottom: 0;
    }
    .hide-md {
      display: none;
    }
    .hide-sm {
      display: block;
    }
    .pl-column-right {
      padding-left: 1.5rem;
    }
    .pb-last-item {
      padding-bottom: 0.5rem;
    }
  }

  .two-columns {
    .margin-bottom-4-md {
      margin-bottom: 1.5rem;
    }
    .pb-4-md {
      padding-bottom: 1.5rem !important;
    }
    .hide-md {
      display: block;
    }
    .hide-sm {
      display: none;
    }
    .pl-column-right {
      padding-left: 0;
    }
    .pb-last-item {
      padding-bottom: 1rem;
    }
  }
}
@media (max-width: 767px) {
  .offer {
    padding-bottom: 0;
    .offer-card {
      width: 100%;
      margin-bottom: 34px !important;
    }
  }

  .product-card {
    .border-top-md {
      padding-top: 0.5rem;
      border-top: $border-width solid $border-color;
    }
    .border-bottom-md {
      padding-bottom: 0.5rem;
      border-bottom: $border-width solid $border-color;
    }
    .margin-bottom-4-md {
      margin-bottom: 0;
    }
    .pb-4-md {
      padding-bottom: 0;
    }
    .pl-column-right {
      padding-left: 1.5rem;
    }
    .pb-last-item {
      padding-bottom: 0.5rem;
    }
  }

  .one-column {
    .hide-md {
      display: none;
    }
  }

  .two-columns {
    .hide-md {
      display: none;
    }

    .hide-sm {
      display: block;
    }
  }
}
