
.list-separated {
    list-style: none outside none;
    padding: 0;
    margin: 0;

    li {
        padding: var(--#{$prefix}abstract-spacing, .75rem) 0;

        + li {
            border-top: 1px solid $gray-400;
        }

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }
}

.list-ul {
    list-style: none outside none;
    padding: 0;
    margin: 0;

    li {
        padding-left: 1.5rem;
        margin-bottom: $paragraph-margin-bottom;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-color: $dark;
            width: .25em;
            height: .25em;
            margin-left: -1.5em;
            margin-top: .5em;
            border-radius: 50%;
        }
    }
}

.list-ol {
    padding: 0;
    counter-reset: list-item;
    list-style: none outside none;

    > li {
        counter-increment: list-item;
        display: flex;
        gap: .5rem;

        &:before {
            content: counter(list-item)'.';
            flex: 0;
            color: $blue-light;
            font-weight: 700;
        }
    }
}
