@import '../variables';

.text-black {
  color: $gray-600;
}
.w-40px {
  width: 40px;
}
.f-weight-500 {
  font-weight: 500;
}
.f-weight-400 {
  font-weight: 400;
}
.bell-width {
  width: 24px !important;
}
.f-weight-600 {
  font-weight: 600;
}
.f-20 {
  font-size: 20px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-14 {
  font-size: 14px !important;
}
.light-gray {
  color: #505558 !important;
}
.mb-15 {
  margin-bottom: 15px;
}
.sum-tax {
  margin-left: 1rem;
}
.sum-margin {
  margin-left: 2rem;
}
.h-40px {
  height: 40px;
}
.single-product-card-container {
  .product-card-sliding {
    padding-top: 0;
    width: 100%;
    img {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }
}

.progress {
  background-color: #eeeff1;
  border-radius: 15px;
  height: 9px;
  .progress-bar {
    border-radius: 15px;
  }
}

.water-bar {
  background-image: linear-gradient(269deg, #3ea9ca, #06649b, #0f255b);
}

.gas-bar {
  background-image: linear-gradient(to left, #06649b, #0f255b) !important;
}
.wind-bar {
  background: linear-gradient(
    266deg,
    #73deca,
    #28a48c,
    #258875,
    #0f6e5c
  ) !important;
}
.biomass-bar {
  background: linear-gradient(
    262deg,
    #ff7ba7,
    #eb296a,
    #cd0045,
    #9f0a3c
  ) !important;
}
.sun-bar {
  background: linear-gradient(262deg, #ffd88c, #e5a62d, #e29502) !important;
}
.other-bar {
  background: #ffd88c !important;
  background: linear-gradient(259deg, #eeeff1, #dedfe3, #b3b3b3) !important;
}

.padding-card-custom {
  padding: 29px 34px 32px 32px !important;
}
.card-views {
  display: flex;
  align-items: center;
  max-width: 100% !important;
  justify-content: space-between;
  .heading-left {
    border-bottom: none !important;
    flex-direction: row-reverse;
    .w-40px {
      width: 50px;
      margin-right: 15px;
    }
    .h-40px {
      height: 50px;
    }
  }
  .heading-right {
    h3 {
      margin-right: 15px;
    }
  }
}

.desk-padding {
  padding: 20px 15px 12px;
}
.big-card-head-padding {
  padding: 10px 5px 8px;
}
.big-card-body-padding {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 991px) {
  .padding-card-custom {
    padding: 22px 24px 27px !important;
  }

  .card-views {
    display: block;
    .heading-left {
      border-bottom: 1px solid #dee2e6 !important;
      flex-direction: unset;
      .w-40px {
        width: 40px !important;
        margin-right: 0;
      }
      .h-40px {
        height: 40px !important;
      }
    }
    .heading-right {
      h3 {
        margin-right: 15px;
      }
    }
  }
  .desk-padding {
    padding: 0 0 0;
  }
  .big-card-head-padding {
    padding: 0 0 0;
  }
  .big-card-body-padding {
    padding-left: 0;
    padding-right: 0;
  }
}
