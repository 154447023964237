@import '../variables';

.nav-item {
  &:not(:first-child) {
    margin: 0 0 0 20px;
  }
  .nav-link {
    appearance: none;
    background: none;
    border: 0 none;
    padding: 0;
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;

    & > i {
      padding-top: 5px;
      font-size: 21px;
      padding-right: 5px;
      position: relative;
      top: 2px;
    }

    &:hover {
      color: white;
    }

    &:hover > span,
    &.active > span {
      position: relative;
      &::before {
        display: block;
        text-decoration: none;
        content: '';
        width: 30px;
        position: absolute;
        bottom: -5px;
        border-width: 0 0 2px;
        border-style: solid;
        left: 50%;
        transform: translate(-50%,0);

        transition: width .2s ease-out;
      }
    }

    &:focus > span,
    &:focus-visible > span {
      position: relative;
      &::before {
        display: block;
        text-decoration: none;
        content: '';
        width: 100%;
        position: absolute;
        bottom: -5px;
        border-width: 0 0 2px;
        border-style: solid;
        left: 50%;
        transform: translate(-50%,0);
      }
    }

    &.logout-link {
      margin-top: -3px;
      i {
        padding: 8px;
        border: 2px solid;
        font-size: 16px;
        @include transition($btn-transition);
      }
      &:hover,
      &:focus,
      &:focus-visible {
        i {
          border-color: $white;
          background-color: $white;
          color: $blue;
        }
        &::before {
          display: none !important;
        }
      }
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &:not(:first-child) {
      margin: 0 0 0 12px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &:not(:first-child) {
      margin: 0 0 0 40px;
    }
  }
}

a.logo-link:hover {
  text-decoration: none;
  &::before {
    display: none;
  }
}

.nav-item-profile {

  .dropdown-menu {
    --#{$prefix}dropdown-min-width: #{14.25rem};
  }

  .dropdown-toggle:after {
    content: "\f078";
    font-family: 'Font Awesome 6 Pro';
    border: 0 none;
    vertical-align: .5em;
    margin-left: .5rem;
    font-size: .5rem;

    transition: transform .15s;
  }

  &.show {
    .nav-link {
      background-color: rgba($gray-700,.25);
    }

    .dropdown-toggle:after {
      transform: rotateX(180deg);
    }
  }
}
