@import '../variables';

.profile-card {
  padding-top: 92px;
  .card {
    padding: 32px;
    margin-bottom: 40px;
    .card-body {
      display: flex;
      justify-content: space-between;
      padding: 0;
      .card-image {
        margin-right: 24px;
        margin-top: 3px;
        width: 36px;
        i {
          font-size: 32px;
          color: $blue;
        }
      }
      .card-detail {
        flex-basis: 100%;
        .card-desc {
          color: $gray-light;
        }
      }
      i {
        display: flex;
        align-items: center;
      }
    }
    &.height {
      height: 180px;
      i {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (max-width: 767px) {
  .profile-card {
    padding: 25px 23px 3px 23px;
    .card {
      padding: 24px;
      margin-bottom: 0;
      margin-top: 24px;
      .card-body {
        .card-image {
          margin-right: 16px;
        }
        h5 {
          margin-top: 4px;
        }
      }
    }
  }
  .profile-card-footer {
    padding-bottom: 80px;
  }
}
