@import '../variables';

.steps {
  &.is-vertical {
    .item {
      position: relative;
      display: flex;
      max-width: 1000px;
      margin: 0 auto;

      .image {
        .bubble {
          z-index: 2;
          height: 48px;
          width: 48px;
          background-color: white;
          border-radius: 999px;
          font-size: 20px;
          position: relative;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        > div {
          position: relative;
          text-align: center;
          font-size: 0.8em;
        }

        img {
          border-radius: 50%;
          height: 5em;
          border: 0.35em solid #5ed3bf;
        }
        span {
          display: block;
          clear: both;
          padding: 0.25em 0;
          margin: 0.5em 0;
          background: #3b4262;
        }
      }

      .details {
        position: relative;
        flex-grow: 1;

        > div {
          border-radius: 0.5em;
          margin: 0.5em 0 2em 1.5em;

          h3 {
            color: $blue;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
          }

          p {
            margin: 0;
            padding: 0;
            line-height: 150%;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            color: #07112a;
          }
        }
      }

      &:not(:last-child),
      &.has-vertical-line {
        &::before {
          content: '';
          width: 0;
          height: 100%;
          border-left: 2px solid $green;
          position: absolute;
          top: 30px;
          left: 1.5em;
          z-index: 1;
        }

        &.has-gray-line {
          &::before {
            border-left: 2px dashed $gray-500;
          }
        }

        &.has-orange-line {
          &::before {
            border-left: 2px dashed $orange;
          }
        }
      }
    }
  }
}