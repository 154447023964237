@import '../variables';

.btn-dropdown {
  --#{$prefix}btn-padding-x: 1rem;
  --#{$prefix}btn-padding-y: .5rem;

  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: #{$white};
  --#{$prefix}btn-box-shadow: none;

  --#{$prefix}btn-hover-bg: #{$white};
  --#{$prefix}btn-hover-color: #{$blue};
  --#{$prefix}btn-hover-border-color: #{$white};

  --#{$prefix}btn-active-color: #{$blue};
  --#{$prefix}btn-active-bg: #{$white};
  --#{$prefix}btn-active-border-color: #{$white};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-focus-color: #{$blue};
  --#{$prefix}btn-focus-bg: #{$white};
  --#{$prefix}btn-focus-ring-border-color: #{$white};

  --#{$prefix}loading-spinner-color: #{$gray};

  &:hover {
    --#{$prefix}loading-spinner-color: #{$white};
  }
  height: 2rem;
  background: transparent;
  border-width: 2px;
  font-size: 10px;
  min-width: 0;
}

.tooltip-card > * .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle.dropdown-toggle-hide-arrow::after {
  display: none !important;
}

.dropdown-menu {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-radius: $border-radius;
  padding: 0;

  .dropdown-item {
    //font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    color: $secondary;
    border-radius: 0;
    transition: background-color .2s ease-in-out;

    &:hover,
    &.active,
    &.dropdown-item-selected {
      background-color: $list-hover;
      &::before {
        border-color: transparent transparent $list-hover transparent;
      }
    }

    &[role='option'] {
      cursor: pointer;
    }

    &:first-child {
      border-radius: $border-radius $border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius $border-radius;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-400;
    }
  }
}

.btn-dropdown-secondary-light {
  text-transform: none;
  letter-spacing: initial;
  --#{$prefix}btn-color: #{$blue-light};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-border-color: #{$blue-light};
  --#{$prefix}btn-border-width: 2px;
  --#{$prefix}btn-font-size: 1rem;
  --#{$prefix}btn-line-height: 0.875;

  --#{$prefix}btn-hover-bg: #{$blue-light};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$blue-light};

  --#{$prefix}btn-active-color: #{$blue-light};
  --#{$prefix}btn-active-bg: #{$white};
  --#{$prefix}btn-active-border-color: #{$blue-light};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-focus-ring-border-color: #{$blue-light};
  --#{$prefix}btn-focus-ring-offset: -4px;
}

.btn-dropdown-success {
  text-transform: none;
  letter-spacing: initial;
  --#{$prefix}btn-color: #{$success};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-border-color: #{$success};
  --#{$prefix}btn-border-width: 2px;
  --#{$prefix}btn-font-size: 1rem;
  --#{$prefix}btn-line-height: 0.875;

  --#{$prefix}btn-hover-bg: #{$success};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$success};

  --#{$prefix}btn-active-color: #{$success};
  --#{$prefix}btn-active-bg: #{$white};
  --#{$prefix}btn-active-border-color: #{$success};
  --#{$prefix}btn-active-shadow: none;

  --#{$prefix}btn-focus-ring-border-color: #{$success};
  --#{$prefix}btn-focus-ring-offset: -4px;
}

.dropdown-menu-tariff {
  --#{$prefix}dropdown-item-padding-y: .375rem;

  .dropdown-item {
    position: relative;
    font-size: 1rem;
    color: $body-color;
    padding-left: calc(var(--#{$prefix}dropdown-item-padding-x) - 2px);
    border-left: 2px solid transparent;

    &:not(:last-child) {
      border-bottom: 0 none;

      &:before {
        content: '';
        display: block;
        position: absolute;
        inset: auto var(--#{$prefix}dropdown-item-padding-x) 0;
        border-bottom: 1px solid $gray-400;
      }
    }

    &:first-child {
      border-radius: 2px 2px 0 0;
    }
    &:last-child {
      border-radius: 0 0 2px 2px;
    }

    &:hover {
      background-color: $white;
      text-decoration: underline;
      color: var(--#{$prefix}dropdown-item-hover-color);
    }

    &:focus {
      text-decoration: underline;
      color: var(--#{$prefix}dropdown-item-hover-color);
      border-left-color: var(--#{$prefix}dropdown-item-hover-color);
    }

    &.active {
      background-color: $white;
      color: var(--#{$prefix}dropdown-item-hover-color);
      font-weight: 700;

    }
  }
}

.dropdown-menu-secondary-light {
  --#{$prefix}dropdown-item-hover-color: #{$blue-light};
}

.dropdown-menu-success {
  --#{$prefix}dropdown-item-hover-color: #{$success};
}
