

.fa-analytics::before {
  content: "\f643"; }

.fa-balance-scale::before {
  content: "\f24e"; }

.fa-birthday-cake::before {
  content: "\f1fd"; }

.fa-bolt::before {
  content: "\e0b7"; }

.fa-box-full::before {
  content: "\f49c"; }

.fa-boxes::before {
  content: "\f468"; }

.fa-broadcast-tower::before {
  content: "\f519"; }

.fa-cctv::before {
  content: "\f8ac"; }

.fa-calculator-alt::before {
  content: "\f64c"; }

.fa-calender-alt::before {
  content: "\f073"; }

.fa-cocktail::before {
  content: "\f561"; }

.fa-cogs::before {
  content: "\f085"; }

.fa-cloud-download-alt::before {
  content: "\f0ed"; }

.fa-cloud-upload-alt::before {
  content: "\f0ee"; }

.fa-edit::before {
  content: "\f044"; }

.fa-exclamation-circle::before {
  content: "\f06a"; }

.fa-exclamation-triangle::before {
  content: "\f071"; }

.fa-file-alt::before {
  content: "\f15c"; }

.fa-glass-cheers::before {
  content: "\f79f"; }

.fa-globe-europe::before {
  content: "\f7a2"; }

.fa-hard-hat::before {
  content: "\f807"; }

.fa-hands-heart::before {
  content: "\f4c3"; }

.fa-hand-holding-water::before {
  content: "\f4c1"; }

.fa-heartbeat::before {
  content: "\f21e"; }

.fa-home::before {
  content: "\f015"; }

.fa-home-lg-alt::before {
  content: "\f015"; }

.fa-info-circle::before {
  content: "\f05a"; }

.fa-list-alt::before {
  content: "\f022"; }

.fa-magic::before {
  content: "\e2ca"; }

.fa-map-marked::before {
  content: "\f59f"; }

.fa-map-signs::before {
  content: "\f277"; }

.fa-minus-circle::before {
  content: "\f056"; }

.fa-mobile-alt::before {
  content: "\f3cd"; }

.fa-people-carry::before {
  content: "\f4ce"; }

.fa-play-circle::before {
  content: "\f144"; }

.fa-plus-circle::before {
  content: "\f055"; }

.fa-question-circle::before {
  content: "\f059"; }

.fa-save::before {
  content: "\f0c7"; }

.fa-search::before {
  content: "\f002"; }

.fa-shield-alt::before {
  content: "\f3ed"; }

.fa-shipping-fast::before {
  content: "\f48b"; }

.fa-shopping-basket::before {
  content: "\f291"; }

.fa-shopping-cart::before {
  content: "\f07a"; }

.fa-sliders-h::before {
  content: "\f1de"; }

.fa-sliders-v::before {
  content: "\f3f1"; }

.fa-sign-in::before {
  content: "\f2f6"; }

.fa-sign-out::before {
  content: "\f2f5"; }

.fa-sync::before {
  content: "\f2f1"; }

.fa-tachometer::before {
  content: "\f62c"; }

.fa-temperature-frigid::before {
  content: "\f768"; }

.fa-temperature-hot::before {
  content: "\f76a"; }

.fa-th::before {
  content: "\e195"; }

.fa-thunderstorm::before {
  content: "\f76c"; }

.fa-tint::before {
  content: "\f043"; }

.fa-times::before {
  content: "\f00d"; }

.fa-times-circle::before {
  content: "\f057"; }

.fa-tools::before {
  content: "\f7d9"; }

.fa-trash-alt::before {
  content: "\f2ed"; }

.fa-tv-alt::before {
  content: "\f26c"; }

.fa-user-friends::before {
  content: "\f500"; }

.fa-user-hard-hat::before {
  content: "\f82c"; }