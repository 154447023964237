@import '../variables';

// General

.form-box {
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 24px;
}

.form-label {
  line-height: 1.375rem;
  //color: #505558;
}
.custom-control {
  padding-left: 2rem !important;
}
.custom-control .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background: white;
}

.custom-control
  .custom-control-input.input-error
  ~ .custom-control-label::before {
  background-color: $light-red;
  border-color: $red;
}
.custom-control
  .custom-control-input.input-error:checked
  ~ .custom-control-label::before {
  border-color: $blue-light;
}

.form-text {
  font-size: 11px !important;
  color: #505558;
  margin-top: 0px;
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  top: 1px !important;
  left: -1.75rem !important;
  width: 20px;
  height: 20px;
}

.custom-control-lg .custom-control-label {
  font-size: 1rem !important;
}

.input-group-text {
  height: 24px;
}

.input-group-text-button {
  border: none;
  padding: 0;
  background-color: inherit;

  button {
    margin-left: 1em;
    margin-bottom: 0;
  }
}

input[type='password'] {
  font-family: sans-serif;
  font-size: 18px;
}

// Browser specific password input styles

@supports (-ms-ime-align: auto) {
  input[type='password'] {
    font-size: 18px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='password'] {
    font-size: 18px;
  }
}

@-moz-document url-prefix() {
  input[type='password'] {
    font-size: 18px !important;
  }
}

// Safari only
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input[type='password'] {
      font-size: 12px !important;
    }
  }
}

// Checkboxes

//.custom-checkbox .custom-control-label::before {
//  background: white;
//  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.12) !important;
//  border: solid 1px $gray;
//}
//
//.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
//  background-color: $blue-light;
//  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.25) !important;
//}
//.custom-checkbox
//  .custom-control-input:disabled:checked
//  ~ .custom-control-label::before {
//  background-color: $gray !important;
//  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.12) !important;
//  border: 0;
//}
//.custom-checkbox
//  .custom-control-input:disabled
//  ~ .custom-control-label::before {
//  background-color: $gray-400 !important;
//  border-color: $gray;
//}



// Radios

.custom-radio .custom-control-label::before {
  background: white;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.12) !important;
  border: solid 1px $gray;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: white;
  box-shadow: none !important;
  border-width: 2px;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 0, 247, 0.25);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: $blue-light;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: $blue-light;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 6px !important;
  left: -23px !important;
}

// Switches

.switch-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch {
  --#{$prefix}form-check-focus-ring-offset: -4px;
  --#{$prefix}form-check-focus-ring-border-color: #{$blue-light};

  position: relative;
  width: 50px;
  height: 25px;
  flex-shrink: 0;

  &.disabled {
    pointer-events: none;

    .layer {
      background-color: $gray;
      background-image: none;
    }
  }

  &:focus,
  &:focus-within,
  &:focus-visible,
  &.focus {
    position: relative;
    outline: none;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.114538);

    &:checked {
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.250656);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: var(--#{$prefix}form-check-focus-ring-offset);
      border-radius: inherit;
      border: var(--#{$prefix}form-check-focus-ring-border-width, 1px) dashed var(--#{$prefix}form-check-focus-ring-border-color);
    }
  }
}

.switch.r,
.switch.r .layer {
  border-radius: 100px;
}

.switch.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #dedfe3;
  transition: 0.3s ease all;
  z-index: 1;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

// Switch styles
.switch .knobs:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.switch .checkbox:checked + .knobs:before {
  content: '';
  left: 27px;
  background-color: white;
}

.switch .checkbox:checked ~ .layer {
  background-image: linear-gradient(
    9.8deg,
    #73deca -24.59%,
    #28a48c 35.24%,
    #258875 68.98%,
    #0f6e5c 93.74%
  );
}
.switch.input-error .checkbox ~ .layer {
  background-color: $red;
}

.switch .knobs,
.switch .knobs:before,
.switch .layer {
  transition: 0.3s ease all;
}

// Inputs

.form-group {
  clear: both;
  // margin-bottom: 17px;
}

.custom-radio label {
  color: $gray-light;
}

label {
  margin-bottom: 0.5rem !important;
}

/* Microsoft Edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}


:is(.form-control, .form-select, .form-check-input) {
  outline: 2px dashed transparent;
  outline-offset: 3px;

  &:focus {
    outline: 2px dashed $blue-light;
  }
  &::placeholder {
    font-weight: 400;
  }
  &[readonly] {
    border-color: $neutral-100;

    &:focus {
      box-shadow: none;
    }
  }
}

.toggle-password {
  float: right;
  margin: -17px 0 0 0;
  cursor: pointer;
  color: $gray-500 !important;
  z-index: 4;
}

// Dropdowns

.dropdown-label {
  color: #000460;
  font-size: 12px;
  justify-content: left !important;
}

.new-dropdown {
  .dropdown-toggle {
    --#{$prefix}btn-active-shadow: none;

    color: $gray-light !important;
    position: relative;
    height: 24px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    border-radius: 0;
    border: 0 transparent;
    border-bottom: 1px solid $gray;
    box-shadow: unset;
    text-align: start;
    justify-content: start;
    padding: 2px;
    &:hover {
      outline: 0 none;
      border: 0 transparent;
      border-bottom: 1px solid $gray;
    }
    &:focus {
      background-color: $white !important;
    }
    &::after {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 40%;
      transition: all 0.2s;
    }
    &:disabled {
      border: 1px solid $gray;
      background-color: $gray-300 !important;
    }
  }
  &.error-dropdown {
    .dropdown-toggle {
      color: $red !important;
      border-bottom-color: $red;
    }
  }
  span {
    color: $red;
    font-size: 11px;
  }
  &.show .dropdown-toggle,
  .dropdown-toggle.show {
    border: 0 transparent;
    border-bottom: 1px solid $gray;
    &::after {
      transform: rotate(180deg);
    }
  }
  .dropdown-menu {
    margin-top: -2px !important;
    margin-bottom: 0 !important;
    border-radius: 0;
    &.show {
      .dropdown-item {
        padding: 10px;
        font-size: 16px;
        line-height: 1.5;
        color: $gray-light;
        border-radius: 0;
        border-bottom: 0;

        &:nth-child(odd) {
          background-color: $gray-300;
        }
      }
    }
  }
}
p.control-label {
  font-size: 12px;
  color: $blue;
}
.dropdown-menu {
  margin: 0 0 30px 0;
  border: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;

  .dropdown-header {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #10265c;
  }

  .dropdown-item {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: $gray-900;

    --#{$prefix}text-focus-ring-offset: 4px;
    @include text-focus-ring;
    &:focus-within {
      &::before {
        border-width: 2px;
      }
    }
    &:focus-visible {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
      &::after {
        display: none;
      }
    }
  }
}

// Typeahead Dropdown

.we-typeahead {
  .dropdown-toggle::after {
    border-top-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    position: absolute;
    right: 12px;
    top: 16px;
  }

  .dropdown-menu {
    margin-top: 0;
    padding: 0;
    border: none;
    border-radius: 0 0 5px 5px;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #07112a;
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
  }

  .dropdown-item {
    padding: 0.7rem 1.5rem;

    &:active {
      color: #07112a;
    }

    &:last-child:hover {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .dropdown-item:focus,
  .dropdown-item:hover,
  .dropdown-item.active {
    background: #eeeff1;
    color: black;
  }
}

// 3 Postitions Switch

.switch-3-way-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  .switch-3-way {
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 25px;

    &:focus-within {
      border-radius: $border-radius-pill;
      @include card-focus-effect;
    }

    .switch-off,
    .switch-neutral,
    .switch-on {
      position: absolute;
      z-index: 9;
      -webkit-appearance: none;

      & + label {
        &::after {
          position: absolute;
          content: '';
          width: 24px;
          height: 25px;
          top: 0;
          left: 0;
          z-index: 10;
        }
      }
    }
    .switch-off {
      & + label {
        &::after {
          left: 0;
        }
      }
      &:checked {
        & ~ .switch-3-way__inner {
          background: $red;
          box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.261828);
          transition: background-color 200ms ease-in-out;
          &::after {
            left: 2px;
            transition: left 200ms ease-in-out;
          }
        }
      }
    }
    .switch-neutral {
      & + label {
        &::after {
          left: 13px;
        }
      }
      &:checked {
        & ~ .switch-3-way__inner {
          background: $gray-400;
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.261828);
          transition: background-color 200ms ease-in-out;
          &::after {
            left: 16px;
            transition: left 200ms ease-in-out;
          }
        }
      }
    }
    .switch-on {
      & + label {
        &::after {
          left: 28px;
        }
      }
      &:checked {
        & ~ .switch-3-way__inner {
          background: linear-gradient(
            9.8deg,
            #73deca -24.59%,
            #28a48c 35.24%,
            #258875 68.98%,
            #0f6e5c 93.74%
          );
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.250656);
          transition: 0.3s ease all;
          &::after {
            left: 28px;
            transition: 0.3s ease all;
          }
        }
      }
    }
    .switch-3-way__inner {
      height: 25px;
    }

    // bg
    &__inner {
      position: absolute;
      width: 50px;
      height: 24px;
      top: 0;
      left: 0;

      background: #dedfe3;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.261828);
      transition: 0.3s ease all;
      z-index: 1;
      border-color: #dedfe3;
      border-radius: 24px;

      // knob
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background: #ffffff;
        box-shadow: 0 7px 24px rgba(0, 0, 0, 0.19982),
          0 3px 5px rgba(0, 0, 0, 0.242707);
        border-radius: 50%;
        transition: left 200ms ease-in-out;
      }
    }
    input:disabled ~ label {
      pointer-events: none;
    }
    input:disabled ~ .switch-3-way__inner {
      background-color: $gray !important;
      background-image: none !important;
    }
  }


  &.disabled {
    pointer-events: none;

    .switch-3-way__inner {
      background-color: $gray;
      background-image: none;
    }
  }
}

.form-check-input {
  background-size: .75rem;

  &:not(:checked):not(:disabled):not(.form-check-readonly) {

    &:hover {
      background-color: $form-check-input-hover-bg;
    }
  }

  &:disabled {
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;

    ~ .form-check-label {
      cursor: default;
      opacity: $form-check-label-disabled-opacity;
    }
  }

  &[type="checkbox"] {

    &:checked:not(:disabled):not(.form-check-readonly) {

      &:hover {
        background-color: $blue-700;
      }
    }

    &:disabled:checked {
      background-color: $input-disabled-border-color;
    }
  }

  &[type="radio"] {
    background-size: 100%;

    &:not(:checked):hover {
    }

    &:checked:not(:disabled):not(.form-check-readonly) {
      --#{$prefix}input-check-color: #{$blue-light};
      background-color: transparent;
      border-color: $blue-light;

      &:hover {
        background-color: $blue-100;
      }
    }

    &:disabled {

      ~ .form-check-label {
        cursor: default;
        opacity: $form-check-label-disabled-opacity;
      }

      &:checked {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$input-disabled-border-color}'/></svg>"));
      }
    }
  }

  &.form-check-readonly {
    border-color: $neutral-100;
    background-color: $input-bg;

    &:hover {
      outline: none;
    }
    &:active {
      filter: none;
    }
    &[type="checkbox"] {
      &:checked {
        --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-checked-readonly-bg-image)};
      }
    }
    &[type="radio"] {
      &:checked {
        --#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-checked-readonly-bg-image)};
      }
    }
  }
}

.wrap-label {
  --#{$prefix}card-focus-ring-offset: -4px;
  --#{$prefix}card-focus-ring-border-color: #{$blue-light};

  display: block;
  padding: 1rem;
  border: 2px solid $gray-400;
  border-radius: $border-radius;

  transition: border-color .2s;

  &:hover {
    border-color: $blue-light;
  }

  .form-check-input {
    pointer-events: none;

    &:focus,
    &:focus-visible,
    &.focus {
      &:before { content: none; }
    }
  }

  @supports selector(:has(*)) {

    &:has(.form-check-input:focus){
      @include card-focus-effect;
    }
  }
  @supports not selector(:has(*)) {

    &.has-focus {
      @include card-focus-effect;
    }
  }
}
