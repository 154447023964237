@import '../variables';

.consumption-component {
  .description-text {
    margin-bottom: 40px;
    color: $gray-light;
  }
  .heading-section {
    margin-bottom: 32px;
    h2 {
      color: $blue;
      margin-bottom: 32px;
    }
  }
  .graph-section {
    margin-top: 32px;
    margin-bottom: 40px;
    filter: drop-shadow(0px 22px 45px rgba(0, 0, 0, 0.110649));
    .card-detail {
      margin-bottom: 1px;
      border-radius: 5px 5px 0 0;
      padding: 24px;
      .img {
        img {
          width: 72.6px;
          height: 64px;
        }
        h4 {
          font-size: 14px;
        }
      }
      p {
        font-size: 11px;
        color: $gray-light;
        opacity: 0.5;
        font-style: italic;
      }
    }
    .graph {
      border-radius: 0 0 5px 5px;
      padding: 32px;
    }
  }
  .graph-footer {
    color: $gray-light;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 990px) {
  .consumption-component {
    .description-text {
      margin-bottom: 32px;
    }
    .heading-section {
      margin-bottom: 24px;
    }
    .graph-section {
      margin-top: 24px;
      margin-bottom: 32px;
      .graph {
        padding: 24px !important;
      }
    }
    .graph-footer {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 32px;
    }
  }
}
