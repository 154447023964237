@import '../variables';

.datepicker {
  width: 294.55px;
  .datepicker-header {
    padding: 20px 20px 10px 20px;
    background: $orange;
    box-shadow: 0 19px 39px 0 rgba(0, 0, 0, 0.11);
    border-top-left-radius: 1.7px;
    border-top-right-radius: 1.7px;
    .title {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      color: #ffffff;
    }
    .year {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      color: rgba(255, 255, 255, 0.7);
      margin-top: -10px;
    }
    .fulldate {
      font-size: 28px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
      margin-top: -10px;
    }
  }
}

ngb-datepicker {
  padding: 10px 10px 15px 10px !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 19px 39px 0 rgba(0, 0, 0, 0.11) !important;
  background-color: #ffffff !important;
  border-bottom-left-radius: 1.7px !important;
  border-bottom-right-radius: 1.7px !important;
}

ngb-datepicker-month-view {
  padding-bottom: 10px;
}

.ngb-dp-header {
  background: $white !important;
  border-radius: 0 !important;
  padding-bottom: 10px;
}

.ngb-dp-weekdays {
  background: $white !important;
  border: none !important;
  height: 25px;
}

.ngb-dp-navigation-chevron {
  --bs-btn-focus-ring-border-color: #{$secondary};
  color: $primary !important;

  .btn[disabled] & {
    .ngb-dp-navigation-chevron {
      color: $input-disabled-bg !important;
    }
  }
}

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday,
.ngb-dp-day > div {
  width: 2.38rem !important;
  height: 2.38rem !important;
  padding-top: 3.8px;
}

.ngb-dp-weekday {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.38) !important;
  text-transform: uppercase;
}

.ngp-dp-day {
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  text-align: center !important;
  color: #07112a !important;
}

.ngb-dp-day > div {
  border-radius: 990px !important;
  font-size: 12px !important;
}

.ngb-dp-day > div.bg-primary {
  background: $orange !important;
}

ngb-datepicker select {
  background: transparent !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.75 !important;
  color: #07112a !important;
  &:first-child {
    text-align-last: right !important;
  }
}

ngb-datepicker > button {
  margin-top: 15px;
  background: transparent;
  border: none;
  float: right;
}
