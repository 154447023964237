@import '../variables';
.offer {
  &.header {
    padding: 15px 25px 15px 25px;
  }
  &.offer-card {
    &.stepper-card {
      padding: 24px 10px 24px 10px;
    }
    h2 {
      margin-bottom: 3px;
    }
    .card-title {
      margin: 0 14px 25px 4px;
    }
    .thumb {
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      i {
        font-size: 55px;
      }
    }
    .stepper {
      .main-stepper {
        margin-right: 12px;
        .stepper-content {
          .user-edit {
            border: none;
            border-radius: 50%;
            background-color: transparent;
            &.user {
              background-image: $gradiant-green !important;
              border: none !important;
              i {
                margin-right: 5px;
                margin-left: 0;
                margin-top: 6px;
              }
            }
            &.user-edit {
              border: 2px solid $green;
              height: 48px;
              width: 48px;
              background: $white;
              i {
                color: $green;
                font-size: 20px;
                margin-left: 6px;
                &::after {
                  opacity: 1;
                }
              }
            }
            &.search {
              border: 2px solid $gray-500;
              i {
                margin-left: 0;
                margin-top: 6px;
                color: $gray-500;
              }
            }
          }
          .rectangle {
            width: 1px;
            height: 100%;
            margin: -26px 24px -3px 23px;
            border: solid 1px $green;
          }
          .rectangle-dashed {
            border: dashed 1px $gray-500;
            margin: 0 24px 0 23px;
          }
          .detail {
            margin-top: 10px;
            margin-left: 10px;
            margin-bottom: 25px;
            p {
              margin: 3px 15px 0 0;
            }
          }
        }
      }
    }
  }
}
