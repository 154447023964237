@import '../variables';

// Todo: should be replaced in the future (time & datepicker missing)
form.contact {
  i.position-absolute {
    bottom: 8px;
    color: $orange;
  }
  input {
    &::placeholder {
      color: $gray-900 !important;
    }
    &.form-control.input-with-icon {
      padding-left: 20px;
    }
  }
}
