.form-floating {
  position: relative;

  &:has(.form-floating-icon) {
    :is(
      .form-control,
      .form-control-plaintext,
      .form-select
    ) {
      background-image: none;
    }
  }

  .form-control,
  .form-control-plaintext,
  .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    font-size: 0.875rem;
    line-height: 1;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  .form-control,
  .form-control-plaintext {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;

    &::placeholder {
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  .form-control:focus,
  .form-control:not(:placeholder-shown),
  .form-control-plaintext,
  .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }

  .form-control-plaintext {
    ~ label {
      border-width: $input-border-width 0; // Required to properly position label text - as explained above
    }
  }

  :is(.form-control, .form-control-plaintext, .form-select) {
    &:focus {
      ~ :is(.form-floating-icon) {
        color: $blue-light;
      }
    }

    .was-validated &:invalid,
    &.is-invalid {
      ~ .form-floating-icon {
        color: $form-feedback-invalid-color;
      }
      &:focus {
        ~ :is(label, .form-floating-icon) {
          color: $form-feedback-invalid-color;
        }
      }
    }

    .was-validated &:valid,
    &.is-valid {
      &:focus {
        ~ :is(label, .form-floating-icon) {
          color: $form-feedback-valid-color;
        }
      }
    }

    &:disabled {
      ~ label {
        color: $input-disabled-color;
      }
    }
  }
}

.form-floating-icon {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $form-floating-height;
  line-height: 1;
  font-size: 1.5rem;
  padding-right: $form-floating-padding-x;

  svg {
    width: 1.5rem;
  }

  .btn:not(.btn-link) {
    font-size: 1.5rem;
  }
}

.form-control-meta {
  outline: 2px dashed transparent;
  outline-offset: 3px;

  @include transition($input-transition);

  input {
    border: 0 none;
    height: 1.375rem;
    font-weight: $input-font-weight;
    color: $input-color;

    &::placeholder {
      font-weight: 400;
      color: $input-placeholder-color;
    }

    &:focus,
    &:focus-visible {
      outline: 0 none;
    }
  }

  label {
    pointer-events: auto;
    cursor: text;
    font-weight: 400;
  }

  &:has(input:focus) {
    border-color: $input-focus-border-color;
    outline: 2px dashed $input-focus-border-color;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }

    .form-floating-icon {
      color: $input-focus-border-color;
    }
  }

  .was-validated &:invalid,
  &.is-invalid {

    &:has(input:focus) {
      border-color: $form-feedback-invalid-color;

      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-feedback-invalid-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-feedback-invalid-focus-box-shadow;
      }

      label {
        color: $form-feedback-invalid-color;
      }

      .form-floating-icon {
        color: $form-feedback-invalid-color;
      }
    }
  }
}
