// Margin 10% classes

.mt-10-perc {
  margin-top: 10%;
}

.mr-10-perc {
  margin-right: 10%;
}

.ml-10-perc {
  margin-left: 10%;
}

.mb-10-perc {
  margin-bottom: 10%;
}

// Margin 20% classes

.mt-20-perc {
  margin-top: 20%;
}

.mr-20-perc {
  margin-right: 20%;
}

.ml-20-perc {
  margin-left: 20%;
}

.mb-20-perc {
  margin-bottom: 20%;
}

// Padding 20 classes

.p-20 {
  padding: 20px;
}

.mb-2rem {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
}
