/* autoprefixer: off */

// Vendor
// overwrite path variables
$we-assets-images-path: '/assets/img';
$we-assets-icons-path: '/assets/icons';
$fa-font-path: '/assets/fonts';

@import '@wienenergiegithub/we-t1-bootstrap-theme/src/scss/webpack-entrypoint';
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/navigation.scss';
@import 'node_modules/swiper/modules/pagination.scss';
@import 'node_modules/swiper/modules/a11y.scss';
@import 'node_modules/swiper/modules/keyboard.scss';

// Custom MeineWE
@import './global.scss';
@import './carousel.scss';
@import './cookie-consent.scss';
@import './goodies.scss';
