// HACK - to prevent T3 styles from the cookie-consent component to overwrite T1 styles

.cookie-consentcheck-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
  padding: space() 0;
  background-color: grey;
  opacity: 0.85;

  h3 {
    color: white;
  }
}

.cookie-consentcheck-content {
  padding: 0 space();
  color: white;

  h3 {
    color: white;
  }

  @include media-breakpoint-up(md) {
    width: 675px;
  }
}

.cookie-consentcheck-paragraph {
  margin-bottom: 20px;
}

.__reakit-portal {
  *:focus {
    outline: none !important;
  }

  button::after {
    border: none !important;
    border-radius: 0 !important;
  }

  div::before {
    border: none !important;
  }

  div > a:hover {
    color: #fff !important;
  }

  div > a:focus {
    background-color: transparent !important;
  }

  p a:focus {
    padding: 0 !important;
  }

  a {
    text-decoration: none !important;

    &:focus {
      background-color: #000460 !important;
      color: #fff !important;
      padding-right: 0 !important;
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      @media (min-width: 768px) {
        margin-right: 0;

        &::after {
          content: ' ';
          border-width: 0 0 2px !important;
          border-style: solid !important;
          border-color: $white !important;
          border-radius: 0 !important;
          bottom: -2px !important;
          left: 6px !important;
          margin: 0 !important;
        }
      }
    }
  }
}
