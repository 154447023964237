// Vendor
@import 'bootstrap/scss/functions';
@import '@wienenergiegithub/we-t1-bootstrap-theme/src/scss/_variables.scss';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import '@wienenergiegithub/we-t1-bootstrap-theme/src/scss/_fonts.scss';
@import '@wienenergiegithub/we-t1-bootstrap-theme/src/scss/_utilities.scss';

@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

$desktop-wide-breakpoint: 1800px;
$desktop-breakpoint-xl: 1200px;
$mobile-breakpoint: 992px;
$small-mobile-height-breakpoint: 768px;

$info-card-mobile-h2-font-size: 18px;
$info-card-mobile-line-height: 24px;

//
// FOCUS States
//
// Overwrite of Focus State from default elements
// we call it overwrite, because it overwrites the default acessibility bevaviour
.focus-overwrite {
  outline: 2px dashed $blue-light !important;
  border: none !important;
}
.focus-overwrite-border {
  border: 2px dashed $blue-light !important;
}
.focus-border-large {
  &:focus-visible::before, &:focus::before, &::before {
    border-width: 2px !important;
  }
}

.label-sm {
  display: block;
  padding: 0.75rem 0.75rem 0;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1;
  border: 1px solid transparent;

  // there are with !important in _forms.scss as well
  margin-bottom: 0 !important;
}

.p-2comma5 {
  padding: 0.75rem;
}

.text-uppercase-btn {
  & button {
    & span {
      text-transform: uppercase !important;
    }
  }
}

.text-no-underline {
  & button {
    & span {
      text-decoration: none !important;
    }
  }
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  a {
    &:hover, &.hover {
      color: $blue-light !important;
    }
    &:focus-visible,
    &:focus,
    &.focus {
      color: white !important;
      @extend .focus-overwrite;
    }
  }
}

.text-secondary-link {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  &:hover, &.hover {
    background-color: transparent;
    color: $blue-light !important;
  }
  &:focus-visible,
  &:focus,
  &.focus {
    color: white !important;
    background-color: $blue-light;
    @extend .focus-overwrite;
  }
}

.loader {
  border: 16px solid transparent;
  border-top: 16px solid $blue-light;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;

  @media (max-width: $mobile-breakpoint) {
    height: 24px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
