// ########### 200 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLight.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLight.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLightItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLightItalic.woff')
      format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraLightItalic.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
}

// ########### 300 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Light.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Light.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-LightItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-LightItalic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

// ########### 400 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Regular.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Regular.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Regular.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Regular.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Italic.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Italic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Italic.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Italic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

// ########### 500 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Medium.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-MediumItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-MediumItalic.woff')
      format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-MediumItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}

// ########### 600 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-SemiBold.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-SemiBold.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-SemiBoldItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-SemiBoldItalic.woff')
      format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

// ########### 700 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Bold.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Bold.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.ttf')
      format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

// ########### 800 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBold.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBold.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBold.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBoldItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBoldItalic.woff')
      format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-ExtraBoldItalic.ttf')
      format('truetype');
  font-weight: 800;
  font-style: italic;
}

// ########### 900 ###########
@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-Black.woff2') format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Black.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/Fira_Sans/FiraSans-BlackItalic.woff2')
      format('woff2'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BlackItalic.woff') format('woff'),
    url('../../assets/fonts/Fira_Sans/FiraSans-BlackItalic.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}
