.one-column,
.two-columns {
  .col-12-col-md-7,
  .col-12-col-md-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .two-columns {
    .col-12-col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }

    .col-12-col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  }
}

.container.container-mac {
  @include media-breakpoint-between(sm, lg) {
    max-width: unset;
    padding: 0 40px;
  }

  @include media-breakpoint-up(lg) {
    max-width: unset;
    padding: 0 60px;
  }

  @media screen and (min-width: 1800px) {
    max-width: 1740px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.container-wide {
  @include media-breakpoint-up(xl) {
    max-width: 1375px;
  }
}
.inline-size {
  container-type: inline-size;
}

@container (max-width: 599.999px) {
  .col-below-600-auto {
    @include make-col-auto();
  }
  .d-below-600-none {
    display: none !important;
  }
}

@container (min-width: 600px) {
  .col-above-600-auto {
    @include make-col-auto();
  }
  .d-above-600-none {
    display: none !important;
  }
}

@container (max-width: 407.999px) {
  .col-below-408-auto {
    @include make-col-auto();
  }
  .col-below-408 {
    @include make-col();
  }
  .col-below-408-12 {
    @include make-col(12);
  }
  .d-below-408-none {
    display: none !important;
  }
}

@container (min-width: 408px) {
  .col-above-408-auto {
    @include make-col-auto();
  }
  .col-above-408 {
    @include make-col();
  }
  .col-above-408-12 {
    @include make-col(12);
  }
  .d-above-408-none {
    display: none !important;
  }
}

