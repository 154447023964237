@import '../variables';

.list-group {
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  margin: 0 auto;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.list-group-item {
  &:hover {
    &::after {
      display: none;
    }
  }

  & > button {
    background: transparent;
    width: 100%;
    border: none;
    text-align: left;
  }

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  color: #07112a;
  padding: 15px 5px 15px 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);

  &:last-child {
    border-bottom: none;
  }
}

.list-group-item-action:focus,
.list-group-item-action:hover,
.list-group-item.active {
  & > button {
    color: white;
  }

  background-color: #000460;
  color: white;
  border-bottom: 1px solid #000460;

  &:last-child {
    border-bottom: none;
  }

  .list-group-badge {
    color: #000460;
    background: white;
  }

  .list-group-btn {
    color: white;
  }
}

.list-group-badge,
.list-group-btn {
  background: #000460;
  color: #fff;
  height: 43px;
  width: 43px;
  border-radius: 100px;
  display: inline-block;
  text-align: center;
  padding-top: 13px;
  margin-right: 20px;
}

.list-group-btn {
  font-size: 18px;
  background: transparent;
  color: #000460;
  margin-right: 0;
  float: right;
}

.list-group-flush,
.list-group--dropdown {
  padding-left: 22px;
  padding-right: 22px;
}

.list-group-flush > .list-group-item {
  padding: 14px 0 14px 0;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  color: #005797;
}

.list-group--dropdown {
  padding: 0 22px 22px 22px;
  border-radius: 5px;
  box-shadow: 0 38px 45px 0 rgba(0, 0, 0, 0.11);
  background: #ffffff;

  h6 {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    color: #000460;
    margin-top: 30px;
    display: inline-block;
  }
  ul {
    box-shadow: none;
  }
  li {
    padding: 14px 0 14px 0;
    text-transform: none !important;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #07112a;
  }

  &.is-card-extension {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 2;
    position: relative;
  }
}

.list-group--extended {
  border-radius: 5px;
  padding: 37px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;

  h6 {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    color: #07112a;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 40px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    color: #000460;
    margin-bottom: 16px;
  }

  hr {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: -10px;
    margin-right: -10px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #07112a;
  }
}

@include media-breakpoint-up(sm) {
  .list-group-item {
    padding: 27px;
  }
}

@include media-breakpoint-up(md) {
  .list-group--extended {
    p {
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      color: #07112a;
    }
  }

  .content-box-inner {
    min-height: 603px;
  }
}

@include media-breakpoint-up(lg) {
  .list-group,
  .list-group--dropdown {
    max-width: 430px;
    margin: 0;
  }

  .list-group-container {
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
    background: white;
  }

  // Layout
  .content-box-nav {
    padding-right: 0;
  }

  .content-box-inner {
    padding-left: 0;
  }
}

@media (max-width: 992px) {
  .container.limited {
    max-width: 907px;
  }
}
