

.table-custom-striped {
    width: 100%;

    :is(thead, tbody, tfoot) {
        :is(td, th) {
            padding: .5rem .5rem .5rem .5rem;

        }
    }

    :is(thead, tfoot) {
        color: $blue;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }

    tbody {

        tr:nth-child(odd) {
            :is(td, th) {
                background-color: $gray-300;
            }
        }

        tr:nth-child(even) {
            :is(td, th) {
                background-color: $white;
            }
        }
    }

    tfoot {
        vertical-align: top;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 1.625rem;

        :is(thead, tbody, tfoot) {
            :is(td, th) {
                padding: 1.5rem 1.5rem 1.5rem 1.5rem;
            }
        }

        :is(thead, tfoot) {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }

    @include media-breakpoint-down(md) {
        table-layout: fixed;

        td, th {
            width: 50%;
            vertical-align: top;
        }
    }
}

.status-bubble {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    color: $white;
    border-radius: $border-radius-pill;

    :is(i, svg.svg-inline--fa) {
        font-size: .5rem;
    }

    @include media-breakpoint-up(md) {
        width: 1.5rem;
        height: 1.5rem;

        :is(i, svg.svg-inline--fa) {
            font-size: .75rem;
        }
    }
}

.status-bubble-success {
    background-image: linear-gradient(134.66deg, #73DECA 3.37%, #28A48C 33.5%, #258875 62.74%, #0F6E5C 88.49%);
}

.status-bubble-warning {
    background-image: linear-gradient(134.66deg, #FFD88C 3.37%, #D9A300 46.27%, #CB8603 89.57%);
}

.status-bubble-danger {
    background-image: linear-gradient(133.03deg, #FF7BA7 9.52%, #EB296A 31.23%, #DB0033 59.19%, #9F0A3C 90.66%);
}

.status-bubble-info {
    background-image: linear-gradient(135.2deg, #3EA9CA 3.61%, #06649B 52.86%, #0F255B 116.41%);
}

.table-custom-striped tr.table-hover-effect {
    > td {
        transition: background-color 0.15s ease-in-out;
    }
    &:focus,
    &:focus-visible {
        outline: none;
    }
    &:hover > td,
    &:focus > td,
    &:focus-visible > td,
    &.active > td {
        background-color: #e3ebf2;
    }
    &.active > td:first-child {
        box-shadow: 2px 0 0 inset $blue-light !important;
    }
}

table.table-rounded-borders {
    border-collapse: separate;
    border-radius: $border-radius;
    border: 1px solid $gray;
    border-spacing: 0;
    tbody tr:last-child td:first-child {
        border-radius: 0 0 0 $border-radius;
    }
    tbody tr:last-child td:last-child {
        border-radius: 0 0 $border-radius 0;
    }
    thead th {
        border-bottom: 1px solid $blue;
    }
    th:not(:last-child),
    td:not(:last-child) {
        border-right: 1px solid $gray;
    }
}

.list-custom-striped {
    li:nth-child(odd) {
        background-color: $gray-300;
    }
    li:nth-child(even) {
        background-color: $white;
    }
}

.link-list-custom-striped {
    li:nth-child(odd) {
        :is(a) {
            background-color: $gray-300;
        }
    }
    li:nth-child(even) {
        :is(a) {
            background-color: $white;
        }
    }
    li a {
        transition: background-color 0.15s ease-in-out;
        text-decoration: none;
        &:hover,
        &:focus,
        &:focus-visible {
            color: inherit;
            outline: none;
            background-color: #e3ebf2;
        }
        &.active {
            background-color: #e3ebf2;
            box-shadow: 2px 0 0 inset $blue-light !important;
        }
    }
}

.link-list-custom-striped.link-list-rounded-borders {
    border-radius: $border-radius;
    border: 1px solid $gray;
    li:first-child a {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
    li:last-child a {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}