// Goodie Category
.overflow-x-hidden {
  overflow-x: hidden;
}

.goodie-overview {

  mwe-horizontal-swiper {
    .swiper.swiper-container {
      margin-top: 0;
      padding: 0 0 2.8em 0;
      margin-right: -10em;
      margin-left: -10em;
    }
  }
}

.goodie-overview-details {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: theme-color(dark);
  @include media-breakpoint-up(md) {
    font-size: 16px;
    width: 50%;
  }
  &.goodie-overview-welcome {
    @include media-breakpoint-up(md) {
      width: 66%;
    }
  }
}

%noUnderline {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

// Goodie Card

.goodie {
  position: relative;
  max-width: 360px;
  margin: 0 10px 10px 0;

  a {
    @extend %noUnderline;
    &:hover::after {
      display: none;
    }
  }

  p a {
    text-decoration: underline !important;
  }

  @include media-breakpoint-up(md) {
    width: auto;
    margin-right: 0;
  }
}

.goodie-wrapper {
  @extend %noUnderline;
  &:hover > .goodie-content-wrapper > .goodie-title {
    text-decoration: underline;
  }
  border-radius: 5px;
  position: relative;
  max-height: 360px;
  height: 360px;
  background-color: $white;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);

  @include media-breakpoint-up(md) {
    max-height: 455px;
    height: 455px;
  }
}

.goodie-wrapper-is-expired {
  filter: grayscale(100%);

  &:hover > .goodie-content-wrapper > .goodie-title {
    text-decoration: none;
  }
}

.goodie-title {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 20px;
  padding-top: 2px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

.goodie-content-wrapper {
  padding: 20px 24px 20px 25px;
}

.goodie-content-description {
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: theme-color(dark);
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.goodie-favorite {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0.5em;
  right: 0.5em;
  font-size: 22px;
  background: white;
  color: theme-color(secondary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);

  @include media-breakpoint-up(md) {
    font-size: 28px;
    height: 50px;
    width: 50px;
  }
}

.goodie-favorite-is-active {
  color: theme-color(primary);
}

.goodie-expired {
  position: absolute;
  top: 0.5em;
  left: 0.5em;

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 125px;
    height: 50px;
  }
}

.goodie-badge {
  position: absolute;
  right: 15px;
  top: 135px;
  line-height: 0;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.15);

  align-items: center;
  border-radius: 50%;
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-align: center;

  width: 72px;
  height: 72px;

  background: $green;
  color: $white;

  @include media-breakpoint-up(md) {
    top: 190px;
    right: 15px;
    height: 96px;
    width: 96px;
  }

  &.type-discount {
    background: $red;
  }

  &.type-raffle {
    background: $green;
  }
  &.type-mgm {
    background: $orange;
  }

  &.type-gift {
    background: $yellow;
  }

  &.type-bonus,
  &.type-campaign {
    background: $orange;
  }

  img {
    max-width: 100%;
  }

  .discount {
    width: 25px;
    height: 25px;
    font-size: 25px;

    @include media-breakpoint-up(md) {
      width: 33px;
      height: 33px;
      font-size: 33px;
    }
  }
  .mgm,
  .bonus {
    width: 36px;
    height: 36px;
    @include media-breakpoint-up(md) {
      width: 42px;
      height: 42px;
    }
  }

  .gift {
    width: 36px;
    height: 36px;
    font-size: 36px;

    @include media-breakpoint-up(md) {
      width: 42px;
      height: 42px;
      font-size: 42px;
    }
  }

  .raffle,
  .campaign {
    width: 36px;
    height: 36px;
    font-size: 36px;

    @include media-breakpoint-up(md) {
      width: 42px;
      height: 42px;
    }
  }
}

.goodie-image {
  height: 193px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @include media-breakpoint-up(md) {
    height: 270px;
  }
}

// Goodie Detail
.goodie-detail-view {
  max-width: 100%;
  width: 100%;
  padding-bottom: 0;

  .goodie-wrapper {
    background: transparent;
    background-color: unset;
    background-image: unset;
    box-shadow: none;
    max-height: 100%;
    height: 100%;
    padding: 0;
  }

  .goodie-action-wrapper {
    display: none;
  }

  .goodie-image {
    margin: 0;
    width: 100%;
    height: 220px;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      height: 560px;
    }
  }

  .goodie-badge {
    top: 160px;

    @include media-breakpoint-up(md) {
      right: 25px;
      top: 475px;
    }
  }

  .goodie-favorite {
    top: 15px;
    right: 15px;
  }

  .goodie-title {
    margin-bottom: 24px;
    -webkit-line-clamp: unset;
    font-size: 24px;

    @include media-breakpoint-up(md) {
      font-size: 54px;
    }
  }

  .goodie-time {
    margin-bottom: 28px;
    color: theme-color(secondary);
    font-weight: 600;
    font-size: 18px;
  }

  .goodie-content-description {
    margin-bottom: 28px;
    -webkit-line-clamp: unset;
    font-size: 16px;
  }

  .goodie-image {
    object-fit: cover;
  }
}

.goodie-detail-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
}

// Empty Goodie Block
.goodies-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 36px 20px;
  text-align: center;
  background-color: $white;

  h5 {
    font-size: 20px;
    @include media-breakpoint-up(md) {
      font-size: 28px;
    }
  }

  img {
    width: 100px;
    margin-bottom: 1em;
  }

  li {
    color: theme-color(secondary);
    font-size: 32px;
    vertical-align: bottom;
  }
}

.goodies-favorite-empty {
  @extend .goodies-flex-grid;
  .goodie {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px;
    text-align: center;
    width: 360px;

    li,
    em {
      color: theme-color(secondary);
    }
  }
}

.empty-goodie-card-image {
  img {
    width: 130px;
  }
}

.goodie-empty {
  box-shadow: none;
  .empty-goodie-card-image-wrapper {
    flex: 1 0 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dedfe3;
    width: 360px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-bottom: 18px;
    margin-top: -28px;

    li {
      font-size: 96px;
      color: white;
    }
  }

  div {
    text-align: left;
  }

  p,
  h5 {
    color: transparent;
    background: #dedfe3;
    user-select: none;
    display: inline-block;
    border-radius: 5px;
  }

  h5 {
    height: 26px;
    margin-bottom: 24px;
  }

  p {
    height: 13px;
    margin-bottom: 6px;
  }
}

.goodie-detail-activate-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
}

.goodie-detail-activate-container {
  @include media-breakpoint-up(md) {
    width: 435px;
  }
}

.goodie-detail-activate-headline {
  align-items: center;
  justify-content: flex-start;
  flex: 0;

  h5 {
    margin: 0;
    font-size: 24px;
    @include media-breakpoint-up(md) {
      font-size: 34px;
    }
  }
}

.goodie-detail-activate-desc {
  font-weight: 200;
  font-size: 16px;
}

.goodie-detail-activate-icon {
  color: theme-color(secondary);
  font-size: 20px;
  margin-right: 12px;
  @include media-breakpoint-up(md) {
    margin-right: 20px;
    font-size: 30px;
  }
}

.goodie-detail-activate-input-wrapper {
  position: relative;
}

.goodie-detail-activate-input {
  background: #eeeff1;
  width: 100%;
  height: 50px;
  border: none;
}

.goodie-detail-to-clipboard {
  position: absolute;
  color: theme-color(primary);
  font-size: 22px;
  right: 15px;
  top: 13px;
  cursor: pointer;
}

li.goodie-detail-activation-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: $green;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.goodie-detail-back-link {
  display: inline-block;
  font-weight: 500;
}

.goodies-show-more-content.contracted {
  & > div {
    display: none;
  }
  & > div:nth-child(-n + 3) {
    display: block;
  }
}

.goodies-flex-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(275px, 1fr));
  align-content: center;
  justify-items: center;
  gap: 16px;
}

.goodies-show-more-button-wrapper {
  margin-top: 15px;
}

.goodie-overview .custom-swiper-button-wrapper {
  display: none;
}
