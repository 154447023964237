.card-selectable {
    display: block;
    padding: 1.5rem;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;

    @include border-radius($input-border-radius, 0);

    @include transition($input-transition);
    border-radius: $input-border-radius;

    &:hover {
        background-color: $neutral-050;
    }

    &:has(.form-check-input:checked) {
        &:hover {
            border-color: $blue-600;
            background-color: $blue-050;
        }
    }

    &:has(.form-check-input.is-invalid) {
        border-color: $danger;
    }

    &:has(.form-check-input:disabled) {
        &,
        &:hover {
            border-color: $neutral-300;
            background-color: $neutral-100;
        }
    }

    .form-check-input {
        &:focus,
        &:focus-visible {
            outline: none;
        }
    }

    &:has(.form-check-input:focus, .form-check-input:focus-visible) {
        outline: 2px dashed $blue-light;
        outline-offset: 3px;
    }

    &:has(.form-check-input.form-check-readonly) {
        &,
        &:hover {
            background-color: $input-bg;
            border-color: $neutral-100;
            outline: none;
        }
    }
}