@import '../variables';

.alert {
  border-radius: $border-radius;
  color: $white;
  border: none;
  padding: 1rem;
  position: relative;

  &:not(.alert-minimal) {
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  :is(h1,h2,h3,h4,h5,h6,p, p a:not(.btn), p a:not(.btn):hover) {
    color: inherit;
  }
}

.alert-close {
  background-color: transparent;
  padding: $alert-padding-y $alert-padding-x;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  color: inherit;
  text-shadow: none;
  opacity: 1;

  &:hover {
    color: $white;
  }

  span {
    width: 12px;
    height: 12px;
    display: block;
    background-image: url($we-assets-icons-path + '/alert-close.svg');
  }
}

.alert-icon-wrapper {
  font-size: 1rem;
  line-height: 1;
  min-width: 1em;
  max-width: 1em;
  min-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  .alert:not(.alert-minimal) & {
    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      min-height: 1em;
    }
  }
}

.alert-info {
  background-image: url($we-assets-images-path + '/alert-pattern.svg'),
    linear-gradient(90deg, #06649b 0%, #0f255b 100%) !important;
  background-repeat: no-repeat;
  background-position: right 5% top 0px, top left;
}

.alert-success {
  background-image: url($we-assets-images-path + '/alert-pattern.svg'),
    linear-gradient(90.05deg, #28a48c 0.04%, #258875 48.47%, #0f6e5c 99.79%) !important;
  background-repeat: no-repeat;
  background-position: right 5% top 0px, top left;
}

.alert-warning {
  background-image: url($we-assets-images-path + '/alert-pattern.svg'),
    linear-gradient(90deg, #d9a300 0%, #cb8603 100%) !important;
  background-repeat: no-repeat;
  background-position: right 5% top 0px, top left;
}

.alert-danger {
  background-image: url($we-assets-images-path + '/alert-pattern.svg'),
    linear-gradient(90deg, #db0033 0%, #9f0a3c 100%) !important;
  background-repeat: no-repeat;
  background-position: right 5% top 0px, top left;
}

.alert-neutral {
  background-image: url($we-assets-images-path + '/alert-pattern.svg'),
    linear-gradient(89.98deg, #dedfe3 0.02%, #949494 99.99%) !important;
  background-repeat: no-repeat;
  background-position: right 5% top 0px, top left;
  color: $secondary;
}
