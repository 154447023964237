@import '../variables';

.line-h {
  line-height: inherit !important;
}
.black {
  color: #07112a !important;
}
.notify {
  border-radius: 6px;
  background-color: $white;
  padding: 20px 24px 15px 24px;
  max-width: 435px;
  position: relative;
  margin-left: auto;
}
.notify-close {
  color: $blue;
  opacity: 1;
  font-size: 28px;
  font-weight: 500;
  border: none;
  background: transparent;
  position: absolute;
  top: -5px;
  right: 2px;
}
.revert {
  flex-direction: column-reverse;
}
.cover-inner .mb-100 {
  margin-bottom: 100px;
}
.cover-inner .mt-100 {
  margin-top: 15px;
}
.cover--margin {
  margin-top: -60px;
}
.notification-animated {
  transition: max-height 1s, opacity 0.5s;
  transition-timing-function: ease-in-out;
  max-height: 500px;
}

.notification-visually-hidden {
  opacity: 0;
}

.notification-hidden {
  max-height: 0;
}

.info-icon {
  font-size: 30px;
  color: $blue;
}
.pl-15 {
  padding-left: 0;
}
.tooltip-card {
  padding: 20px 25px;
}

@media screen and (min-width: 991px) {
  .revert {
    flex-direction: revert;
  }
  .info-icon {
    font-size: 40px;
    color: $blue;
  }
  .cover-inner .mt-100 {
    margin-top: 100px;
  }
  .cover--margin {
    margin-top: 60px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .tooltip-card {
    padding: 25px 25px;
  }
}
@media screen and (min-width: 768px) {
  .revert {
    flex-direction: revert;
  }
}
