@import '../variables';

h-title {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #07112a;
}

h-desc {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
}

p-info {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  color: #07112a;
}

p-thick {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  color: #07112a;
}

.rectangle-night-light {
  width: 137px;
  height: 106px;
  border-radius: 5px;
  background-color: #000460;
}

.rectangle-the-truth {
  width: 137px;
  height: 106px;
  border-radius: 5px;
  background-color: #505558;
}

.rectangle-the-nothing {
  width: 137px;
  height: 106px;
  border-radius: 5px;
  border: solid 1px #000000;
  background-color: #ffffff;
}

.rectangle-energy {
  width: 137px;
  height: 106px;
  border-radius: 5px;
  background-color: #eb6626;
}

.rectangle-aqua {
  width: 137px;
  height: 106px;
  border-radius: 5px;
  background-color: #005797;
}

.shape-background {
  width: 160px;
  height: 202px;
  padding: 8px 38px 26px 30px;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
}

.weight-bold-title {
  font-size: 80px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #000460;
}

.weight-bold-subtitle {
  font-size: 22.4px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #000460;
}

.weight-text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #07112a;
}

.weight-semibold-title {
  font-size: 80px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #000460;
}

.weight-semibold-subtitle {
  font-size: 22.4px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: #000460;
}

.weight-regular-title {
  font-size: 80px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000460;
}

.weight-regular-subtitle {
  font-size: 22.4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000460;
}

.font-description {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  color: #07112a;
}

.description-width {
  width: 184px;
}
