
.swiper-focus-outline-fix {
  .swiper-container {
    padding: 0.5rem 0.5rem 0;
    margin-left: -0.5rem;
  }
}
.products-swiper-wrapper {
  .swiper-slide {
    width: 210px !important;
  }
}