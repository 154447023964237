@import '../variables';

.bg--orange {
  background: $orange;
}

.bg--blue {
  background: $blue;
}

.bg--blue-light {
  background: $blue-light;
}

.bg--green {
  background: $green;
}

.bg-green-100 {
  background-color: rgba($green, 0.1);
}

.bg-blue-100 {
  background-color: rgba($blue-light, 0.1);
}

.bg--red {
  background: $red;
}

.bg--yellow {
  background: $yellow;
}

.bg-warning-100 {
  background-color: rgba(217, 163, 0, 0.1);
}

// Gray

.bg-gray--100 {
  background: $gray-100;
}

.bg-gray--200 {
  background: $gray-200 !important;
}

.bg-gray--300 {
  background: $gray-300;
}

.bg-gray--400 {
  background: $gray-400;
}

.bg-gray--500 {
  background: $gray-500;
}

.bg-gray--600 {
  background: $gray-600;
}

.bg-image-product {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 150px;
  background-image: url("data:image/svg+xml,%3Csvg width='218' height='93' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='109.266%25' y1='27.273%25' x2='33.922%25' y2='81.477%25' id='a'%3E%3Cstop stop-color='%23E13B31' offset='0%25'/%3E%3Cstop stop-color='%23ED771E' offset='37.326%25'/%3E%3Cstop stop-color='%23F7AF40' offset='72.67%25'/%3E%3Cstop stop-color='%23FCCF8A' offset='100%25'/%3E%3C/linearGradient%3E%3ClinearGradient x1='5.694%25' y1='61.403%25' x2='100%25' y2='44.271%25' id='b'%3E%3Cstop stop-color='%23FCCF8A' offset='0%25'/%3E%3Cstop stop-color='%23FCCF8A' offset='0%25'/%3E%3Cstop stop-color='%23F7AF40' offset='45.698%25'/%3E%3Cstop stop-color='%23ED771E' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M25.794 172.047c15.92 2.948 30.19 4.608 42.807 4.983 65.66 1.946 111.687-26.026 131.547-48.65 20.625-23.496 28.148-47.265 22.57-71.307L10.906 101.872l14.889 70.175z' fill='url(%23a)' transform='scale(-1 1) rotate(12 376.1 -1032.672)'/%3E%3Cpath d='M174.06 39.868L71.835 163.72c13.926 7.9 26.68 11.783 38.26 11.649 17.373-.202 45.362-13.266 64.817-26.617 13.986-10.771 34.784-29.377 62.395-55.815l-63.247-53.07z' fill='url(%23b)' transform='rotate(50 238.117 66.051)'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-image-product-alt {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 150px;
  background-image: url("data:image/svg+xml,%3Csvg width='218' height='93' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='-22.805%25' y1='45.338%25' x2='100%25' y2='52.182%25' id='a'%3E%3Cstop stop-color='%23FCCF8A' offset='0%25'/%3E%3Cstop stop-color='%23F7AF40' offset='27.33%25'/%3E%3Cstop stop-color='%23ED771E' offset='62.674%25'/%3E%3Cstop stop-color='%23E13B31' offset='100%25'/%3E%3C/linearGradient%3E%3ClinearGradient x1='106.68%25' y1='25.222%25' x2='0%25' y2='75.87%25' id='b'%3E%3Cstop stop-color='%233EA9CA' offset='0%25'/%3E%3Cstop stop-color='%2306649B' offset='52.12%25'/%3E%3Cstop stop-color='%230F255B' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M25.794 172.047c15.92 2.948 30.19 4.608 42.807 4.983 65.66 1.946 111.687-26.026 131.547-48.65 20.625-23.496 28.148-47.265 22.57-71.307L10.906 101.872l14.889 70.175z' fill='url(%23a)' transform='scale(-1 1) rotate(12 376.1 -1032.672)'/%3E%3Cpath d='M174.06 39.868L71.835 163.72c13.926 7.9 26.68 11.783 38.26 11.649 17.373-.202 45.362-13.266 64.817-26.617 13.986-10.771 34.784-29.377 62.395-55.815l-63.247-53.07z' fill='url(%23b)' transform='rotate(50 238.117 66.051)'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-ellipse {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -4%;
    width: 160%;
    height: 260%;
    border-radius: 50%;
    opacity: 0.5;
    background-image: linear-gradient(
                    124deg,
                    rgba(0, 0, 0, 0.01) -87.85%,
                    rgba(0, 0, 0, 0.02) -27.88%,
                    rgba(0, 0, 0, 0.03) 30.3%,
                    rgba(0, 0, 0, 0.20) 81.54%);
    z-index: -1;
  }
}

.bg-ellipse-inverse {

  &:before {
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 40.84%, rgba(255, 255, 255, 0.01) 96.38%); //smarter solution
  }
}

.bg-green-010 {
  background-color: $green-010 !important;
}

.bg-blue-050 {
  background-color: $blue-050 !important;
}

.bg-red-050 {
  background-color: $red-050 !important;
}

.bg-yellow-050 {
  background-color: $yellow-050 !important;
}

.bg-neutral-010 {
  background-color: $neutral-010 !important;
}
