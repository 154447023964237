@import '../variables';

.we-select {
  width: 100%;

  button.form-control {
    width: 100%;
    text-align: left;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $gray;
    border-radius: 0;
    padding-left: 0;

    &:focus {
      border-bottom: 1px solid $blue;
    }
  }

  .dropdown-toggle::after {
    border-top-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    position: absolute;
    right: 12px;
    top: 9px;
  }

  .dropdown-menu {
    width: 100%;
    margin-top: 0;
    padding: 0;
    border: none;
    border-radius: 0 0 5px 5px;
    font-size: 12px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #07112a;
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;

    .dropdown-item {
      padding: 0.7rem 1.5rem;

      &:active {
        color: #07112a;
      }

      &:last-child:hover {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
      background: $gray-400;
    }
  }
}
