@import '../variables';

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo--stencil {
  height: 13px;
  margin-top: -5px;
  margin-right: 10px !important;
  width: 25px;
}

// hero
.hero-header {
  padding-top: 6em;
}

.hero--bg {
  background-image: url($we-assets-images-path + '/background-b2c.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  .theme-override-b2b & {
    background-image: url($we-assets-images-path + '/background-b2b.svg');
  }
}

.hero {
min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: normal;
  padding: 1em;
  box-sizing: border-box;
  color: white;
}

.hero button {
  width: 100%;
}

.hero-title {
  max-width: 16em;
  font-size: 12vh;
  font-weight: bold;
  line-height: 0.9;
  padding-bottom: 30px;
}

.hero-footer {
  display: flex;
  margin-bottom: 1em;
  margin-top: 1em;
}

.hero button {
  width: 100%;
}

.hero-image {
  position: relative;
  width: 250px;
  min-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.hero-image-container {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  min-width: 250px;
}

.hero-image-animation {
  position: absolute;
  top: 82px;
  left: 52px;
  width: 146px;
}

// Hero alerts
.hero .alert {
  width: 100% !important;
  // margin: 64px 0 64px 0;
  margin: 32px 0 45px 0;
}

.feature-video {
  width: 100%;
  height: auto;
  margin-top: 8px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .hero-image {
    position: relative;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 280px;
  }

  .hero-image-animation {
    position: absolute;
    top: 90px;
    left: 58px;
    width: 164px;
  }
}

// Small device landscape

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .hero {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .hero button {
    max-width: 300px;
  }

  .hero-image {
    position: relative;
    width: 320px;
    top: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 320px;
  }

  .hero-image-animation {
    position: absolute;
    top: 104px;
    left: 68px;
    width: 184px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .hero-image {
    position: absolute !important;
    width: 527px;
    top: -50px;
    right: -30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
  }

  .hero-image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 527px;
  }

  .hero-image-animation {
    position: absolute;
    top: 120px;
    left: 107px;
    width: 214px;
  }

  .hero button {
    max-width: 300px;
  }

  .hero-title {
    padding-bottom: 40px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .hero-footer .col-md {
    width: auto;
  }

  .hero-image {
    position: absolute !important;
    width: 388px;
    top: -50px;
    right: -30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
  }

  .hero-image-container {
    position: relative;
    top: 0;
    left: 0;
    width: 388px;
  }

  .hero-image-animation {
    position: absolute;
    top: 114px;
    left: 105px;
    width: 210px;
  }
}

//Internet Explorer only
@media all and (-ms-high-contrast: none) {
  @media (max-height: 991px) {
    .hero {
      height: auto !important;
      overflow-x: hidden;
    }
  }
}

//Feature Overview Hero
.feature-hero {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  color: white;
  background: url($we-assets-images-path + '/feature-background-big.svg')
    no-repeat;
  background-size: cover;

  h1 {
    font-size: 66px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    color: #ffffff;
    text-transform: none;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    color: #ffffff;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    color: #ffffff;
  }
}

span.subtitle {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #ffffff;
}

.feature {
  h1 {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: #10265c;
    text-transform: none !important;
  }

  h3 {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    color: #10265c;
    text-transform: none;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #07112a;
  }

  img {
    width: 100%;
  }
}

.feature-title {
  h1 {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: #10265c;
    text-transform: none !important;
  }
}

.feature-btn {
  width: 90%;
}

.tree-background {
  height: 70%;
  min-height: 500px;
  background-image: url($we-assets-images-path + '/illu_upcoming.svg');
  background-repeat: no-repeat;
  background-position: calc(85%) calc(100%);
}

.feature-header {
  margin-top: 10%;
}

.ft-background-blue {
  background-color: #eeeff1;
}

.ft-background-white {
  background-color: #f7f8f8;
}

.ft-padding-top {
  padding-top: 120px;
}

.ft-mp {
  padding: 40px 0px 100px 0px;
}

.ft-mp-l {
  padding: 100px 0px 80px 0px;
}

.ft-footer {
  height: 44%;
  min-height: 400px;
  background: url($we-assets-images-path + '/onboarding-background-big.svg')
    no-repeat;
  background-size: cover;
}

.ft-footer-h1 {
  margin-top: 10%;
  font-size: 66px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  color: #ffffff !important;
  text-transform: none;
}

.no-padding {
  padding: 0px 0px 0px 0px !important;
}

.low-padding {
  padding: 0px 12px 0px 12px !important;
}

@media (max-width: 1024px) {
  .feature {
    padding: 8px 18px 8px 18px !important;
    margin: 0px 16px 0px 16px !important;

    h1 {
      font-size: 34px;
    }

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  .tree-background {
    background-position: calc(100%) calc(0%);
  }

  .feature-title {
    h1 {
      font-size: 34px;
    }
  }
}

@media (max-width: 425px) {
  .feature-header {
    margin-top: 70%;
  }

  .ft-mp {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .ft-mp-l {
    padding: 20px 0px 0px 0px;
  }

  .ft-footer {
    height: 50%;
    h1 {
      font-size: 40px;
    }
  }

  span.subtitle {
    font-size: 10px;
    display: none;
  }

  .feature-hero h1 {
    font-size: 32px;
  }

  .feature-hero h2 {
    font-size: 14px;
  }

  .feature-hero p {
    font-size: 16px;
  }

  .ft-padding-top {
    padding-top: 40px;
  }

  .feature-hero {
    min-height: 590px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  .tree-background {
    background-position: calc(100% + 80px) calc(0% - 100px);
  }

  .ft-footer-h1 {
    margin-top: 20%;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    color: #ffffff !important;
    text-transform: none;
  }

  .feature-btn {
    width: 100%;
  }
}

// Just so it doesn't take too much empty space on tablets
@media (min-width: 768px) {
  .feature {
    margin: 0px 2px 0px 2px !important;
    padding: 0px 8px 0px 8px !important;
  }
}

@media (max-width: 576px) {
  span.subtitle {
    font-size: 10px;
    display: none;
  }
}
