@import '../variables';

.personal-information {
  .personal-information-card {
    width: inherit;
    padding: 32px;
    margin: 0 0 3rem 0;
    border-radius: 0.25rem;

    .form-content {
      margin-top: 40px;
      input {
        &.form-control {
          height: 30px;
        }
      }
    }
    .form-footer {
      margin-top: 12px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      gap: 18px;
    }
    .form-heading {
      max-width: 100%;
      box-shadow: unset;
      padding: 0;
      h5 {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .personal-information {
    .personal-information-card {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .personal-information {
    margin-bottom: 40px;
    .personal-information-card {
      padding: 24px;
    }
  }
}

@media (max-width: 575px) {
  .personal-information {
    .personal-information-card {
      .form-footer {
        margin-top: 12px;
        justify-content: stretch;
        flex-direction: column;
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
