@import '../variables';

html,
body {
  height: 100%;
  min-width: 300px;
}

.cover {
  min-height: 260px;
  width: 100%;
}

.cover--margin {
  margin-top: -60px;
  padding-bottom: 30px;
}

.cover-inner {
  margin-top: 15px;

  h1 {
    text-transform: none;
  }
}

.cover h2 {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.22;
  color: #ffffff;
  text-transform: uppercase;
}

.cover h1 {
  font-size: 34px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.12;
  color: #ffffff;
}

.cover p {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 10px;
}

.header-new {
  display: block;
  background-image: url(../../../assets/img/header-background-mobile-new.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;

  .nav-item {
    .nav-link {
      &.logout-link {
        i {
          font-size: 12px;
          margin-top: 2px;
        }
      }
    }
  }
}

.page-content {
  flex: 1 0 auto;
  padding-bottom: 5rem;
}

.content-container {
  margin-bottom: 65px;
}

.sticky-footer {
  flex-shrink: unset;
}

.cover.background-image {
  background-image: url($we-assets-images-path + '/background-big.svg');
  background-size: cover;
  background-repeat: no-repeat;

  .theme-override-b2b & {
    background-image: url($we-assets-images-path + '/background-header-b2b.svg');

  }
}

.cover.blue {
  background: linear-gradient(126deg, #3ea9ca, #06649b 74%, #0f255b);
}

.cover.orange {
  background: $orange;
}

.cover.minimized {
  height: 65px !important;
  min-height: 65px !important;

  > div > .row {
    padding-top: 1rem;
  }
}

.content-box {
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);
  background: #fff;
  padding: 20px;

  h5 {
    padding-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    text-transform: none;
    color: #10265c;
  }

  .form-buttons {
    padding-top: 40px;
  }

  .form-buttons button {
    width: 100%;
  }

  .form-buttons button:not(:last-child) {
    margin-bottom: 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 376px) {
  .content-box {
    padding: 50px;
  }

  .cover-inner {
    margin-top: 40px;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .cover {
    height: 390px;
  }

  .cover-inner {
    margin-top: 80px;
  }

  .cover h1 {
    font-size: 66px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.95;
    color: #ffffff;
  }

  .cover p {
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.86;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .content-box {
    padding: 100px;

    .form-buttons button {
      width: auto;
    }

    .form-buttons button:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  .header-new {
    background-image: url('../../../assets/img/header-background-new.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .content-box {
    min-height: 603px;
    padding: 50px;
  }
}
