
.card-basic {
    --#{$prefix}border-color: #{$gray-400};
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: $white;
    border-radius: $border-radius-lg;
    box-shadow: 0 22px 45px 0 rgba(0, 0, 0, 0.11);

    @supports selector(:has(*)) {
        &:has(.card-focus-effect-trigger:focus) {
            @include card-focus-effect;

            .card-focus-effect-trigger:focus::before {
                content: none;
            }
        }
    }
    @supports not selector(:has(*)) {
        &.card-has-focus {
            @include card-focus-effect;

            .btn-bubble:focus::before {
                content: none;
            }
        }
    }
}

.card-basic-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;

    @include media-breakpoint-up(lg) {
        padding: 2rem;
    }
}
.card-basic-body-slim {
    flex: 1 1 auto;
    min-height: 1px;
    padding: .75rem 1.5rem;

    @include media-breakpoint-up(lg) {
        padding: 1rem 2rem;
    }
}

.card-basic-footer {
    flex: 0 0 auto;
    min-height: 1px;
    padding: .75rem 1.5rem;
    background-color: $gray;
    color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include media-breakpoint-up(lg) {
        padding: 1rem 2rem;
    }
}

.card-basic-segment {
    & + & {
        border-top: 1px solid $gray-400;
    }

    &:not(:last-child) {
        padding-bottom: .75rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 1rem;
        }
    }

    &:not(:first-child) {
        padding-top: .75rem;
        flex-grow: 0;

        @include media-breakpoint-up(lg) {
            padding-top: 1rem;
        }
    }
}

.card-basic.has-background {

    @include media-breakpoint-up(lg) {
        padding-right: 250px;
    }
}

.card-background {

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 206px;
        overflow: hidden;
        border-radius: 0 $border-radius-lg $border-radius-lg 0;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 206px;
            min-height: 764px;
            background-image: url('../../../assets/img/formular-bg-large-b2c.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: right;

            .theme-override-b2b & {
                background-image: url('../../../assets/img/formular-bg-large-b2b.svg');
            }
        }
    }
}

.card-basic-image {
    flex-shrink: 0;
    flex-basis: calc(min(300px,35%));

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.button-notch {
    mask-image: radial-gradient(70% 100%, circle closest-corner, transparent 0, transparent 32px, black 33px);
    -webkit-mask-image: -webkit-radial-gradient(calc(100% - 64px) 100%, circle closest-corner, transparent 0, transparent 32px, black 33px);
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: inherit;
}

.has-notch {
    background-color: transparent;
    box-shadow: none;
    filter: drop-shadow(0 22px 45px rgba(0, 0, 0, 0.11));
}

.button-in-notch {
    position: absolute;
    top: 100%;
    left: calc(100% - 64px);
    transform: translate(-50%, -50%);
}

.card-clickable {
    cursor: pointer;

    &:hover {
        background-color: $blue-100;
        outline: 2px solid $blue;
    }

    &:focus, &:focus-visible {
        outline: 2px dashed $blue;
        outline-offset: 3px;
    }
}
