
.tooltip-body {
    [class*="col-xs"] {
        flex: 0 0 auto;
        width: auto;
    }
}

.dropdown-menu-right {
    --bs-position: end;
    right: 0;
    left: auto;
}
