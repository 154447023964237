:is(.form-control, .form-select) {
  .was-validated &:valid,
  &.is-valid {
    --#{$prefix}form-check-focus-ring-border-color: #{$form-feedback-valid-color};

    .form-floating-icon .btn:not(.btn-link) {
      color: $form-feedback-valid-color;
    }
  }
  .was-validated &:invalid,
  &.is-invalid {
    --#{$prefix}form-check-focus-ring-border-color: #{$form-feedback-invalid-color};

    .form-floating-icon .btn:not(.btn-link) {
      color: $form-feedback-invalid-color;
    }
  }
}

.was-validated .form-check-input[type="checkbox"]:invalid:checked, .form-check-input[type="checkbox"].is-invalid:checked {
  &:hover {
    background-color: $red-700;
  }
}

.was-validated .form-check-input[type="radio"]:invalid:checked, .form-check-input[type="radio"].is-invalid:checked {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$danger}'/></svg>"));
  border-color: $danger;
  &:hover {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$red-700}'/></svg>"));
    border-color: $red-700;
  }
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  --#{$prefix}form-select-bg-icon: none;
  --#{$prefix}form-select-bg-img: #{escape-svg(
      url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00054 12.7421C8.29295 12.7421 8.58551 12.6305 8.80836 12.4073L15.6652 5.55042C16.1116 5.10401 16.1116 4.38082 15.6652 3.93442C15.2188 3.48801 14.4956 3.48801 14.0492 3.93442L8.00054 9.98504L1.95081 3.93531C1.5044 3.4889 0.781215 3.4889 0.334806 3.93531C-0.111603 4.38172 -0.111603 5.1049 0.334806 5.55131L7.19164 12.4081C7.41485 12.6314 7.70769 12.7421 8.00054 12.7421Z" fill="#{$danger}"/></svg>')
    )};
}