
$stage-visible: .9s;
$stage-crossfade: .3s;
$stage-count: 6;
$stage-animation-length: $stage-visible * $stage-count - ($stage-count) * $stage-crossfade;

.loading-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    transform-style: preserve-3d;
    perspective: 50rem;
}

.loading-stage {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%;
    position: relative;
    animation: new-crossfade $stage-animation-length ease infinite;
    animation-fill-mode: both;
    transform-origin: center center;

    @for $i from 2 through $stage-count {

        &:nth-child(#{$i}) {
            animation-delay: ($i - 1) * ($stage-visible) - ($i - 1) * ($stage-crossfade);

            .loading-stage-icon {
                animation-delay: ($i - 1) * ($stage-visible) - ($i - 1) * ($stage-crossfade);
            }
        }
    }
}

.loading-stage-icon {
    position: absolute;
    bottom: 5%;
    left: 50%;
    color: $blue;
    font-size: 4rem;

    animation: icon-swoop $stage-animation-length ease infinite;
    animation-fill-mode: both;
    transform-origin: center center;

    i, svg {
        transform: translate3d(-50%, 0, 0);
    }

}


@keyframes new-crossfade {
    0% {
        opacity: 0;
        transform: translate3d(0,0,20px);
    }
    #{6% / $stage-count} {
        opacity: 1;
        transform: translate3d(0,0,0px);
    }
    #{94% / $stage-count} {
        opacity: 1;
        transform: translate3d(0,0,0px);
    }
    #{100% / $stage-count} {
        opacity: 0;
        transform: translate3d(0,0,-20px);
    }
    100% {
        opacity: 0;
    }
}

@keyframes icon-swoop {
    0% {
        transform: translate3d(-60px,0,0px) scale(.8);
        opacity: 0;
    }
    #{12% / $stage-count} {
        transform: translate3d(0,0,0px) scale(1);
        opacity: 1;
    }
    #{88% / $stage-count} {
        transform: translate3d(0,0,0px) scale(1);
        opacity: 1;
    }
    #{100% / $stage-count} {
        transform: translate3d(60px,0,0px) scale(.8);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

$loading-inline-duration: .5s;

.loading-inline {
    display: flex;
    height: 1em;
    //width: 2em;
    gap: .25em;
    max-width: 2.25em;
}

:is(.loading-inline-left,.loading-inline-right) {
    width: 1em;
    height: 1em;
    border-radius: 1em;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    animation: loading-pulse-left $loading-inline-duration cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
    animation-fill-mode: both;

    &:before, &:after {
        content: '';
        grid-row-start: 1;
        grid-column-start: 1;
    }
}

.loading-inline-left {

    &:before {
        background-image: linear-gradient(133.03deg, #FF7BA7 9.52%, #EB296A 31.23%, #DB0033 59.19%, #9F0A3C 90.66%);
        animation: crossfade-balls $loading-inline-duration cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
        animation-fill-mode: both;
    }

    &:after {
        background-image: linear-gradient(134.66deg, #FFD88C 3.37%, #D9A300 46.27%, #CB8603 89.57%);
    }
}

.loading-inline-right {
    animation-delay: -$loading-inline-duration;

    &:before {
        background-image: linear-gradient(135.36deg, #75CEEA 3.37%, #06649B 78.1%, #0F255B 104.38%);
        animation: crossfade-balls $loading-inline-duration cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
        animation-delay: -$loading-inline-duration;
        animation-fill-mode: both;
    }

    &:after {
        background-image: linear-gradient(134.66deg, #FFD88C 3.37%, #D9A300 46.27%, #CB8603 89.57%);
    }
}

@keyframes loading-pulse-left {
    0% {
        transform: scale(.66);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes crossfade-balls {
    from {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
