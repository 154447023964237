
.flip-outer {
  perspective: 2000px;
  will-change: height;
}

.flip-height-transition {
  transition: height 0.4s ease-in-out;
}

.flip-inner {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  will-change: transform;
  transition: transform 0.4s ease-in-out;
  transform-style: preserve-3d;
  transform-origin: 50% 50% 0;

  > * {
    grid-row-start: 1;
    grid-column-start: 1;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
}

.flip-front {
  transform: rotateX(0deg);
}

.flip-back {
  transform: translate(100%, 0);
}

.flipped {
  transform: translate(-100%, 0);
}