@import 'variables';

@mixin card-focus-effect {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: var(--#{$prefix}card-focus-ring-offset, -5px);
    border-radius: inherit;
    border: 1px dashed var(--#{$prefix}card-focus-ring-border-color, #{$blue-light});
  }
}

// Helpers
.we-logo {
  max-width: 120px;
  margin-left: 1rem !important;
  margin-top: 14px;
}

.has-text-white {
  color: white;
}

.has-text-invoice-open {
  color: $red;
  font-size: 2.5rem;
  font-weight: 400;
}

.has-text-green {
  color: $green;
}

.has-text-orange {
  color: $orange !important;
}

.has-text-red {
  color: $red !important;
}

.has-text-blue {
  color: $blue;
}

.has-text-gray-500 {
  color: $gray-500;
}

.has-text-blue-light {
  color: $blue-light;
}

.has-border-green {
  border: solid 2px $green;
}

.has-border-orange {
  border: solid 2px $orange;
}

.has-border-gray {
  border: solid 2px $gray-500;
}

.has-border-red {
  border: solid 2px $red;
}

.has-border-blue {
  border: solid 2px $blue;
}

.green-check {
  background-color: #258875;
  border-radius: 50%;
  color: $white;
  width: 1.4rem;
  height: 1.4rem;
  min-width: 1.4rem;
  text-align: center;
  font-size: 0.65rem;
  float: left;
}

.check-text {
  width: 88%;
  float: left;
  font-size: 12px;
  color: $blue;
  font-weight: bold;
  padding-top: 0.1rem;
}

.product-detail {
  text-align: left;

  &.single-price {
    margin-bottom: 4.4rem;
  }
}

.product-detail h6 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: $blue;
  margin: 0;
}

.product-detail p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #07112a;
}

.product-detail .large-price {
  font-size: 1.8rem;
  margin-right: 0.4rem;
}

.horizontal-center {
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.product-detail .small-price {
  font-size: 1rem;
}

.square {
  height: 200px;
  width: 200px;
}

.no-shadow {
  box-shadow: none !important;
}

.content--placeholder {
  background: #d8d8d8;
  background: url($we-assets-images-path + '/placeholder-gray.png') center
    center no-repeat #d8d8d8;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.container.limited {
  max-width: 540px;
}

.is-front {
  z-index: 1030;
  position: relative;
}

.has-border-radius {
  border-radius: 5px;
}

.has-border-bottom-radius {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.has-border-top-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.image-upload-preview {
  img {
    width: 54px;
    height: 54px;
    border-radius: 2px;
    border: solid 1px #b3b3b3;
    background-color: #d8d8d8;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
    margin-bottom: 0;
  }

  i.fa-file-pdf {
    width: 54px;
    text-align: center;
  }

  button {
    margin-left: 5px;
    float: right;
    height: 31px;
    width: 31px;

    i {
      color: $blue;
      font-size: 14px;
      position: relative;
      right: 7px;
      top: -1px;
    }
  }
}

// Custom margins

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-20 {
  margin-bottom: 30px !important;
}

.mb-15 {
  margin-bottom: 16px !important;
}

// Custom paddings

.pr-35 {
  padding-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

// Custom height

.h-100--fixed {
  height: 100px !important;
  min-height: 100px !important;
}

// Error pages

.error-page {
  background: $gray-200;
  width: 100%;

  .page-content {
    padding-bottom: 30px;
  }

  .status-code {
    font-size: 70px !important;
    font-weight: 800 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #193352;
  }

  .alert-default {
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .error-page {
    .status-code {
      font-size: 100px !important;
      font-weight: 800 !important;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container.limited {
    max-width: 720px;
  }

  .error-page {
    .status-code {
      font-size: 150px !important;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .container.limited {
    max-width: 907px;
  }

  .container.not-md-limited {
    max-width: 1140px;
  }

  .error-page {
    .status-code {
      font-size: 300px !important;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// CSS Animations

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

// SlideInUp Animation

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

/**
 * z-index helpers
 */

$maxIndex: 5;

@mixin z-index-list {
  @for $i from 1 through $maxIndex {
    .z-#{$i} {
      z-index: $i;
    }
  }
}

@include z-index-list;

.product-icon-width {
  max-width: 75px;
  height: auto !important;

  @include media-breakpoint-down(md) {
    max-width: 3rem;
  }
}
