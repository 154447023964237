/*
* Orson http://en.orson.io , autreplanete http://www.autreplanete.com/
*
**/

// Asset paths
$we-assets-images-path: '../../assets/img' !default;
$we-assets-icons-path: '../../assets/icons' !default;

// Font awesome
$fa-font-path: '../../assets/fonts' !default;

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// Brand Colors
$blue: #000460;
$blue-light: #005797;
$blue-light-hover: #3177aa;
$list-hover: #e3ebf2;
$indigo: #000460;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #cd0045;
$light-red: #f4c2d3;
$orange: #eb6626;
$orange-light: #ed834f;
$orange-dark: #bd4d17;
$yellow: #d9a300;
$brand-green: #258875;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #949494;
$gray-light: #505558;
$gray-100: #ffffff;
$gray-200: #f7f8f8;
$gray-300: #eeeff1;
$gray-400: #dedfe3;
$gray-500: #b3b3b3;
$gray-600: #07112a;
$gray-700: #07112a;
$gray-800: #07112a;
$gray-900: #07112a;
$black: #000;
$login-btn-bg-dark: #00054c;
$login-btn-bg-normal: #4267b2;
$login-btn-bg-light: #2b83fc;
$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
);

$neutral-010: #fcfcfc;
$neutral-050: #f1f1f1;
$neutral-100: #e2e2e2;
$neutral-200: #c6c6c6;
$neutral-300: #ababab;
$neutral-400: #919191;
$neutral-500: #777777;
$neutral-600: #5e5e5e;
$neutral-700: #474747;
$neutral-800: #303030;
$neutral-900: #1b1b1b;

$orange-010: #fcfcfc;
$orange-050: #ffede5;
$orange-100: #ffdbcc;
$orange-200: #ffb595;
$orange-300: #ff8c58;
$orange-400: #eb6626;
$orange-500: #cb4f0c;
$orange-600: #a73b00;
$orange-700: #802b00;
$orange-800: #5a1c00;
$orange-900: #370e00;

$red-010: #fcfcfc;
$red-050: #ffede9;
$red-100: #ffdad3;
$red-200: #ffb4a8;
$red-300: #ff8978;
$red-400: #fa5a4b;
$red-500: #d74135;
$red-600: #b4271f;
$red-700: #910809;
$red-800: #690001;
$red-900: #410001;

$green-010: #f2fff6;
$green-050: #bcffdc;
$green-100: #a7f2ca;
$green-200: #8bd6af;
$green-300: #71ba95;
$green-400: #559f7b;
$green-500: #3b8463;
$green-600: #1c6b4b;
$green-700: #005235;
$green-800: #003823;
$green-900: #002112;

$blue-010: #fdfcff;
$blue-050: #e9f1ff;
$blue-100: #d1e4ff;
$blue-200: #9fc9ff;
$blue-300: #74aff5;
$blue-400: #5894d8;
$blue-500: #3b7abc;
$blue-600: #005797;
$blue-700: #004881;
$blue-800: #00325b;
$blue-900: #001c38;

$yellow-010: #fffbf7;
$yellow-050: #fff0c5;
$yellow-100: #ffe07a;
$yellow-200: #eac33e;
$yellow-300: #cca720;
$yellow-400: #af8d00;
$yellow-500: #917400;
$yellow-600: #745c00;
$yellow-700: #574400;
$yellow-800: #3d2f00;
$yellow-900: #231b00;

$primary: $orange;
$secondary: $blue;
$tertiary: $indigo;
$success: $green-600;
$info: $cyan;
$warning: $yellow;
$danger: $red-600;
$light: $gray-100;
$dark: $gray-800;


$theme-colors: (
);
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    primary: $orange,
    secondary: $blue,
    secondary-light: $blue-light,
    tertiary: $indigo,
    success: $success,
    brand-green: $brand-green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800,
    gray: $gray,
    gray-light: $gray-light,
    gray-500: $gray-500,
  ),
  $theme-colors
);
$colors: (
  blue: $blue,
  blue-light: $blue-light,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $brand-green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);
$theme-color-interval: 8%;

// Brand gradients
$gradient-orange: linear-gradient(
  133deg,
  #fccf8a,
  #f7af40 28%,
  #eb6626 63%,
  #e13b31
);
$gradient-blue: linear-gradient(135deg, #3ea9ca, #06649b 44%, #0f255b);
$gradient-blue-light: linear-gradient(135deg, #75ceea, #06649b 74%, #0f255b);
$gradient-green: linear-gradient(
  135deg,
  #73deca,
  #28a48c 35%,
  $brand-green 70%,
  #0f6e5c
);
$gradient-red: linear-gradient(
  133deg,
  #ff7ba7,
  #eb296a 27%,
  #db0033 61%,
  #9f0a3c
);
$gradient-yellow: linear-gradient(135deg, #ffd88c, $yellow 50%, #cb8603);

$gradiant-green: linear-gradient(
  135deg,
  #73deca 3%,
  #28a48c 34%,
  #258875 64%,
  #0f6e5c 90%
);
// Spacing
$spacer: 1rem;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    12px: ($spacer * .75),
    3: $spacer,
    4: ($spacer * 1.5),
    gutter: 30px,
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3),
    8: ($spacer * 3.5),
    9: ($spacer * 5),
  ),
  $spacers
);
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "smartphone": calc(480 / 236 * 100%)
);

$box-shadow:       null;
$box-shadow-sm:    null;
$box-shadow-lg:    null;
$box-shadow-inset: null;

// Body
$body-bg: $white;
$body-color: $gray-light;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
$grid-columns: 12;
$grid-gutter-width: 30px;

// Components
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$border-width: 1px;
$border-radius: 5px;
$border-radius-lg: .75rem;
$border-radius-sm: 0.2rem;
$border-radius-pill: 50rem;
$component-active-color: $white;
$component-active-bg: $primary;
$caret-width: 0.3em;
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$transition-collapse:         height .35s ease, padding .35s ease !default;

// Fonts
$font-family-sans-serif: 'Fira Sans', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;
$font-weight-normal: normal;
$font-weight-semibold: 600;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$h1-font-size: 56px;
$h2-font-size: 50px;
$h3-font-size: 40px;
$h4-font-size: 34px;
$h5-font-size: 28px;
$h6-font-size: 20px;
$headings-margin-bottom: ($spacer * .5);
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: $indigo;
$text-color: $body-color;
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: 1.25rem;
$lead-font-weight: 300;
$small-font-size: 80%;
$text-muted: $gray-500;
$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);
$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;
$hr-opacity:                  1 !default;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 5px;
$mark-bg: #fcf8e3;

// Buttons
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: 1.25;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1.5;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: 1.5;

$input-btn-border-width:      $border-width;

//button heights
$input-btn-height: 3.5rem;
$input-btn-height-lg: $input-btn-height;
$input-btn-height-md: 2.5rem;
$input-btn-height-sm: 2rem;
$input-btn-height-xs: 1.5rem;

$btn-padding-x: 2rem;
$btn-box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.35);
$btn-focus-box-shadow: 0 0 0 3px rgba($primary, 0.25);
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);
$btn-link-disabled-color: $gray-600;
$btn-block-spacing-y: 0.5rem;
$btn-border-width: 2px;
$btn-border-radius: $border-radius-pill;
$btn-border-radius-lg: $border-radius-pill;
$btn-border-radius-sm: $border-radius-pill;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
$btn-disabled-opacity: 1;
$btn-disabled-bg: $gray;
$btn-disabled-border-color: $gray;

$input-btn-focus-width:         1px;
$input-btn-focus-color-opacity: 1;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

// Forms
$input-bg: $white;

$input-disabled-color:                  rgba($black,.16) !default;
$input-disabled-bg:                     $neutral-100 !default;
$input-disabled-border-color:           $neutral-300 !default;
$input-color: $body-color;

$input-padding-y:                       .75rem;
$input-padding-x:                       .75rem;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);
$input-border-color: $neutral-600;
$input-border-radius: $border-radius;
$input-border-width: 2px;
$input-focus-bg: $input-bg;
$input-font-weight: $font-weight-semibold;
$input-focus-border-color: $input-border-color;
$input-focus-box-shadow: 0 0 0 1px $blue-light;
$input-focus-color: $input-color;
$input-placeholder-color: $neutral-300;
$input-height-border: $input-btn-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: ($font-size-sm * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$input-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, outline-color ease-in-out 0.15s;
$form-text-margin-top: 0.25rem;

$btn-padding-y-sm:            3px !default;
$btn-padding-x-sm:            1rem !default;
$btn-font-size-sm:            11px !default;
$btn-border-radius-sm:        $btn-border-radius;
$btn-font-size:               .875rem;
$btn-font-weight:             600;
$btn-line-height:             1;

$form-label-font-size:                  .875rem;

$form-check-input-width: 1.5rem;
$form-check-padding-start: $form-check-input-width + .5rem !default;
$form-check-margin-bottom: 0.5rem;
$form-check-input-border: $input-border-width solid $input-border-color !default;
$form-check-input-gutter: .5rem;
$form-check-input-margin-y: 0.25rem;
$form-check-input-margin-x: 0.25rem;
$form-check-inline-margin-x: 0.75rem;

$form-check-input-hover-bg: $neutral-050;

$form-check-input-focus-border: $blue-light;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-bg-color: $blue-light;
$form-check-input-checked-border-color: transparent;
$form-check-input-disabled-opacity: 1;

$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'><path fill='#{$white}' stroke='none' d='M11.7489 2.05152C12.0837 2.38636 12.0837 2.92746 11.7489 3.2623L4.89134 10.1198C4.5565 10.4547 4.0154 10.4547 3.68056 10.1198L0.25105 6.69106C-0.0836833 6.35622 -0.0836833 5.81512 0.25105 5.48028C0.585836 5.14544 1.12855 5.14544 1.46339 5.48028L4.26185 8.30098L10.5381 2.05152C10.8729 1.71614 11.414 1.71614 11.7489 2.05152Z'/></svg>") !default;

$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$blue-light}'/></svg>") !default;

$form-check-input-checked-readonly-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'><path fill='#{$blue-600}' stroke='none' d='M11.7489 2.05152C12.0837 2.38636 12.0837 2.92746 11.7489 3.2623L4.89134 10.1198C4.5565 10.4547 4.0154 10.4547 3.68056 10.1198L0.25105 6.69106C-0.0836833 6.35622 -0.0836833 5.81512 0.25105 5.48028C0.585836 5.14544 1.12855 5.14544 1.46339 5.48028L4.26185 8.30098L10.5381 2.05152C10.8729 1.71614 11.414 1.71614 11.7489 2.05152Z'/></svg>") !default;
$form-check-radio-checked-readonly-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$blue-600}'/></svg>") !default;


$form-select-indicator-color: $neutral-600;
$form-select-indicator:           url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00054 12.7421C8.29295 12.7421 8.58551 12.6305 8.80836 12.4073L15.6652 5.55042C16.1116 5.10401 16.1116 4.38082 15.6652 3.93442C15.2188 3.48801 14.4956 3.48801 14.0492 3.93442L8.00054 9.98504L1.95081 3.93531C1.5044 3.4889 0.781215 3.4889 0.334806 3.93531C-0.111603 4.38172 -0.111603 5.1049 0.334806 5.55131L7.19164 12.4081C7.41485 12.6314 7.70769 12.7421 8.00054 12.7421Z" fill="#{$form-select-indicator-color}"/></svg>');
$form-select-bg-size:             1rem;

$form-select-focus-box-shadow:    $input-focus-box-shadow;
$form-select-disabled-bg:         $input-disabled-bg;

$form-floating-height:            calc($input-padding-y * 2 + 1.375rem + .875rem + $input-height-border);
$form-floating-line-height:       1.125 !default;
$form-floating-padding-x:         $input-padding-x;
$form-floating-padding-y:         $input-padding-y;
$form-floating-input-padding-t:   add(1.125rem, $input-padding-y);
$form-floating-input-padding-b:   $input-padding-y;
$form-floating-label-transform:   none;
$form-floating-label-opacity:     1;
$form-floating-transition:        none;

$form-group-margin-bottom: 1rem;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-control-gutter: 1.25rem;
$custom-control-spacer-y: 0.25rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: #ddd;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-description-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
  0 0 0 3px $primary;
$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten($primary, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-indeterminate-bg: $primary;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-radio-indicator-border-radius: 50%;
$custom-select-padding-y: 0.375rem;
$custom-select-padding-x: 0.75rem;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px;
$custom-select-indicator-color: #333;
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: lighten($primary, 25%);
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075),
  0 0 5px rgba($custom-select-focus-border-color, 0.5);
$custom-select-font-size-sm: 75%;
$custom-select-height-sm: $input-height-sm;
$custom-file-height: 2.5rem;
$custom-file-width: 14rem;
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white,
  0 0 0 0.2rem $primary;
$custom-file-padding-y: 1rem;
$custom-file-padding-x: 0.5rem;
$custom-file-line-height: 1.5;
$custom-file-color: $gray-700;
$custom-file-bg: $white;
$custom-file-border-width: $border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow: inset 0 0.2rem 0.4rem rgba($black, 0.05);
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $gray-200;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$input-feedback-invalid-focus-box-shadow: null;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1287_14799)"><path d="M0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12ZM17.4281 9.92813C17.9391 9.41719 17.9391 8.58281 17.4281 8.07187C16.9172 7.56094 16.0828 7.56094 15.5719 8.07187L10.5 13.1437L8.42813 11.0719C7.91719 10.5609 7.08281 10.5609 6.57187 11.0719C6.06094 11.5828 6.06094 12.4172 6.57187 12.9281L9.57187 15.9281C10.0828 16.4391 10.9172 16.4391 11.4281 15.9281L17.4281 9.92813Z" fill="#{$form-feedback-icon-valid-color}"/></g><defs><clipPath id="clip0_1287_14799"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24ZM12 6C12.6234 6 13.125 6.50156 13.125 7.125V12.375C13.125 12.9984 12.6234 13.5 12 13.5C11.3766 13.5 10.875 12.9984 10.875 12.375V7.125C10.875 6.50156 11.3766 6 12 6ZM13.5 16.5C13.5 17.3297 12.8297 18 12 18C11.1703 18 10.5 17.3297 10.5 16.5C10.5 15.6703 11.1703 15 12 15C12.8297 15 13.5 15.6703 13.5 16.5Z" fill="#{$form-feedback-icon-invalid-color}"/></svg>');

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": var(--#{$prefix}form-valid-color),
    "icon": $form-feedback-icon-valid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}success),
    "focus-box-shadow": none,
    "border-color": var(--#{$prefix}form-valid-border-color),
  ),
  "invalid": (
    "color": var(--#{$prefix}form-invalid-color),
    "icon": $form-feedback-icon-invalid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}danger),
    "focus-box-shadow": none,
    "border-color": var(--#{$prefix}form-invalid-border-color),
  )
) !default;
// scss-docs-end form-validation-states


// Dropdown
$dropdown-min-width: max(10rem,100%);
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-width: $border-width;
$dropdown-divider-bg: $gray-200;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-disabled-color: $gray-600;
$dropdown-item-padding-x: .75rem;
$dropdown-item-padding-y: .5rem;
$dropdown-header-color: $secondary;
$dropdown-header-padding-x: $dropdown-item-padding-x;
$dropdown-header-padding-y: $dropdown-item-padding-y;

//Offcanvas
$offcanvas-horizontal-width:        29rem;
$offcanvas-border-color:            none;
$offcanvas-border-width:            0;
$offcanvas-bg-color:                transparent;

// Navs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;
$nav-tabs-border-color: #ddd;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: #ddd;
$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar
$navbar-padding-y: ($spacer * .5);
$navbar-padding-x: $spacer;
$navbar-brand-font-size: $font-size-lg;
$nav-link-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-height: (
  $font-size-base * $line-height-base + $nav-link-padding-y * 2
);
$navbar-brand-padding-y: ($navbar-brand-height - $nav-link-height) * .5;
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: rgba($white, 1);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: rgba($white, 0.1);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-border-color: rgba($black, 0.1);

// Tables
$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;
$table-bg: transparent;
$table-accent-bg: transparent;
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;
$table-border-width: $border-width;
$table-border-color: $gray-400;
$table-head-bg: $gray-200;
$table-head-color: $gray-700;
$table-inverse-bg: $gray-900;
$table-inverse-accent-bg: rgba($white, 0.05);
$table-inverse-hover-bg: rgba($white, 0.075);
$table-inverse-border-color: lighten($gray-900, 7.5%);
$table-inverse-color: $body-bg;

// Z Index
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1055;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Pagination
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;
$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: #ddd;
$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: #ddd;
$pagination-active-color: $white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: #ddd;

// Jumbotron
$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-200;

// Cards
$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: 1px;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: rgba($black, 0.03);
$card-bg: $white;
$card-img-overlay-padding: 1.25rem;
$card-deck-margin: ($grid-gutter-width * .5);
$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Tooltip
$tooltip-max-width: 173px;
$tooltip-color: $white;
$tooltip-bg: $blue-light;
$tooltip-opacity: 1;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;
$tooltip-margin: 0;
$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $tooltip-bg;

// Popovers
$popover-inner-padding: 1px;
$popover-bg: $white;
$popover-max-width: 30rem;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-box-shadow: 0 5px 10px rgba($black, 0.2);
$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: inherit;
$popover-header-padding-y: 1.5rem;
$popover-header-padding-x: 1.5rem;
$popover-body-color: $body-color;
$popover-body-padding-y: 1.5rem;
$popover-body-padding-x: 1.5rem;
$popover-arrow-width: 10px;
$popover-arrow-height: 5px;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-width: ($popover-arrow-width + 1px);
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Badges
$badge-padding-mc-x: .1875rem;
$badge-padding-mc-y: .1875rem;

$badge-padding-xs-x: .25rem;
$badge-padding-xs-y: .25rem;
$badge-font-xs-size: .75rem;

$badge-padding-sm-x: .5rem;
$badge-padding-sm-y: .3125rem;
$badge-font-sm-size: .875rem;

$badge-padding-md-x: 0.75rem;
$badge-padding-md-y: 0.375rem;
$badge-font-md-size: $font-size-base;

$badge-padding-lg-x: 1rem;
$badge-padding-lg-y: .4375rem;
$badge-font-lg-size: 1.125rem,;

$badge-color: $white;
$badge-font-size: $font-size-base;
$badge-font-weight: $font-weight-normal;
$badge-padding-x: $badge-padding-md-x;
$badge-padding-y: $badge-padding-md-y;
$badge-border-radius: $border-radius-pill;
$badge-pill-padding-x: 0.6em;
$badge-pill-border-radius: 10rem;

// Modals
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: $border-width;
//$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
//$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;
$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $gray-200;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 15px;
$modal-xxxl: 1139px;
$modal-xxl: 1022px;
$modal-xl: 904px;
$modal-lg: 732px;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.3s ease-out;

// Alerts
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

// Progress bars
$progress-height: 1rem;
$progress-font-size: 0.75rem;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

// List group
$list-group-bg: transparent;
$list-group-border-color: rgba($black, 0.125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;
$list-group-item-padding-y: 0.25rem;
$list-group-item-padding-x: 1.25rem;
$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;
$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;
$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

// Image thumbnails
$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: #ddd;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);
$thumbnail-transition: all 0.2s ease-in-out;

// Figures
$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;

// Breadcrumbs
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: '/';

// Carousel
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-control-icon-width: 20px;
$carousel-transition: transform 0.6s ease;

// Close
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;

// Code
$code-font-size: 90%;
$code-padding-y: 0.2rem;
$code-padding-x: 0.4rem;
$code-color: #bd4147;
$code-bg: $gray-100;
$kbd-color: $white;
$kbd-bg: $gray-900;
$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;


// Options
$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-negative-margins: true;
$enable-cssgrid: true;
