@import '../variables';

// Show the whole content first
// Same height as the tabs bar
.tabs-wrapper {
  .footer {
    padding-bottom: 60px;
  }
}

.tabs {
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 2.5px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 -1px 24px 0 rgba(0, 0, 0, 0.11);
    background-color: inherit;
  }
}

.tabs-item {
  display: inline-block;

  &:hover {
    cursor: pointer;
    color: $secondary;
  }

  &.active {
    color: $primary;

    &:hover {
      color: $primary;
    }

    .tabs-item-icon {
      color: white;
      background: #eb6626;
      top: -17.5px;
      padding-top: 5px;

      & > i {
        padding-top: 5px;
      }
    }
  }
}

.tabs-item-icon {
  font-size: 20px;
  height: 43px;
  width: 43px;
  margin: 0 auto;
  border-radius: 900px;
  position: relative;

  & > i {
    padding-top: 10px;
  }
}

.tabs-item,
.tabs-item-icon {
  position: relative;
  color: $secondary;
  text-align: center;
}

.tabs-item > p {
  margin-top: -4px;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  text-align: center;
}

.tabs-submenu {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  transform: translate3d(0,100%,0);
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;

  transition: transform .25s ease-out, opacity .15s ease-out;

  .tabs-item.profile-active + & {
    transform: translate3d(0,0,0);
    opacity: 1;
    pointer-events: auto;
  }
}

.tabs-item.profile-active {
  .tabs-item-icon {
    color: white;
    background: $secondary;
    top: -17.5px;
    padding-top: 5px;
    > i {
      padding-top: 5px;
    }
  }
}

@include media-breakpoint-up(md) {
  .tabs-wrapper {
    body,
    .footer {
      padding-bottom: 0;
    }
  }
}

/* IE styles only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tabs {
    background: white;
  }
}
