@import '../variables';


@mixin text-focus-ring {
  &:focus-within {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: var(--#{$prefix}text-focus-ring-offset, -5px);
      border-radius: $border-radius;
      border: 1px dashed var(--#{$prefix}text-focus-ring-border-color, #{$blue-light});
    }
  }
}
@mixin text-focus-bg {
  &:focus-within {
    position: relative;
    z-index: 0;
    color: var(--#{$prefix}text-focus-bg-text-color, #{$white});

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: var(--#{$prefix}text-focus-bg-offset, -.15em -.25em);
      background: var(--#{$prefix}text-focus-bg-background-color, #{$blue});
      z-index: -1;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h5-custom,
.text--extra-large,
.text--extra-large-2,
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

h1 {
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: $headings-color;
}

h2 {
  font-size: 1.75rem;
  line-height: 1.25em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: $headings-color;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.33em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: $headings-color;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: $headings-color;
}

h5,
h6 {
  font-size: 1.125rem;
  line-height: 1.444em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: $headings-color;
}
.h5-custom {
  font-size: 20px !important;
}
.text--extra-large {
  font-size: 1rem !important;
  line-height: 1.5em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $text-color;
}
.text--extra-large-2 {
  font-size: 24px !important;
}
.text--extra-large-3 {
  font-size: 14px !important;
}
.text--big {
  font-size: 0.875rem !important;
  line-height: 1.571em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $text-color;
}

.text--large {
  font-size: 0.75rem;
  line-height: 1.667em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $text-color;
}
.text--large-2 {
  font-size: 0.75rem;
  line-height: 1.571em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $text-color;
}

.text--medium,
.text--small {
  font-size: 0.688rem;
  line-height: 1.73em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: $text-color;
}

.text--legal {
  font-size: 0.688rem;
  line-height: 1.73em;
  font-weight: 400;
  font-stretch: normal;
  font-style: italic;
  color: $text-color;
}

@media (min-width: 991px) {
  h1 {
    font-size: 2.625rem;
    line-height: 1.19em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 1.167em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  h3 {
    font-size: 2rem;
    line-height: 1.25em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  h4 {
    font-size: 1.75rem;
    line-height: 1.214em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  h5 {
    font-size: 1.5rem;
    line-height: 1.333em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  h6 {
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: $headings-color;
  }

  .text--extra-large {
    font-size: 1.125rem !important;
    line-height: 1.444em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }
  .text--extra-large-2 {
    font-size: 28px !important;
  }
  .text--extra-large-3 {
    font-size: 18px !important;
  }

  .h5-custom {
    font-size: 24px !important;
  }
  .text--big {
    font-size: 1rem !important;
    line-height: 1.5em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }

  .text--large {
    font-size: 0.875rem;
    line-height: 1.571em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }

  .text--large-2 {
    font-size: 1rem;
    line-height: 1.571em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }
  .text--medium {
    font-size: 0.75rem;
    line-height: 1.667em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }

  .text--small {
    font-size: 0.688rem;
    line-height: 1.727em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-color;
  }
}

.text--address {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  color: $blue;
}

a {
  text-decoration: none;
  position: relative;
  color: $orange;
  &:hover {
    text-decoration: underline;
    color: $orange;
  }
  &.no-hover,
  &.zocial,
  &.unlink {
    &:hover {
      text-decoration: none;
    }
  }
  &.blue {
    color: $blue;
  }
}

p,
i {
  &.is-black {
    color: $text-color;
  }
  &.is-green {
    color: $green;
  }
  &.is-bold {
    font-weight: bold;
  }
  &.is-blue {
    color: $blue;
  }
  &.is-red {
    color: $red;
  }
}

p a,
a.link {
  position: relative;
  color: $blue-light;
  text-decoration: underline;
  font-weight: 600;

  &:hover,
  &.hover {
    color: $blue;
  }

  &.focus,
  &:focus-visible {
    color: $white;
    background: $blue-light;
    &::before {
      position: absolute;
      content: '';
      inset: 0 -.25rem;
      background: $blue;
      z-index: -1;
    }
  }

  &.disabled,
  &:disabled {
    color: $gray !important;
    text-decoration: none;

    &:hover {
      color: $gray !important;
      background: none;
      &::before,
      &::after {
        display: none !important;
      }
    }
  }

  &.orange {
    color: $orange;
    &:hover,
    &.hover {
      color: $orange-dark;
    }

    &.focus,
    &:focus-visible {
      color: $white;
      background: $orange;
      &::before {
        background: $orange;
      }
    }
  }

  &.light-blue {
    color: $blue-light;
    &:hover,
    &.hover {
      color: $blue;
    }

    &.focus,
    &:focus-visible {
      color: $white;
      background: $blue-light;
      &::before {
        background: $blue-light;
      }
    }
  }
}

.text-muted {
  font-size: 12px;
}

.is-thin {
  font-weight: 200;
}

.color-gray {
  color: $gray-600;
}

.color-gray-light {
  color: $gray-light !important;
}

.product-info {
  color: $gray-500 !important;
  font-style: italic !important;
  font-size: 11px !important;
}

.product-account {
  color: $gray-light !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.color-indigo {
  color: $indigo !important;
}

.f-400 {
  font-weight: 400 !important;
}

.text-focus-blue {
  @include text-focus-bg;
}

.text-focus-orange {
  --#{$prefix}text-focus-bg-text-color: $primary;
  @include text-focus-bg;
}

.text-focus-ring {
  @include text-focus-ring;
}

.fs-2rem {
  font-size: 2rem !important;
}

.link-hover-behaviour {
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

a.focus-inverted {
  &.focus,
  &:focus-visible {
    color: $blue !important;
    background: $white !important;
  }
}

.clickable-secondary {
  color: $blue;

  &:hover,
  &.hover {
    color: $blue-light;
  }

  &.focus,
  &:focus-visible {
    color: $white;
    background: $blue;
  }
}

.font-size-12 {
  font-size: 0.75rem !important;
}