@import 'libs/assets/src/lib/scss/main_style.scss';
@import 'ikp/src/_variables.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html:has(#navbarSupportedContent.show) {
  /* remove the main scrollbar when dialog is open */
  overflow: hidden;
  height: 100%;
}

// FOCUS States
// Elements (explicit)
.main-content {
  a:focus::before,
  button:focus::before {
    @extend .focus-overwrite-border;
  }
}
.card-basic,
.badge:is(button):focus-visible,
.badge:is(button):focus,
.badge:is(button).focus {
  &::before {
    @extend .focus-overwrite;
  }
}

.object-card > .card-basic {
  margin: 20px !important;
}

.centered-top {
  margin: 30px 0;
  @media (max-width: $mobile-breakpoint) {
    margin: 0 0;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $zindex-fixed;
}

.hero {
  @media (max-width: $mobile-breakpoint) {
    .two-active-alerts {
      .alert {
        margin: 0 0 10px 0;
      }
    }
    @media (max-height: $small-mobile-height-breakpoint) {
      .hero-header {
        padding-top: 1rem;
      }
      .mb-5 {
        margin-bottom: 1rem !important;
      }
    }
  }
}

// Badge Hacking to fit our design
.badge-pill.mb-4 {
  margin-bottom: 0 !important;
}

// width utilities
.w-md-auto {
  width: auto;
  text-align: center;
  @media (max-width: $mobile-breakpoint) {
    width: 100%;
  }
}

.dropdown-item {
  &:hover,
  &:focus {
    background: $list-hover;
  }
}

.centered-datepicker-content {
  width: 100%;
  --bs-border-color: var(--bs-gray);
  ngb-datepicker {
    width: 100%;
    > div.ngb-dp-months {
      justify-content: center !important;
      width: 100%;
    }
  }
}

.light-blue-icon {
  color: #005797;
}

.form-check-label-spacing {
  margin-left: 8px;
}

.custom-input-invalid-state {
  border-color: var(--bs-form-invalid-border-color) !important;
}
