@import '../variables';

.payment-badge {
  height: 37px;
  background: $gray-300;
  padding: 0 13px 0 13px;
  border-radius: 5px;
  position: relative;
  width: fit-content;
  min-width: 83px;

  img {
    height: 19px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.badge.badge-pill {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #07112a;
  text-align: left;

  &.badge-light {
    background: #eeeff1;
  }

  i {
    height: 15.3px;
    width: 15.3px;
    padding: 5px;
    font-size: 8.1px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    top: -2px;
    left: 2px;
    cursor: pointer;
    color: #000460;
  }
}

.badge {
  display: inline-flex;
  &:is(button) {
    border: 0 none;
    position: relative;

    --#{$prefix}btn-focus-color: var(--#{$prefix}btn-hover-color);
    --#{$prefix}btn-focus-bg: var(--#{$prefix}btn-hover-bg);
    --#{$prefix}btn-focus-border-color: var(--#{$prefix}btn-hover-border-color);
    --#{$prefix}btn-focus-ring-border-color: #{$blue-light};
    --#{$prefix}btn-focus-ring-offset: -3px;

    &:focus-visible,
    &:focus,
    &.focus {
      color: var(--#{$prefix}btn-focus-color);
      background-color: var(--#{$prefix}btn-focus-bg);
      border-color: var(--#{$prefix}btn-focus-border-color);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: var(--#{$prefix}btn-focus-ring-offset);
        border-radius: inherit;
        border: 1px dashed var(--#{$prefix}btn-focus-ring-border-color);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .payment-badge {
    height: 44px;
    min-width: 100px;
  }
}

.badge-mc {
  --#{$prefix}badge-padding-x: #{$badge-padding-mc-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-mc-y};
}

.badge-xs {
  //font-size: .75rem;
  @include rfs($badge-font-xs-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-padding-x: #{$badge-padding-xs-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-xs-y};
}

.badge-sm {
  //font-size: .75rem;
  @include rfs($badge-font-sm-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-padding-x: #{$badge-padding-sm-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-sm-y};
}

.badge-md {
  //font-size: .75rem;
  @include rfs($badge-font-md-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-padding-x: #{$badge-padding-md-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-md-y};
}

.badge-lg {
  //font-size: .75rem;
  @include rfs($badge-font-lg-size, --#{$prefix}badge-font-size);
  --#{$prefix}badge-padding-x: #{$badge-padding-lg-x};
  --#{$prefix}badge-padding-y: #{$badge-padding-lg-y};
}
